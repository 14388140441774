
import { BATCH_CONFLICT_ACTION, buildParentMap as buildParentMapOriginal } from './arborHelpers';


/**
 * Helper to encapsulate state during tree manipulation
 */
export default class TreeStateManager {
  constructor(
    draft,
    items,
    openState,
    newItemIndicators = [],
  ) {
    this.draft = draft;
    this.items = items;
    this.openState = openState;
    this.newItemIndicators = newItemIndicators;

    this.rootItem = this.items['root'];
  }


  /**
   * Might be redundant in some cases, but we want to clear the
   * indicators on any interaction. Catch some of them here.
   *
   * The UI will also dispatch events to clear it manually, like
   * clicking on the kebab menu, hovering, etc.
   * @param {string|string[]} ids
   */
  deleteIndicators(ids) {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }
    ids.forEach(id => {
      if (typeof id === "string") {
        const idx = this.newItemIndicators.indexOf(id);
        if (idx !== -1) {
          this.newItemIndicators.splice(idx, 1);
        }
      }
    })
  }


  /** 
   * @param {string} id - The ID to find the parent of
   * @returns {TreeItem | undefined}
   */
  findParent(id) {
    return Object.values(this.items).find(item => item.children?.includes(id));
  }

  /**
   * Move an item from one location to another. May be within parent, or to a new parent.
   * @param {string} id
   * @param {string} parentId - The ID of the new parent
   * @param {number} newIndex - The index to insert the item at
   * @returns {void}
   */
  moveItem(id, parentId, newIndex) {
    const item = this.items[id];
    const prevParent = this.findParent(id);
    const nextParent = this.items[parentId] || this.rootItem;

    if (!item) {
      return console.warn('TREE_MOVE_ITEMS: item not found', id);
    }
    if (!prevParent) {
      return console.warn('TREE_MOVE_ITEMS: prevParent not found', id);
    }
    if (!nextParent) {
      return console.warn('TREE_MOVE_ITEMS: nextParent not found', parentId);
    }

    // Remove item from previous parent's children
    prevParent.children = prevParent.children.filter(child => child !== id);

    // Add item to new parent's children at specified index
    nextParent.children = nextParent.children || [];
    nextParent.children.splice(newIndex, 0, id);

    this.deleteIndicators([id, parentId]);
  }

  /**
   * Adds a new item to the tree. Name/id/etc must already be generated
   * @param {string} parentId
   * @param {number} index - insertAt
   * @param {TreeItem} newItem
   * @returns {void}
   */
  createItem(parentId, index, newItem) {
    const parent = this.items[parentId] || this.rootItem;
    if (!parent) {
      return console.warn('TREE_CREATE_ITEM: parent not found', parentId);
    }

    parent.children = parent.children || [];
    parent.children.splice(index, 0, newItem.id);

    this.items[newItem.id] = newItem;

    if (!this.newItemIndicators.includes(newItem.id)) {
      this.newItemIndicators.push(newItem.id);
    }
  }

  /**
   * Delete an individual item, not recursively.
   * Use this if you have already collected all the child IDs to delete.
   * @param {string} id
   * @param {{string: string}} parentMap
   * @returns {void}
   */
  deleteItem(id, parentMap) {
    const parentId = parentMap[id];
    const parent = this.items[parentId];
    if (parent?.children) {
      parent.children = parent.children.filter(child => child !== id);
    }
    delete this.items[id];

    const openStateIdx = this.openState.indexOf(id);
    if (openStateIdx > -1) {
      this.openState.splice(openStateIdx, 1);
    }

    this.deleteIndicators([id]);
  }

  /** 
   * Construct a mapping of childId -> parentId.
   * Makes deletions much faster and simpler
   * @returns {{string: string}} - A map of childId -> parentId
   */
  buildParentMap() {
    return buildParentMapOriginal(this.items);
  }

  /**
   * Handles BATCH_CONFLICTS, where multple types of actions are sent in at once.
   * Its triggered by a user moving items and coming into a name conflict,
   * which can be resolved multiple ways.
   * @param {{parentId: string, index: number}} actionPayload
   * @param {{resolutionType: string, payload: {dragId: string, newName: string, itemsToOverwrite: string[]}}} batchAction
   * @param {{string: string}} parentMap
   * @param {{count: number}} moveCounter - We need to increment target index after each drop
   * @returns {void}
   */
  handleBatchConflictAction(
    actionPayload,
    batchAction,
    parentMap,
    moveCounter,
  ) {
    const { parentId, index } = actionPayload;
    const { resolutionType, payload: batchPayload } = batchAction;
    const { dragId, newName, itemsToOverwrite = [] } = batchPayload;

    if (!this.items[dragId]) {
      console.warn('TREE_MOVE_ITEMS_WITH_BATCH_CONFLICTS: dragId not found', dragId);
      return;
    }

    switch (resolutionType) {
      case BATCH_CONFLICT_ACTION.OVERWRITE: {
        if (!itemsToOverwrite.length) {
          console.warn('TREE_MOVE_ITEMS_WITH_BATCH_CONFLICTS: itemsToOverwrite not found', dragId);
          break;
        }
        itemsToOverwrite.forEach(id => {
          this.deleteItem(id, parentMap);
        });
        this.moveItem(dragId, parentId, index + moveCounter.current); // TODO: I think this index is wrong. See regular MOVE_ITEM. But it doesn't matter because its being replaced?
        moveCounter.current += 1;
        break;
      }
      case BATCH_CONFLICT_ACTION.KEEP: {
        this.moveItem(dragId, parentId, index);
        this.items[dragId].label = newName;
        break;
      }
      case BATCH_CONFLICT_ACTION.CANCEL: {
        // noop
        break;
      }
      default: {
        console.warn('BATCH_CONFLICT_ACTION resolutionType not recognized:', resolutionType)
        break;
      }

    }
  }
}
