import {
  middlewareActionType as mAction,
  INIT_ACTIONS
} from 'src/redux/middleware/layoutSyncMiddleware/constants';
import { LINK_COLORS } from './layoutSchema.js';

export const UPDATE_GLOBAL_SETTINGS = mAction('update-global-settings');
export const CREATE_COMPONENT = mAction('create-component');
export const CREATE_ZERO_STATE_COMPONENT = '@layout/create-component-zero-state';
export const SET_ADD_COMPONENT_OVERLAY = mAction('set-add-component-overlay');

export const COPY_COMPONENT = mAction('copy-component');
export const UPDATE_COMPONENT = mAction('update-component');
export const UPDATE_PROFILE = mAction('update-profile');
export const UPDATE_COMPONENT_PROFILE = mAction('update-component-profile');
export const UPDATE_UI_COMPONENT_STATE = mAction('update-ui-component-state');

export const MDW_INIT_START = mAction(INIT_ACTIONS.START);
export const MDW_INIT_SUCCESS = mAction(INIT_ACTIONS.SUCCESS);
export const MDW_INIT_FAILURE = mAction(INIT_ACTIONS.FAILURE);
export const MDW_INIT_TEARDOWN = mAction(INIT_ACTIONS.TEARDOWN);

export const UPDATE_LINKED_DATA = mAction('update-linked-data');
export const UPDATE_LINKED_DATA_BY_LAYOUT = mAction('update-linked-data-by-layout');
export const UPDATE_COMPONENT_LINK_COLOR = mAction('update-component-link-color');
export const UPDATE_TICKER_SEARCH_LINK_COLOR = mAction('update-ticker-search-link-color');


/** @typedef {import('./layoutSchema.js').ProfileStruct} ProfileStruct */
/** @typedef {import('./layoutSchema.js').SchemaApi} SchemaApi */
/** @typedef {import('./layoutExpressionConfig').ExpressionPayload} ExpressionPayload */
/** @typedef {import('./reducerUtils.js').ProfileSettings} ProfileSettings */
/** @typedef {function(dispatch, getState): void} Thunk */
/** @typedef {import('./layoutSchema.js').MosaicNode} MosaicNode */
/** @typedef {import('./layoutSchema.js').ProfileConfigItem} ProfileConfigItem */
/** @typedef {import(import('./layoutSchema').TreeItem)} TreeItem */


/**
 * @template T
 * @typedef {T[keyof T]} ValueOf
 */

/** @typedef {function(dispatch, getState): void} Thunk */

/** 
 * @typedef {object} Action
 * @property {string} type
 * @property {*} payload
 */

/**
 * @typedef {object} LayoutAction
 * @property {string} type
 * @property {string} namespace
 * @property {*} payload
 */

/**
 * @typedef {object} ProfilePayload
 * @property {string} activeProfile
 * @property {{string: ProfileStruct}} profileMap
 * @property {{string: TreeItem}} profileTree
 */

/**
 * @typedef {object} SingleProfilePayload
 * @property {string} profileId
 * @property {ProfileStruct} profile
 */


/*
 NOTE:: Any non-deterministic operations MUST be done inside the action creator, instead
 of reducer. This is because BroadcastChannel needs to send actions to other tabs, and 
 those actions must result in the identical state after processing.

 For us this means:
  - Create/Copy layout: Must create the entire schema, because it involves generating many IDs for each component
  - Create component: Just generate the ID, thats the only non-deterministic part. We do this inside Mosiac root jsx.
*/


/**
 * Initializes the layout middleware. userSub must be present inside the store
 * before you attempt.
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @returns {LayoutAction}
 */
export function initializeMiddleware(
  namespace,
  schemaApi,
) {
  return {
    type: MDW_INIT_START,
    namespace,
  }
}


/**
 * Tear down the middleware to prevent background updates from happening
 * on different pages, or preserving invalid data unintentionally.
 * This will:
 * 1) Cause the middleware to reset its instance variables
 * 2) Cause the reducer to set state = initialState
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @returns {LayoutAction}
 */
export function teardownMiddleware(
  namespace,
  schemaApi,
) {
  return {
    type: MDW_INIT_TEARDOWN,
    namespace,
  }
}


/**
 * If componentId is passed, only show the overlay for that component.
 * Otherwise, show for all.
 *
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {string|undefined} componentId
 * @returns {LayoutAction}
 */
export function showAddComponentOverlay(
  namespace,
  schemaApi,
  componentId
) {
  return {
    type: SET_ADD_COMPONENT_OVERLAY,
    namespace,
    payload: { open: componentId || true }
  }
}


/**
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @returns {LayoutAction}
 */
export function hideAddComponentOverlay(
  namespace,
  schemaApi,
) {
  return {
    type: SET_ADD_COMPONENT_OVERLAY,
    namespace,
    payload: { open: false }
  }
}


/**
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {string} layoutId
 * @param {string} newComponentId - must be pre-generated
 * @param {string} type - COMPONENT_TYPE
 * @param {object} overrides
 * @returns {LayoutAction}
 */
export function createComponent(
  namespace,
  schemaApi,
  layoutId,
  newComponentId,
  type,
  overrides = {}
) {
  return {
    type: CREATE_COMPONENT,
    namespace,
    payload: {
      layoutId,
      newComponentId,
      type,
      overrides
    }
  }
}

/**
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {string} layoutId
 * @param {string} componentId - must be pre-generated
 * @param {string} type - COMPONENT_TYPE
 * @param {object} overrides
 * @returns {LayoutAction}
 */
export function createZeroStateComponent(
  namespace,
  schemaApi,
  layoutId,
  newComponentId,
  type,
  overrides = {},
) {
  return {
    type: CREATE_ZERO_STATE_COMPONENT,
    namespace,
    payload: {
      layoutId,
      newComponentId,
      type,
      overrides
    }
  }
}


/**
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {keyof LINK_COLORS} color
 * @param {object} data
 */
export function updateLinkedData(
  namespace,
  schemaApi,
  color,
  data = {}
) {
  if (color === 'none') {
    console.error(Error('Dispatching "none" link color invalid. Should be sending to component state.'))
    return;
  }
  if (!(color in LINK_COLORS)) {
    console.error(Error(`Invalid link color ${color}`));
    return;
  }

  return {
    type: UPDATE_LINKED_DATA,
    namespace,
    payload: { color, data }
  }
}


/**
* @actionCreator
* @param {string} namespace
* @param {SchemaApi} schemaApi
* @param {keyof LINK_COLORS} color
* @param {object} data
*/
export function updateLayoutLinkedData(
  namespace,
  schemaApi,
  color,
  data = {}
) {
  if (color === 'none') {
    console.error(Error('Dispatching "none" link color invalid. Should be sending to component state.'))
    return;
  }
  if (!(color in LINK_COLORS)) {
    console.error(Error(`Invalid link color ${color}`));
    return;
  }

  return {
    type: UPDATE_LINKED_DATA_BY_LAYOUT,
    namespace,
    payload: { color, data }
  }
}


/**
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {string} componentId
 * @param {keyof LINK_COLORS} color
 * @returns {LayoutAction}
 */
export function updateComponentLinkColor(
  namespace,
  schemaApi,
  componentId,
  color
) {
  if (!(color in LINK_COLORS)) {
    console.error(Error(`Invalid link color ${color}`));
    return;
  }

  return {
    type: UPDATE_COMPONENT_LINK_COLOR,
    namespace,
    payload: { componentId, color }
  }
}


/**
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {keyof LINK_COLORS} color
 * @returns {LayoutAction}
 */
export function updateTickerSearchLinkColor(
  namespace,
  schemaApi,
  color
) {
  if (!(color in LINK_COLORS)) {
    console.error(Error(`Invalid link color ${color}`));
    return;
  }

  return {
    type: UPDATE_TICKER_SEARCH_LINK_COLOR,
    namespace,
    payload: { color }
  }
}


/**
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {string} componentId
 * @param {object} data
 * @returns {LayoutAction}
 */
export function updateComponent(
  namespace,
  schemaApi,
  componentId,
  data = {}
) {
  return {
    type: UPDATE_COMPONENT,
    namespace,
    payload: { componentId, data }
  };
}



/**
 * Normal updateProfile logic. It expects all profiles of a given
 * listKey to be provided, even if only one was updated. 
 *
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {string} componentId
 * @param {ProfileConfigItem} profileConfigItem
 * @param {ProfilePayload} profile
 * @param {ExpressionPayload} expressionPayload
 * @returns {Action}
 **/
export function updateProfile(
  namespace,
  schemaApi,
  componentId,
  profileListKey,
  profile,
  expressionPayload = {},
) {
  return {
    type: UPDATE_PROFILE,
    namespace,
    payload: {
      componentId,
      profileListKey,
      profile,
      expressionPayload,
    }
  }
}



/**
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {boolean} isOpen
 * @returns {Action}
 **/
export function setLayoutTreeDrawerOpen(
  namespace,
  schemaApi,
  isOpen,
) {
  return {
    type: UPDATE_GLOBAL_SETTINGS,
    namespace,
    payload: {
      layoutDrawerOpen: isOpen
    }
  }
}
