import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import rootReducer from 'src/redux/store/rootReducer';
import { EXPR_VERSION } from '../expressions/globalExpressionReducer';
import getLayoutToplistMiddleware from '../layoutV2/toplist/makeMiddleware';
import getLayoutHistoryMiddleware from '../layoutV2/history/makeMiddleware';
// import getHistoryPageIntermediateMiddleware from '../historyPageIntermediate/makeHistoryPageIntermediateMiddleware';
// import { userNamespacedLocalStorageMiddleware } from '../middleware/UserNamespacedLocalStorageMiddleware';

const loggerMiddleware = createLogger();
//
//
// const localStorageSliceConfig = [{
//   sliceName: 'expressions',
//   version: EXPR_VERSION,
//   matcher: (action) => {
//     return action?.type?.startsWith('@global-expression/')
//   },
//   propertiesToRemove: ['isFetching', 'error', 'expressionTimestamp#v2']
// }];
//


export function configureStore(preloadedState = {}) {
  // userNamespacedLocalStorageMiddleware.addSlices(localStorageSliceConfig);

  const middlewares = [
    thunkMiddleware,
  ];

  middlewares.push(getLayoutToplistMiddleware().middleware);
  middlewares.push(getLayoutHistoryMiddleware().middleware);


  if (process.env.NODE_ENV !== 'production') {
    middlewares.push(loggerMiddleware);
  }

  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares)
  );

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers);


  // const exprSlice = userNamespacedLocalStorageMiddleware.getSlice('expressions');
  //
  // if (!exprSlice || !exprSlice?.databaseKey || !exprSlice?.getStorageKey) {
  //   throw Error('Missing Expressions localstorage definition');
  // }

  return createStore(
    rootReducer,
    preloadedState,
    composedEnhancers
  );
}
