import React from 'react';
import clsx from 'clsx';
import {
  Box,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginRight: props => props.compact ? -10 : 0,
    '& > *': {
      marginLeft: props => props.compact ? 0 : 20
    },
    '&.panel-icons-fixed': {
      left: 'unset',
      position: 'absolute',
      right: 15,
      top: '50%',
      transform: 'translateY(-50%)'
    },
    '&.panel-icons-centered': {
      justifyContent: 'center'
    }
  }
}));

export default function PanelIconGroup({ className, compact, positionAbsolute, center, children, ...rest }) {
  const classes = useStyles({ compact });

  return (
    <Box
      className={clsx(classes.root, className, positionAbsolute && 'panel-icons-fixed', center && 'panel-icons-centered')}
      {...rest}
    >
      {children}
    </Box>
  );
}
