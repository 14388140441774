import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import * as Sentry from '@sentry/react';
import authService from 'src/services/authService';
import Subscription from 'src/services/Subscription';
import { useLocation } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import useVerifyBetaAccess from 'src/hooks/useVerifyBetaAccess';
import { useSessionStorage } from 'src/hooks/useSessionStorage';
import isSessionStorageAvailable from 'src/utils/isSessionStorageAvailable';
import { fetchLastProfessionalResponse } from '../redux/account/accountActions';
import { parseUnixMilliseconds } from 'src/utils/datetime/date-fns.tz';
import { differenceInMonths } from 'date-fns';
import { userNamespacedLocalStorageMiddleware } from 'src/redux/middleware/UserNamespacedLocalStorageMiddleware';


function AuthGuard({ children }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.account.user);
  const { shutdown: shutdownIntercom } = useIntercom();
  const [hasRedirectedDuringSession, setHasRedirectedDuringSession] = useSessionStorage('hasRedirectedDuringSession');
  const [hasBetaAccess, isVerifyingBetaAccess] = useVerifyBetaAccess();
  const previousNonProfessionalResponse = useSelector(state => state.account.nonProfessionalPreviousResponse);
  const canSetSession = useRef(false);

  useEffect(() => {
    canSetSession.current = isSessionStorageAvailable();
  }, []);

  useEffect(() => {
    if (user?.userSub && !(Object.keys(previousNonProfessionalResponse).length > 3)) {
      dispatch(fetchLastProfessionalResponse(user.userSub));
    }
  }, [user?.userSub]);


  // Make sure there is a user that was previously authenticated. Does not refresh session or verify session with cognito.
  // That happens in Auth.js on initial load.
  if (!authService.getCurrentUser()) {
    Sentry.setUser(null);
    window.ettUser = null;
    userNamespacedLocalStorageMiddleware.setNamespace((null));
    shutdownIntercom();
    return <Redirect to="/login" />;
  }

  // Wait for dynamodb to return user data.
  if (!user?.userSub || isVerifyingBetaAccess) {
    return <LoadingScreen />;
  }

  if (hasBetaAccess) {
    return children;
  }

  // Always force them to /billing if they don't have a subscription and aren't beta users.
  const subscription = new Subscription(user, user.subscriptionId);
  if (subscription.newCustomer()) {
    return <Redirect to="/billing" />;
  }

  // Paying for pro/non-pro on form submit
  const changeCompliancePlanPath = '/account/subscription/update';
  const pendingChangeCompliancePath = '/account/subscription/update-payment-method';
  if (user?.requires_plan_change) {
    if (subscription.pendingSubscriptionChange() && user.requires_plan_change === subscription.pendingSubscriptionPriceId()) {
      if (!location.pathname.startsWith(pendingChangeCompliancePath)) {
        // The user has attempted to pay for pro/non-pro change, but the charge failed. Make them pay here.
        return <Redirect
          to={{
            pathname: pendingChangeCompliancePath,
            state: { compliance: true }
          }}
        />;
      }
    } else if ((subscription.hasAccess() || subscription.pastDue()) && !subscription.canceled()) {
      if (!location.pathname.startsWith(changeCompliancePlanPath)) {
        // The user needs to make initial payment for pro/non-pro change.
        return <Redirect
          to={{
            pathname: changeCompliancePlanPath,
            state: { compliance: true }
          }}
        />;
      }
    }
  }

  // If payment needs attention, redirect them once per session. Don't do this for users with sessionstorage disabeld.
  const subscriptionPath = '/account/subscription';
  if (canSetSession.current && (subscription.pastDue() || subscription.pendingSubscriptionChange())) {
    if (!location.pathname.startsWith(subscriptionPath)) {
      if (!hasRedirectedDuringSession) {
        return <Redirect to={subscriptionPath} />;
      }
    } else {
      if (!hasRedirectedDuringSession) {
        setHasRedirectedDuringSession(1);
      }
    }
  }

  // Redirect them to fill out the Non-Professional survey if they haven't submitted a response in 6 months.
  const surveyPath = '/account/general/non-professional';
  if (
    previousNonProfessionalResponse &&
    Object.keys(previousNonProfessionalResponse).length > 3 &&
    (subscription.hasAccess() && !subscription.canceled())
  ) {
    if (previousNonProfessionalResponse?.complianceFormId && !location.pathname.startsWith(surveyPath)) {
      if (!user?.complianceFormEditLocked && !user?.requires_plan_change) {
        const now = new Date();
        const lastResponseTime = parseUnixMilliseconds(previousNonProfessionalResponse.complianceFormId);
        if (differenceInMonths(now, lastResponseTime) >= 6) {
          return <Redirect
            to={{
              pathname: surveyPath,
              state: { lastResponseExpired: true }
            }}
          />;
        }
      }
    }
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;
