import {
  middlewareActionType as mAction,
} from 'src/redux/middleware/layoutSyncMiddleware/constants';

export const SET_ACTIVE_LAYOUT = mAction("set-active-layout");
export const UPDATE_CURRENT_NODE = mAction('update-current-node');
export const CREATE_LAYOUT = mAction('create-layout');
export const COPY_LAYOUT = mAction('copy-layout');
export const RENAME_LAYOUT = mAction('rename-layout');
export const DELETE_LAYOUT = mAction('delete-layout');


/** @typedef {import('../layoutSchema').SchemaApi} SchemaApi */
/** @typedef {import('../layoutActions').LayoutAction} LayoutAction */


/**
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {string} layoutId
 * @returns {LayoutAction}
 */
export function setActiveLayout(
  namespace,
  schemaApi,
  layoutId
) {
  return {
    type: SET_ACTIVE_LAYOUT,
    namespace,
    payload: { activeLayout: layoutId }
  };
}


/**
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {string} layoutId
 * @param {MosaicNode} newNode
 * @returns {LayoutAction}
 */
export function updateCurrentNode(
  namespace,
  schemaApi,
  layoutId,
  newNode
) {
  return {
    type: UPDATE_CURRENT_NODE,
    namespace,
    payload: { layoutId, newNode }
  };
}


/**
 * Will use default if no templateId is provided.
 * Must create the structure inside the action creator, because generating
 * IDs is non-deterministic. If we dispatch a a simple action and handle
 * generation inside the reducer, our BroadcastChannel will send the action,
 * causing other tabs to generate a different ID all together.
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {string} [templateId=null]
 * @param {object} [layoutArgs={}]
 * @returns {LayoutAction}
 */
export function createLayout(
  namespace,
  schemaApi,
  templateId = null,
  layoutArgs = {}
) {
  const params = schemaApi.createLayout(templateId, layoutArgs);

  return {
    type: CREATE_LAYOUT,
    namespace,
    payload: {
      newId: schemaApi.generateId(),
      ...params
    }
  };
}


/**
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {string} oldLayoutId
 * @param {string} newName
 * @returns {Thunk}
 */
export function copyLayout(
  namespace,
  schemaApi,
  oldLayoutId,
  newName
) {
  // typegen requires us to use named function here
  return (dispatch, getState) => {
    const { [namespace]: state } = getState();

    if (!state.layouts[oldLayoutId]) {
      return console.warn('copyLayout: oldLayoutId not found in state');
    }

    const params = schemaApi.copyLayout(oldLayoutId, newName, state);

    dispatch({
      type: COPY_LAYOUT,
      namespace,
      payload: {
        newId: schemaApi.generateId(),
        ...params
      }
    });
  };
}


/**
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {string} layoutId
 * @returns {LayoutAction}
 */
export function deleteLayout(
  namespace,
  schemaApi,
  layoutId
) {
  return {
    type: DELETE_LAYOUT,
    namespace,
    payload: { layoutId }
  };
}


/**
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {string} layoutId
 * @param {string} newName
 * @returns {LayoutAction}
 */
export function renameLayout(
  namespace,
  schemaApi,
  layoutId,
  newName
) {
  return {
    type: RENAME_LAYOUT,
    namespace,
    payload: { layoutId, newName }
  };
}

