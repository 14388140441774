import shortUuid from 'short-uuid';
import authService from 'src/services/authService';
import rewardfulService from 'src/services/rewardfulService';
import edgeUsersApi from 'src/apis/edgeUsersApi';
import * as Sentry from '@sentry/react';
import { isObjEmpty } from 'src/utils/isObjEmpty';
import { history } from 'src/App';
import { userNamespacedLocalStorageMiddleware } from 'src/redux/middleware/UserNamespacedLocalStorageMiddleware';
import { pushBillingSuccessGTM, pushEventSignupGTM } from 'src/redux/account/gtmEvents';
import { TagManagerService } from 'src/services/tagManagerService';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER_REQUEST = '@account/register-request';
export const REGISTER_SUCCESS = '@account/register';
export const REGISTER_FAILURE = '@account/register-failure';
export const UPDATE_PROFILE = '@account/update-profile';
export const RESET_PASSWORD_SEND_CODE = '@account/reset-password-send-code';
export const RESET_PASSWORD_FORM_SUBMIT = '@account/reset-password-form-submit';
export const CANCEL_SUBSCRIPTION = '@account/cancel-subscription';
export const RENEW_SUBSCRIPTION = '@account/renew-subscription';
export const UPDATE_CART = '@account/update-cart';
export const UPDATE_SUBSCRIPTION = '@account/update-subscription';
export const FETCH_SUBSCRIPTION_PLANS = '@account/fetch-subscription-plans';
export const REQUEST__FETCH_SUBSCRIPTION_PLANS = '@account/request__fetch-subscription-plans';
export const FETCH_UNPAID_INTENT = '@account/fetch-unpaid-intent';
export const REQUEST__FETCH_UNPAID_INTENT = '@account/request__fetch-unpaid-intent';
export const REQUEST__FETCH_UPCOMING_INVOICE = '@account/request__fetch-upcoming-invoice';
export const FETCH_UPCOMING_INVOICE = '@account/fetch-upcoming-invoice';
export const REQUEST__FETCH_NEW_PLAN_INVOICE = '@account/request__fetch-new-plan-invoice';
export const RESOLVE__FETCH_NEW_PLAN_INVOICE = '@account/request__resolve-new-plan-invoice';
export const FETCH_NEW_PLAN_INVOICE = '@account/fetch-new-plan-invoice';
export const FETCH_NON_PROFESSIONAL_FORM_RESPONSE = '@account/fetch-non-professional-form';
export const REQUEST__FETCH_NON_PROFESSIONAL_FORM_RESPONSE = '@account/request__fetch-non-professional-form';
export const START__REFRESH_USER_ON_TIMEOUT = '@account/start__refresh-user-on-timeout';
export const END__REFRESH_USER_ON_TIMEOUT = '@account/end__refresh-user-on-timeout';



const __fetchUser = async () => {
  const response = await edgeUsersApi(`/user`);
  if (response.data && !isObjEmpty(response.data)) {

    const userSub = response.data?.userSub;
    if (userSub) {
      userNamespacedLocalStorageMiddleware.setNamespace(userSub);
    }

    return response.data;
  }
};


export const fetchUser = () => async dispatch => {
  try {
    const user = await __fetchUser();
    dispatch({ type: SILENT_LOGIN, payload: { user } });
  } catch (err) {
    console.log(err);
    dispatch({ type: SILENT_LOGIN, payload: { user: null } });
  }
};

// For Stripe, I sometimes need wait for webhooks to do their thing on the server, then get the updated user.
// Nothing critical relies on this, just want to display updated credit card details after a payment method is submitted.
export const refreshUserOnTimeout = (timeout = 5000) => async dispatch => {
  dispatch({ type: START__REFRESH_USER_ON_TIMEOUT });
  setTimeout(async () => {
    try {
      const user = await __fetchUser();
      if (user) {
        dispatch({ type: SILENT_LOGIN, payload: { user } });
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({ type: END__REFRESH_USER_ON_TIMEOUT });
    }
  }, timeout);
};


export const login = (email, password) => async dispatch => {
  try {
    dispatch({ type: LOGIN_REQUEST });
    await authService.loginWithEmailAndPassword(email, password);

    let dbUser = await __fetchUser();

    dispatch({ type: LOGIN_SUCCESS, payload: dbUser });
    history.push('/');

  } catch (err) {
    console.log(err);
    dispatch({ type: LOGIN_FAILURE });
    throw err;
  }
};


export const logout = (shutdownIntercom) => async dispatch => {
  await authService.logout();
  Sentry.setUser(null);
  window.ettUser = null;
  shutdownIntercom();
  userNamespacedLocalStorageMiddleware.setNamespace(null);
  dispatch({ type: LOGOUT });
  history.push('/login');
};


export const register = (formData, adminCode) => async dispatch => {
  dispatch({ type: REGISTER_REQUEST });
  let betaAccess = (process.env.REACT_APP_USERS_LAMBDA_STAGE === 'beta');
  if (adminCode && !betaAccess) {
    try {
      await edgeUsersApi.post('/user/code/verify', { code: adminCode });
      betaAccess = true;
    } catch (err) {
      console.log(err);
    }
  }

  try {
    const cognitoUser = await authService.register({ ...formData, betaAccess: betaAccess.toString() });
    const message = { message: 'Account created! Check your email and verify your account before logging in.', variant: 'success' };
    dispatch({ type: REGISTER_SUCCESS, payload: message });
    pushEventSignupGTM({ method: 'email', provider: 'cognito' });
    history.push({
      pathname: '/email-verification',
      state: { email: cognitoUser.username }
    });
  } catch (err) {
    console.log('Register error!', err);
    let message = err?.message || 'Something went wrong. Please contact support.'
    message = message.replace('PreSignUp failed with error ', '');

    if (err?.message.startsWith('Error: Denied.')) {
      // Default message if the lambda runtime fails
      message = 'Something went wrong. Please contact support.'
    }
    // else use the given message.

    dispatch({ type: REGISTER_FAILURE, payload: { message, variant: 'error' } });
  }
};


// send code to user's email
export const requestResetPassword = (email) => async dispatch => {
  try {
    await authService.sendResetPasswordCode(email);
    return dispatch({ type: RESET_PASSWORD_SEND_CODE, payload: email });
  } catch (err) {
    console.log('Reset password - code send error!', err);
    return dispatch({ type: RESET_PASSWORD_SEND_CODE, payload: { error: 'Something went wrong' } });
  }
};

// submit new password
export const submitResetPassword = (formData) => async dispatch => {
  try {
    await authService.submitPasswordReset(formData);
    const message = { message: 'Password successfully reset!', variant: 'success' };
    return dispatch({ type: RESET_PASSWORD_FORM_SUBMIT, payload: message });
  } catch (err) {
    console.log(err);
    const message = { message: 'There was an error resetting your password. The email address or verification code was entered incorrectly.', variant: 'error' };
    return dispatch({ type: RESET_PASSWORD_FORM_SUBMIT, payload: message });
  }
};


export const updateDynamoUser = (attributes) => async dispatch => {
  try {
    const newUserAttributes = await edgeUsersApi.patch('/user', attributes);
    dispatch({ type: UPDATE_PROFILE, payload: newUserAttributes });
  } catch (err) {
    console.log(err);
    throw err;
  }
};


export const updateProfile = (formData) => async dispatch => {
  try {
    await authService.updateUser(formData);
    const newUserAttributes = await edgeUsersApi.patch(`/user`, formData);
    dispatch({ type: UPDATE_PROFILE, payload: newUserAttributes });
  } catch (err) {
    console.log(err);
    throw err;
  }
};


export const sendFeedbackEmail = async ({ email, message }) => {
  const subject = 'New User Feedback Form Submission!';
  var html = '';

  if (email) {
    html += `Email: ${email}\n\n`;
  }
  html += `Message:\n`;
  html += message;

  return await sendBasicEmail(subject, html);
};


const sendBasicEmail = async (subject, message) => {
  return await edgeUsersApi.post(`/mail/send`, { html: message, subject });
};


export const fetchLastProfessionalResponse = () => async dispatch => {
  dispatch({ type: REQUEST__FETCH_NON_PROFESSIONAL_FORM_RESPONSE });
  let data = null;
  try {
    const response = await edgeUsersApi.get('/user/nonprofessional-survey/latest');
    data = response.data;
  } catch (err) {
    console.log('fetchLastProfessionalResponse', err);
  }
  dispatch({ type: FETCH_NON_PROFESSIONAL_FORM_RESPONSE, payload: data });
};


export const submitBillingNonProfessionalForm = (values, { setErrors, setStatus, setSubmitting }) => async dispatch => {
  try {
    const response = await edgeUsersApi.post('/user/nonprofessional-survey/billing', values);

    setStatus({ success: true });
    setSubmitting(false);

    let isPro = false;
    if (response.data) {
      const { formResponse, complianceFormEditLocked } = response.data;
      dispatch({ type: FETCH_NON_PROFESSIONAL_FORM_RESPONSE, payload: formResponse });
      dispatch({ type: UPDATE_PROFILE, payload: { data: { complianceFormEditLocked } } });
      // TODO: This is a hack, we should be sending back is_pro from the backend.
      isPro = Boolean(complianceFormEditLocked);
    }

    TagManagerService.push({ event: 'checkout_professional_form_submit', status: isPro ? 'pro' : 'non-pro' })

    dispatch(fetchSubscriptionPlans());
    history.push('/billing/plan');

  } catch (err) {
    setSubmitting(false);
    setStatus({ success: false });
    if (err.response.data && err.response.data.message) {
      setErrors({ submit: err.response.data.message });
    } else {
      setErrors({ submit: 'An unexpected error occurred' });
    }
  }
};

export const submitSubscriptionNonProfessionalForm = (values, { setErrors, setStatus, setSubmitting }) => async dispatch => {
  try {
    const response = await edgeUsersApi.post('/user/nonprofessional-survey/subscription', values);
    setStatus({ success: true });
    setSubmitting(false);

    if (response.data) {
      const { formResponse, user } = response.data;
      dispatch({ type: FETCH_NON_PROFESSIONAL_FORM_RESPONSE, payload: formResponse });

      if (user?.requires_plan_change || user?.complianceFormEditLocked) {
        dispatch({ type: UPDATE_PROFILE, payload: { data: { ...user } } });
      }

      if (user?.requires_plan_change) {
        dispatch(fetchSubscriptionPlans());
        history.push('/account/subscription/update');
      } else {
        // TODO: COMPLETE FORM SUCCESS MESSAGE
      }
    }

  } catch (err) {
    setStatus({ success: false });
    setSubmitting(false);
    if (err.response.data && err.response.data.message) {
      setErrors({ submit: err.response.data.message });
    } else {
      setErrors({ submit: 'An unexpected error occurred' });
    }
  }
};

export const submitAddressForm = (values, { setErrors, setStatus, setSubmitting }) => async dispatch => {
  try {
    const response = await edgeUsersApi.post('billing/update-address', {
      address: values
    });

    dispatch({ type: UPDATE_PROFILE, payload: response });

    setStatus({ success: true });
  } catch (err) {
    console.log('handleAddressSubmit', err);

    setStatus({ success: false });
    if (err.response?.data && err.response?.data !== 'undefined' && err.response.data?.message !== 'Internal Server Error') {
      setErrors({ submit: err.response.data.message });
    } else {
      setErrors({ submit: err.message });
    }
  } finally {
    setSubmitting(false);
  }
};


export const submitUpdateAccountOwner = (values, { setErrors, setStatus, setSubmitting }) => async dispatch => {
  try {
    await dispatch(updateProfile(values));
    setStatus({ success: true });
  } catch (error) {
    setStatus({ success: false });
    setErrors({ submit: error.message });
  } finally {
    setSubmitting(false);
  }
};


///////////////////////////////////////////////////////////////////////
/////////////////////////////// BILLING ///////////////////////////////
///////////////////////////////////////////////////////////////////////


export const updateCart = (data) => {
  return { type: UPDATE_CART, payload: data };
};


export const fetchSubscriptionPlans = () => async dispatch => {
  let plans = [];
  dispatch({ type: REQUEST__FETCH_SUBSCRIPTION_PLANS });
  try {
    const response = await edgeUsersApi.get('/billing/plans');
    plans = response.data;
  } catch (err) {
    console.log('fetchSubscriptionPlans: ', err);
  }
  dispatch({ type: FETCH_SUBSCRIPTION_PLANS, payload: plans });
};


export const handleBillingAddressContinue = () => {
  history.push('/billing/non-professional');
};


export const handleBillingAddressSubmit = (values, { setErrors, setStatus, setSubmitting, dirty }) => async dispatch => {
  setSubmitting(true);

  if (!dirty) {
    history.push('/billing/non-professional');
    return;
  }

  try {
    const response = await edgeUsersApi.post('billing/update-address', {
      address: values
    });

    dispatch({ type: UPDATE_PROFILE, payload: response });
    setSubmitting(false);
    setStatus({ success: true });
    TagManagerService.push({ event: 'checkout_address_form_submit' });
    history.push('/billing/non-professional');

  } catch (err) {
    console.log('handleAddressSubmit', err);
    console.log(err.response?.data, err.response?.data?.message);
    if (err.response?.data && err.response?.data !== 'undefined') {
      setErrors({ 'submit': err.response.data.message });
    } else {
      setErrors({ 'submit': err.response.data.message });
    }
    setSubmitting(false);
    setStatus({ success: false });
  }
};


// TODO: What a nightmare
export const handleBillingInitialPaymentSuccess = (intentType, intent, priceId, subscriptionId, promotionCode, reason, isPaidOnCredit, partnerCode) => async (dispatch, getState) => {
  Sentry.setContext('handleBillingPaymentSuccess', {
    intentType, subscriptionId, reason, isPaidOnCredit
  });
  Sentry.captureMessage('handleBillingPaymentSuccess');
  console.log('handleBillingInitialPaymentSuccess');

  const plans = getState().account.plans;
  const user = getState().account.user;

  const plan = plans.find(p => p.id === priceId);
  if (!plan) {
    const error = Error('billingPaymentSuccess: No plans loaded');
    Sentry.captureException(error);
    throw error;
  }

  const subscriptionStatus = intentType === 'setup_intent' && !isPaidOnCredit ? 'trialing' : 'active';

  const subscriptionParams = {
    subscriptionId,
    subscriptionStatus,
    planDetails: {
      amount: plan.unit_amount,
      currency: plan.currency,
      interval: plan.recurring.interval,
      name: plan.product.name
    }
  };
  const userParams = {
    pendingSubscription: null,
    subscriptionId
  };

  // Provision access in the database.
  console.log('PROVISIONING:', subscriptionId, user.userSub);
  Sentry.captureMessage('PROVISIONING');

  if (!isPaidOnCredit) {
    try {
      pushBillingSuccessGTM({
        user, intentId: intent.id, subscriptionStatus, plan, subscriptionId, partnerCode, promotionCode
      })
    } catch (err) {
      console.error('pushBillingSuccessGTM', err);
    }
  }

  dispatch(provisionSubscription({
    subscriptionId,
    promotionCode,
    intentId: intent.id,
    intentType,
    initial: true
  }));

  // Provision access only on the frontend first, to avoid requiring a 3rd loading screen..
  dispatch({ type: UPDATE_PROFILE, payload: { data: userParams } });
  dispatch({ type: UPDATE_SUBSCRIPTION, payload: { data: subscriptionParams, id: subscriptionId } });

  // Wait for webhooks to resolve and refetch user, for payment method information.
  dispatch(refreshUserOnTimeout(5500));

  history.replace('/account/subscription', { referrer: '/billing/checkout', reason });
};


export const attachRewardfulReferralFromPromoCode = (promoCode) => async () => {
  let affiliateCode = rewardfulService.getReferralCode();

  try {
    affiliateCode = await rewardfulService.getReferralIDFromPromoCode(promoCode);
    Sentry.captureMessage(`Recieved rewardfulService code ${affiliateCode}`);
  } catch (err) {
    console.error(err);
    Sentry.captureException(err);
  }

  try {
    if (affiliateCode) {
      await edgeUsersApi.post('/rw/referral', { code: affiliateCode });
      Sentry.captureMessage(`Attributed rewardfulService code ${affiliateCode}`);
    }
  } catch (err) {
    console.error(err);
    Sentry.captureException(err);
  }
};


export const provisionSubscription = ({ subscriptionId, subscriptionToDelete, promotionCode, intentId, intentType, initial = false }) => async dispatch => {
  try {
    const response = await edgeUsersApi.post('/billing/provision-product', { subscriptionId, subscriptionToDelete, promotionCode, intentId, intentType, initial });
    if (response.data) {
      const data = response.data;
      dispatch({ type: UPDATE_PROFILE, payload: { data } });
    }
  } catch (err) {
    // TODO: Use real retry mechanism.
    console.log('provisionSubscription err:', err);
  }
};


export const cancelSubscription = (email, reason, password) => async dispatch => {
  await authService.loginWithEmailAndPassword(email, password);
  const idempotencyKey = shortUuid.generate();
  const response = await edgeUsersApi.post('/billing/cancel-subscription', { reason, idempotencyKey });
  dispatch({ type: CANCEL_SUBSCRIPTION, payload: response.data });
};


export const renewSubscription = (email, password) => async dispatch => {
  await authService.loginWithEmailAndPassword(email, password);
  const idempotencyKey = shortUuid.generate();
  const response = await edgeUsersApi.post('/billing/renew-subscription', { idempotencyKey });
  dispatch({ type: RENEW_SUBSCRIPTION, payload: response.data });
};


export const fetchUnpaidIntent = (user, subscription) => async dispatch => {
  let intent = null;
  if (subscription.pastDue() || subscription.pendingSubscriptionChange()) {
    try {
      dispatch({ type: REQUEST__FETCH_UNPAID_INTENT });
      const response = await edgeUsersApi.post('/billing/retrieve-unpaid-invoice', { paymentIntentId: subscription.unpaidPaymentIntentId });
      if (response.data) {
        intent = response.data;
      }
    } catch (err) {
      console.log('useUnpaidPaymentIntent err:', err);
      intent = null;
    }
  }
  dispatch({ type: FETCH_UNPAID_INTENT, payload: intent });
};


export const fetchUpcomingInvoice = (user, subscription) => async dispatch => {
  if (!subscription.id) return false;
  let invoice = null;
  try {
    dispatch({ type: REQUEST__FETCH_UPCOMING_INVOICE });
    invoice = await _fetchUpcomingInvoice({ subscriptionId: subscription.id });
  } catch (err) {
    console.error(err);
  }

  dispatch({ type: FETCH_UPCOMING_INVOICE, payload: invoice });
};


export const fetchExistingInvoice = (subscriptionId, intentType) => async dispatch => {
  let invoice = null;
  try {
    dispatch({ type: REQUEST__FETCH_NEW_PLAN_INVOICE });
    const response = await edgeUsersApi.post('/billing/retrieve-existing-invoice', {
      subscriptionId,
      intentType
    });
    if (response.data) {
      invoice = response.data;
    }
  } catch (err) {
    console.log(err);
  }

  dispatch({ type: FETCH_NEW_PLAN_INVOICE, payload: invoice });
};


export const fetchNewPlanInvoice = (subscription, newPriceId, prorationMeta) => async dispatch => {
  if (!subscription.id) return false;
  let invoice = null;

  try {
    const { reset, schedule } = prorationMeta;
    dispatch({ type: REQUEST__FETCH_NEW_PLAN_INVOICE });
    invoice = await _fetchUpcomingInvoice({
      subscriptionId: subscription.id,
      newPriceId,
      ...(reset && { reset }),
      ...(schedule && { schedule }),
    });
  } catch (err) {
    console.log('fetchNewPlanInvoice', err);
  }

  dispatch({ type: FETCH_NEW_PLAN_INVOICE, payload: invoice });
};


const _fetchUpcomingInvoice = async (params) => {
  let invoice = null;
  const response = await edgeUsersApi.post('/billing/retrieve-upcoming-invoice', params);
  if (response.data) {
    invoice = response.data;
  }
  return invoice;
};
