import {
  middlewareActionType as mAction,
} from 'src/redux/middleware/layoutSyncMiddleware/constants';
import { SchemaApi } from 'src/redux/layoutV2/base/layoutSchema';

import { copyLayout } from './layoutMapActions';

export const LAYOUT_TREE_TOGGLE_OPEN = mAction('layout-tree-toggle-open');
export const LAYOUT_TREE_CREATE_ITEM = mAction('layout-tree-create-item');
export const LAYOUT_TREE_COPY_LAYOUT = mAction('layout-tree-copy-layout');
export const LAYOUT_TREE_MOVE_ITEMS = mAction('layout-tree-move-items');
export const LAYOUT_TREE_RENAME_ITEM = mAction('layout-tree-rename-item');
export const LAYOUT_TREE_DELETE_ITEMS = mAction('layout-tree-delete-items');
export const LAYOUT_TREE_MOVE_ITEMS_WITH_BATCH_CONFLICTS = mAction('layout-tree-move-items-with-batch-conflicts');
export const LAYOUT_TREE_CLEAR_NEW_ITEM_INDICATOR = mAction('layout-tree-clear-new-item-indicator');
export const LAYOUT_TREE_SET_SELECTION = mAction('layout-tree-set-selection');


/** @typedef {import('../layoutActions').LayoutAction} LayoutAction */
/** @typedef {import('../layoutSchema').TreeItem} TreeItem */
/** @typedef {import('src/app/slicedForm/shared/elements/profileTree/useTreeController').BatchMoveAction} BatchMoveAction */



/**
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {string} id
 * @returns {LayoutAction}
 */
export function layoutTreeToggleOpen(
  namespace,
  schemaApi,
  id,
) {
  return {
    type: LAYOUT_TREE_TOGGLE_OPEN,
    namespace,
    payload: id
  }
}


/**
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {string} id
 * @returns {LayoutAction}
 */
export function layoutTreeSetSelection(
  namespace,
  schemaApi,
  id,
) {
  return {
    type: LAYOUT_TREE_SET_SELECTION,
    namespace,
    payload: id
  }
}


/**
 * WARN: IMPORTANT! 
 * We cannot batch(() => dispatch(createLayout), dispatch(createTreeItem))
 * If you were to do that, you'd be running the reudcer twice, and broadcasting
 * twice, sequentially. This update needs to be atomic.
 *
 * As a result, this action will do double-duty, creating both.
 *
 * WARN: THIS DUPLICATES copyLayout LOGIC, ON BOTH SIDES
 *
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {object} payload
 * @param {object} payload.oldLayoutId
 * @param {string} payload.parentId
 * @param {number} payload.index
 * @param {TreeItem} payload.newItem
 * @returns {import('../layoutActions').Thunk}
 */
export function layoutTreeCopyLayout(
  namespace,
  schemaApi,
  payload
) {
  // typegen requires us to use named function here
  return (dispatch, getState) => {
    const { oldLayoutId, ...itemPayload } = payload;
    const { id: newId, label: newName } = itemPayload.newItem;

    const { [namespace]: state } = getState();

    if (!state.layouts[oldLayoutId]) {
      return console.warn('copyLayout: oldLayoutId not found in state');
    }

    const params = schemaApi.copyLayout(oldLayoutId, newName, state);

    const layoutPayload = {
      newId,
      ...params
    }

    dispatch({
      type: LAYOUT_TREE_COPY_LAYOUT,
      namespace,
      payload: {
        itemPayload,
        layoutPayload
      }
    })
  }
}


/**
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {object} payload
 * @param {string} payload.parentId
 * @param {number} payload.index
 * @param {TreeItem} payload.newItem
 * @param {boolean} [payload.openFolder]
 * @returns {LayoutAction}
 */
export function layoutTreeCreateFolder(
  namespace,
  schemaApi,
  payload
) {
  return {
    type: LAYOUT_TREE_CREATE_ITEM,
    namespace,
    payload
  }
}


/**
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {object} payload
 * @param {string[]} payload.dragIds
 * @param {string} payload.parentId
 * @param {number} payload.index
 * @returns {LayoutAction}
 */
export function layoutTreeMoveItems(
  namespace,
  schemaApi,
  payload
) {
  return {
    type: LAYOUT_TREE_MOVE_ITEMS,
    namespace,
    payload
  }
}


/**
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {object} payload
 * @param {string} payload.id
 * @param {string} payload.name
 * @param {'folder'|'leaf'} payload.itemType
 * @returns {LayoutAction}
 */
export function layoutTreeRenameItem(
  namespace,
  schemaApi,
  payload
) {
  return {
    type: LAYOUT_TREE_RENAME_ITEM,
    namespace,
    payload
  }
}


/**
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {object} payload
 * @param {string[]} payload.ids
 * @returns {LayoutAction}
 */
export function layoutTreeDeleteItems(
  namespace,
  schemaApi,
  payload
) {
  return {
    type: LAYOUT_TREE_DELETE_ITEMS,
    namespace,
    payload
  }
}


/**
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {string} ids
 * @returns {LayoutAction}
 */
export function layoutTreeClearNewItemIndicator(
  namespace,
  schemaApi,
  id
) {
  return {
    type: LAYOUT_TREE_CLEAR_NEW_ITEM_INDICATOR,
    namespace,
    payload: id
  }
}


/**
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {object} payload
 * @param {string[]} payload.dragIds
 * @param {string} payload.parentId
 * @param {number} payload.index
 * @param {BatchMoveAction[]} payload.batchActions
 * @returns {LayoutAction}
 */
export function layoutTreeMoveItemsWithBatchConflicts(
  namespace,
  schemaApi,
  payload
) {
  return {
    type: LAYOUT_TREE_MOVE_ITEMS_WITH_BATCH_CONFLICTS,
    namespace,
    payload
  }
}





