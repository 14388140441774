import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ListContext } from './context';
import { Draggable } from 'react-beautiful-dnd';
import Item, { ItemPropTypes, ItemDefaultProps } from './Item';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {}
}));


const getItemStyle = (isDragging, isDraggingOver, draggableStyle) => {
  const style = {
    userSelect: 'none',
    position: 'relative',
    ...draggableStyle,
  };
  if (isDragging) {
    style.opacity = .6;
  }
  return style;
};


function DraggableItem(props) {
  const {
    draggableId,
    index,
    ...rest
  } = props;

  const classes = useStyles();
  const listContext = useContext(ListContext);

  const _disabled = rest.disabled || listContext.disabled;

  return (
    <Draggable
      draggableId={draggableId}
      index={index}
      isDragDisabled={_disabled}
    >
      {(provided, snapshot) => (
        <Item
          {...rest}
          {...provided.draggableProps}
          DragHandleProps={provided.dragHandleProps}
          ref={provided.innerRef}
          style={getItemStyle(snapshot.isDragging, snapshot.isDraggingOver, provided.draggableProps.style)}
        >
          {props.children}
        </Item>
      )}
    </Draggable>
  );
}


DraggableItem.displayName = 'ActionList.DraggableItem'


const { DragHandleProps: _1, draggable: _2, ...otherItemProps } = ItemPropTypes;
const { DragHandleProps: _3, draggable: _4, ...otherItemDefaultProps } = ItemDefaultProps;


DraggableItem.propTypes = {
  ...otherItemProps,
  draggableId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired
};


DraggableItem.defaultProps = {
  ...otherItemDefaultProps
}


export default React.memo(DraggableItem);
