import {
  makeStyles,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: theme.fontSize.label,
    lineHeight: '19px',
    marginTop: 0,
    marginBottom: 0,
    textOverflow: 'ellipsis',
  },
}));


/**
 * Often times, the caller must pass styles to the label themselves.
 * This class lets them style it consistently.
 */
function ItemLabel({ className, children }) {
  const classes = useStyles();
  return <Typography variant="body1" className={clsx(className, classes.root)}>{children}</Typography>
}


ItemLabel.displayName = 'ActionList.ItemLabel'

export default ItemLabel;


