import {
  Box,
  Button,
  IconButton,
  Link,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import usePrefetchSubscriptionPlans from 'src/app/AccountPage/Subscription/hooks/usePrefetchSubscriptionPlans';
import { WEBSITE_URL } from 'src/constants';


const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    backgroundColor: theme.palette.background.marketingModalDark,
    '& h2': {
      fontSize: 24,
      fontWeight: 'bold'
    },
    '& h3': {
      fontSize: 18,
      fontWeight: 'bold'
    },
    '& h4': {
      fontSize: 16,
      fontWeight: 'bold'
    },
    '& .MuiList-root': {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0
    },
    '& .MuiListItem-root': {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 1,
      paddingBottom: 1,
      '& .MuiTypography-body1': {
        fontSize: 15,
        fontWeight: 500
      }
    },
    '& .success': {
      color: theme.palette.success.main
    },
    '& p': {
      lineHeight: 1.4
    }
  },
  button: {
    width: '100%',
    backgroundColor: theme.palette.primary.marketing
  },
  learnMore: {
    backgroundColor: theme.palette.background.marketingModalLight
  },
  closeButton: {
    padding: 3,
    top: -3,
    right: -7,
    borderRadius: 4,
    '& .MuiSvgIcon-root': {
      fontSize: 24
    }
  },
  planTagColor: {
    color: theme.palette.text.planTag
  }
}));

function MarketingModal({
  className,
  suggestedPlanLevel,
  titleContent,
  includeLearnMoreLink,
  onClose,
  children,
}) {
  const classes = useStyles();
  const theme = useTheme();

  usePrefetchSubscriptionPlans(); // Makes the experience better on the payment page

  return (
    <Box
      className={clsx(classes.root, className)}
    >
      <Box p={3} pt={3}>
        <Box display="flex" justifyContent="space-between">
          {titleContent
            ? titleContent
            : (
              <Typography variant="h2"><span style={{ marginRight: 6 }}>🚀</span> Upgrade to <span style={{ color: theme.palette.text.planTag }}>{suggestedPlanLevel}</span></Typography>
            )}
          <IconButton
            className={classes.closeButton}
            onClick={onClose}
            disableRipple
            aria-label="Close Upgrade popup"
          >
            <ClearIcon />
          </IconButton>
        </Box>
        {children}
        <Box pt={1}>
          <Button
            className={classes.button}
            component={RouterLink}
            color="primary"
            variant="contained"
            size="large"
            to={'/account/subscription/update'}
          >
            Upgrade Now
          </Button>
        </Box>
      </Box>
      {Boolean(includeLearnMoreLink) && (
        <Box
          className={classes.learnMore}
          p={1}
          textAlign="center"
        >
          <Link
            variant="body1"
            href={WEBSITE_URL}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.resendLink}
          >
            {suggestedPlanLevel ? `Learn more about ${suggestedPlanLevel} features` : 'Learn more about available plans'}
          </Link>
        </Box>
      )}

    </Box>
  );
}

MarketingModal.defaultProps = {
  includeLearnMoreLink: true
}

MarketingModal.propTypes = {
  className: PropTypes.string,
  suggestedPlanLevel: PropTypes.string,
  titleContent: PropTypes.node,
  includeLearnMoreLink: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node
}

export default MarketingModal;
