import { getInitialExpressionState, EXPRESSION_NAMESPACE } from "../base/layoutExpressionConfig"
import { PROFILE_CONFIG } from "./profileConfig"
import { HistorySchemaApi } from './schema'
import {
  predefinedLayouts,
  predefinedLayoutTreeItems,
  predefinedLayoutTreeOpenState,
  predefinedComponents,
} from './predefinedLayouts'
import { LINK_COLORS, PREDEF_FOLDER_ID } from "../base/layoutSchema";

const defaultLinks = () => {
  return {
    [LINK_COLORS.white.name]: {
      ticker: 'AAPL',
      historicalDate: undefined,
    }
  }
}


export default function makeInitialState() {

  const expressionState = getInitialExpressionState(EXPRESSION_NAMESPACE.HISTORY_FULL);

  const { layout, components } = HistorySchemaApi._createDefaultLayout({ name: 'New Layout' }, false);

  const layoutId = HistorySchemaApi.generateId();

  return {
    activeLayout: layoutId,
    globalSettings: {
      layoutDrawerOpen: true
    },
    linksByLayout: {
      [layoutId]: defaultLinks(),
      ...Object.keys(predefinedLayouts).reduce((acc, id) => {
        acc[id] = defaultLinks();
        return acc;
      }, {})
    },
    layouts: {
      ...predefinedLayouts,
      [layoutId]: layout,
    },
    layoutTree: {
      items: {
        'root': {
          id: 'root',
          isFolder: true,
          children: [PREDEF_FOLDER_ID, layoutId],
        },
        ...predefinedLayoutTreeItems,
        [layoutId]: {
          id: layoutId,
          label: layout.name
        }
      },
      openState: [...predefinedLayoutTreeOpenState],
    },
    components: {
      ...predefinedComponents,
      ...components
    },
    profileMap: {
      [PROFILE_CONFIG.HISTORY_FILTERS.listKey]: PROFILE_CONFIG.HISTORY_FILTERS.predefinedProfiles,
      [PROFILE_CONFIG.HISTORY_COLUMNS.listKey]: PROFILE_CONFIG.HISTORY_COLUMNS.predefinedProfiles,
      [PROFILE_CONFIG.HISTORY_TIMESERIES.listKey]: PROFILE_CONFIG.HISTORY_TIMESERIES.predefinedProfiles,
      [PROFILE_CONFIG.HISTORY_AGGREGATES.listKey]: PROFILE_CONFIG.HISTORY_AGGREGATES.predefinedProfiles,
    },
    profileTree: {
      [PROFILE_CONFIG.HISTORY_FILTERS.listKey]: HistorySchemaApi.generateInitialProfileTree(PROFILE_CONFIG.HISTORY_FILTERS.listKey),
      [PROFILE_CONFIG.HISTORY_COLUMNS.listKey]: HistorySchemaApi.generateInitialProfileTree(PROFILE_CONFIG.HISTORY_COLUMNS.listKey),
      [PROFILE_CONFIG.HISTORY_TIMESERIES.listKey]: HistorySchemaApi.generateInitialProfileTree(PROFILE_CONFIG.HISTORY_TIMESERIES.listKey),
      [PROFILE_CONFIG.HISTORY_AGGREGATES.listKey]: HistorySchemaApi.generateInitialProfileTree(PROFILE_CONFIG.HISTORY_AGGREGATES.listKey),
    },
    orderings: {
      expressions: expressionState.orderings
    },
    expressions: expressionState.expressions,
    ui: {
      showComponentSelectOverlay: false,
      layoutTree: {
        newItemIndicators: [],
      }
    },
    isFetching: {
      initial: true, // all profile stuff
    },
  }

}
