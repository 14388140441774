/** 
 * @typedef {Object} ChangesetResponse
 * @property {number} revision
 * @property {boolean} success
 */

/**
 * @typedef {Object} LayoutResponse
 * @property {number} revision
 * @property {object} data
 */

/**
 * @typedef {Object} StoredValue
 * @property {Object} data
 * @property {number} revision
 * @property {number} writtenAt
 */

/**
 * @typedef {Object} MessageEvent
 * @property {string} data
 * @property {string} origin
 * @property {string} lastEventId
 * @property {BroadcastChannel} source
 * @property {MessagePort[]} ports
 */

/**
 * @callback RevisionCheckCallback
 * Can be used to manually check if a revision should be made, based on the item's value
 * @param {object} args
 * @param {*} args.prevValue
 * @param {*} args.nextValue
 * @returns {boolean}
 */

/**
 * @callback RevisionRuleItemCallback
 * During REVISION_RULES writing, optionally modify the record. 
 * Allows us to map before saving to db/localstorage, like removing predefined profiles.
 * @param {object} args
 * @param {string[]} args.pathParts - The path to the value, like [profiles, scannerFilter, id123]
 * @param {*} args.record - The value at that path.
 * @param {object|array} args.parent - The parent object or array of the value.
 * @param {object} args.state -  Entire state of this slice/namespace
 */

/**
 * @callback ReadRuleItemCallback
 * During READ_RULES reading, optionally modify the record. 
 * Allows us to modify the data on init, like adding predefined profiles, updating plan_level permissions, etc.
 * @param {object} args
 * @param {string[]} args.pathParts - The path to the value, like [profiles, scannerFilter, id123]
 * @param {*} args.record - The value at that path.
 * @param {object|array} args.parent - The parent object or array of the value.
 * @param {object} args.user - Current user in redux. Needed for planLevel mappings.
 * @param {object} args.state - Entire state of this slice/namespace
 */

/**
 * @typedef {Object} RevisionRule
 * Defines the syncing behavior of our reducer. This tells us:
 *  - How to map records going from redux into db/localstorage
 *  - The ID/sortKey of the record for DDB
 *  - Specifics on how to build the Revision changeset for DDB
 * @property {RevisionRuleItemCallback} [getId] - The ID of the individual record. If not defined, then this slice of state will not be considered for syncing to DB
 * @property {RevisionRuleItemCallback} [getRecordType] - The recordType in dynamoDB, usually the full path. If not defined, then this slice of state will not be considered for syncing to DB
 * @property {RevisionRuleItemCallback} [mapRecord] - Modify a single record
 * @property {RevisionRuleItemCallback} [filterRecord] - Remove record before sending to DB/localstorage (boolean)
 * @property {boolean|RevisionCheckCallback} [add] - If true, an 'add' action causes a revision bump
 * @property {boolean|RevisionCheckCallback} [delete] - If true, a 'delete' action causes a revision bump
 * @property {boolean|RevisionCheckCallback} [put] - If true, a 'put' (update) action causes a revision bump
 */

/**
 * @typedef {Object} ReadRule
 * Like RevisionRule, but specifies mapping when data is READ from a datasource
 * As a result, it does not build changesets, or cause network requests etc. Just mappings.
 * NOTE: Must be seperated, because mappings often must happen on the entire collection, not just a single record.
 *
 * @property {ReadRuleItemCallback} [mapRecord] - Modify a single record
 * @property {ReadRuleItemCallback} [filterRecord] - Remove a single record
 **/

/**
 * @typedef {Object} ChangeItem
 * @property {keyof ACTIONS} action
 * @property {string} key
 * @property {Object} [data]
 */


/** Must be the same for all reducers which use this, so we can reuse logic */
export const MIDDLEWARE_PREFIX = '@layout-mdw'


/**
 * Make action constants for your action creators 
 * @param {string} type
 * @returns {string}
 */
export const middlewareActionType = type => `${MIDDLEWARE_PREFIX}/${type}`;


export const INIT_SOURCE = {
  storage: 'storage',
  database: 'database',
  initialState: 'initialState',
}



export const INIT_ACTIONS = {
  START: '~INIT~/START',
  SUCCESS: '~INIT~/SUCCESS',
  FAILURE: '~INIT~/FAILURE',
  TEARDOWN: '~INIT~/TEARDOWN',
}


export const CHANGESET_ACTIONS = {
  put: 'put',
  add: 'add',
  delete: 'delete',
  // UPDATE: 'UPDATE' 
}




