
import { PROFILE_PERMISSION_SCOPES } from 'src/hooks/useUserPlanPermissions';
import { SchemaApi } from 'src/redux/layoutV2/base/layoutSchema';




/** @typedef {import('src/app/slicedForm/mapping/mappingDirections').ProfileStruct} ProfileStruct */
/** @typedef {import('./layoutSchema').Layout} Layout */
/** @typedef {import('src/app/slicedForm/shared/reducers/profileTreeReducer').TreeItem} TreeItem */



/**
 * Manual instructions for how to deal with Permissions modifications.
 * TODO: This is pretty horrible. Also it probably needs to be extended to all layouts.
 **/
const scopeComponentModificatons = {
  [PROFILE_PERMISSION_SCOPES.disabledCustomization]: (profileItems, componentId, component, scopeValueForPlan, profileConfig) => {
    try {
      // Scope value doesn't require changes
      if (scopeValueForPlan !== true) return;

      // If component has this profile
      if (!component?.[profileConfig.idKey]) return;

      const activeProfile = profileItems.find(p => p.id === component[profileConfig.idKey]);

      // If profile is custom
      if (activeProfile.predefined) return;

      // Change the active profile to the default
      const oldId = component[profileConfig.idKey];
      component[profileConfig.idKey] = profileConfig.defaultProfileId;

      console.log(
        `[LAYOUT:fixProfilePermissions:disabledCustomization:${profileConfig.idKey}] Changed profile to default ${componentId}:${profileConfig.idKey}, ${oldId} => ${profileConfig.defaultProfileId}`
      );
    } catch (err) {
      console.error(`[ERROR: fixProfilePermissions:disabledCustomization:${profileConfig.idKey}], component:${componentId}`, err);
    }
  },
  [PROFILE_PERMISSION_SCOPES.disallowedPredefinedProfiles]: (profileItems, componentId, component, scopeValueForPlan, profileConfig) => {
    try {
      // Scope value doesn't require changes
      if (!Array.isArray(scopeValueForPlan) || !scopeValueForPlan.length) return;

      // If component has this profile
      if (!component?.[profileConfig.idKey]) return;

      // If profile is not in the disallowed list
      if (!scopeValueForPlan.includes(component[profileConfig.idKey])) return;

      // Change the active profile to the default
      const oldId = component[profileConfig.idKey];
      component[profileConfig.idKey] = profileConfig.defaultProfileId;

      console.log(
        `[LAYOUT:fixProfilePermissions:disallowedPredefinedProfiles:${profileConfig.idKey}] Changed profile to default ${componentId}:${profileConfig.idKey}, ${oldId} => ${profileConfig.defaultProfileId}`
      )
    } catch (err) {
      console.error(`[ERROR: fixProfilePermissions:disallowedPredefinedProfiles:${profileConfig.idKey}], component:${componentId}`, err);
    }
  }
}


/**
 * When a user downgrades, their layout state might need to be adjusted.
 * For example, Basic users aren't allowed to use Custom aggregate profiles.
 * TODO: Wow what a nightmare
 *
 * @param {string} componetId
 * @param {object} component
 * @param {object} permissions
 * @param {string} planLevel
 * @param {object} state
 * @param {SchemaApi} schemaApi
 **/
export const fixProfilePermissionsOnDowngrade = (
  componentId,
  component,
  permissions,
  planLevel,
  state,
  schemaApi,
) => {
  try {
    if (!planLevel || !permissions || !Object.keys(permissions).length) {
      return component;
    }

    Object.entries(permissions).forEach(([profileListKey, scopeObject]) => {
      const profileConfig = Object.values(schemaApi.profileConfig).find(p => p.listKey === profileListKey);
      const profileItems = state?.[profileListKey] || [];

      Object.entries(scopeObject).forEach(([scope, scopeValue]) => {
        if (!scope || !scopeValue) return;
        if (!(scope in scopeComponentModificatons)) return;
        if (!scopeValue?.[planLevel]) return;

        scopeComponentModificatons[scope](
          profileItems,
          componentId,
          component,
          scopeValue[planLevel],
          profileConfig
        );
      });
    });
  } catch (err) {
    console.error(`[ERROR: fixProfilePermissions], component:${componentId}`, err);
  }

  return component;
}


/** 
 * @param {{string: Layout|ProfileStruct}} obj
 * @returns {{string: string}}
 */
export const makeLabelMap = (obj) => {
  if (!obj) return {};

  return Object.entries(obj).reduce((acc, [id, profOrLay]) => {
    acc[id] = profOrLay.name;
    return acc;
  }, {});
}
