import _partial from 'lodash/partial';

/** @typedef {import('./boundLayoutActions.jsdoc.js').BoundLayoutActionCreators} BoundLayoutActionCreators */
/** @typedef {import('./layoutExpressionConfig').ExpressionPayload} ExpressionPayload */


/** 
 * Attach the namespace to each function ahead of time.
 * @param {function[]} actions
 * @param {namespace}
 * @param {Array.<*>} args
 * @returns {{string: Function}}
 */
export function bindNamespaceActionCreators(
  actions,
  ...args
) {
  return Object.entries(actions).reduce((acc, [key, action]) => {
    if (typeof action === 'function') {
      acc[key] = _partial(action, ...args);
    }
    return acc;
  }, {});
}
