import shortUuid from 'short-uuid';
import { PROFILE_TYPES } from '../base/layoutSchema';
import {
  predefinedHistoryFilterProfiles,
  predefinedHistoryColumnProfiles,
  predefinedHistoryAggregateProfiles,
  predefinedHistoryTimeseriesProfiles,
} from './defaultProfiles';


export const generateId = () => shortUuid.generate()

/** @typedef {import("../layoutSchema").ProfileConfigItem} ProfileConfigItem **/


export const PROFILE_CONFIG = {
  HISTORY_FILTERS: {
    label: 'Filters',
    metaKey: 'HISTORY_FILTERS',
    listKey: 'historyPageFilterProfiles',
    idKey: 'historyPageFilterProfileId',
    type: PROFILE_TYPES.FILTER,
    predefinedProfiles: predefinedHistoryFilterProfiles,
    defaultProfileId: Object.keys(predefinedHistoryFilterProfiles)[0]
  },
  HISTORY_COLUMNS: {
    label: 'Columns',
    metaKey: 'HISTORY_COLUMNS',
    listKey: 'historyPageColumnProfiles',
    idKey: 'historyPageColumnProfileId',
    type: PROFILE_TYPES.COLUMN,
    predefinedProfiles: predefinedHistoryColumnProfiles,
    defaultProfileId: Object.keys(predefinedHistoryColumnProfiles)[0]
  },
  HISTORY_AGGREGATES: {
    label: 'Market Stats',
    metaKey: 'HISTORY_AGGREGATES',
    listKey: 'historyPageAggregateProfiles',
    idKey: 'historyPageAggregateProfileId',
    type: PROFILE_TYPES.AGGREGATE,
    predefinedProfiles: predefinedHistoryAggregateProfiles,
    defaultProfileId: Object.keys(predefinedHistoryAggregateProfiles)[0]
  },
  HISTORY_TIMESERIES: {
    label: 'Time Series',
    metaKey: 'HISTORY_TIMESERIES',
    listKey: 'historyPageTimeseriesProfiles',
    idKey: 'historyPageTimeseriesProfileId',
    type: PROFILE_TYPES.AGGREGATE,
    // SUBTYPE: TIMESERIES? or new Type entirely?
    predefinedProfiles: predefinedHistoryTimeseriesProfiles,
    defaultProfileId: Object.keys(predefinedHistoryTimeseriesProfiles)[0]
  },
};

