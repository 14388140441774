import shortUuid from 'short-uuid';
import _pick from 'lodash/pick';
import { ALLOW_NULL, EXPRESSION_FORMATS, EXPRESSION_SYMBOLS, INPUTS } from 'src/app/slicedForm/FilterForm/definitions/inputEnums';
import { COLUMN_TYPES } from 'src/app/slicedForm/shared/schema/schemaBuilder';



/** @typedef {import('./reducerUtils.js').ValueOf} ValueOf*/


/** 
 * @typedef {object} ColumnSchema
 * @property {string} column - The column name
 */

/**
 * @typedef {Object} Expression
 * @property {string} label - User-facing name
 * @property {string} name - Unique ID
 * @property {string} expression - The expression itself (e.g. '[[A]] + [[B]]')
 * @property {{string: ColumnSchema}} args - The columns used in the expression (e.g. { 'A': { column: 'vol' } })
 * @property {ValueOf<EXPRESSION_FORMATS>} format - The format of the expression (e.g. 'compact')
 * @property {ValueOf<EXPRESSION_SYMBOLS>} symbol - The symbol of the expression (e.g. 'currency')
 */


/**
 * @typedef {Expression} ExpressionColDef
 * @property {string} columnType
 * @property {string[]} comparesTo
 * @property {ValueOf<ALLOW_NULL>} allowNull
 * @property {boolean} allowNullDefault
 * @property {ValueOf<INPUTS>} input
 * @property {boolean} timeSlice
 */


/**
 * @typedef {object} ExpressionPayload
 * @property {ValueOf<EXPRESSION_NAMESPACE>} namespace
 * @property {Expression[]} expressions
 */

/**
 * Namespaces allow us to share or isolate expressions between layouts/components.
 * Even though we have namespaces, IDs MUST be considered unique across them.
 * This allows us to much more easily delete expression IDs from disparate profiles.
 */
export const EXPRESSION_NAMESPACE = {
  HISTORY_FULL: 'historyFull',
  REALTIME: 'realtime',
  HISTORY_COMP: 'historyComp',
}

/**
 * Base behavior for a new expression
 */
const defaultProperties = {
  label: 'New Expression',
  expression: '',
  format: EXPRESSION_FORMATS.COMPACT,
  symbol: EXPRESSION_SYMBOLS.CURRENCY,
  args: {}
}

/**
 * Properties that must exist on the ColumnDef for an expression,
 * but should not be saved to redux/server.
 */
export const staticExpressionGridProperties = {
  columnType: COLUMN_TYPES.expression.name,
  comparesTo: COLUMN_TYPES.expression.comparesTo,
  group: 'expressions',
  // This should be on filters, not columns. Hmm..
  allowNull: ALLOW_NULL.USER_CONTROLLED,
  allowNullDefault: false,
  input: INPUTS.COMPARE,
  timeSlice: true
}

/**
 * Returns a new expression with default properties
 * @returns {ExpressionColDef}
 **/
export const makeDefaultExpression = () => ({
  ...defaultProperties,
  ...staticExpressionGridProperties,
});

export const EXPR_PREFIX = 'expr_';

/**
 * Generate a unique ID for an expression.
 * Prefixed with expr_, as in many places on the Forms,
 * its far easier to check a prefix than to push a flag 
 * all the way down through state.
 *
 * Like: 'expr_abcd1234'
 * @returns {string}
 **/
export const generateExpressionName = () => {
  return `${EXPR_PREFIX}${shortUuid.generate()}`
}


/**
 * Remove extra properties added to the expression for the Grid,
 * but not wanted inside redux. Apply before reducing.
 * @param {ExpressionColDef} expression
 * @returns {Expression}
 **/
export const removeExpressionGridProperties = (expression) => {
  // Remove some properties we don't want in redux/database
  return Object.entries(expression).reduce((acc, [key, val]) => {
    if (key in staticExpressionGridProperties) return acc;
    return { ...acc, [key]: val };
  }, {})
}


/**
 * Add the grid properties during selection
 * @param {Expression} expression
 * @returns {ExpressionColDef}
 **/
export const addExpressionGridProperties = (expression) => {
  return {
    ...staticExpressionGridProperties,
    ...expression,
  }
}


export const initialState = {
  [EXPRESSION_NAMESPACE.REALTIME]: {
    'expr_realtime_0': {
      ...defaultProperties,
      name: 'expr_realtime_0',
      label: 'High Chg',
      expression: 'GREATEST( [[A]], [[B]] ) - [[C]]',
      format: EXPRESSION_FORMATS.FIXED,
      symbol: EXPRESSION_SYMBOLS.CURRENCY,
      args: {
        'A': { column: 'h' },
        'B': { column: 'pm_h' },
        'C': { column: 'day_m1_close' }
      },
    },
  },
  [EXPRESSION_NAMESPACE.HISTORY_COMP]: {
    'expr_history_comp_0': {
      ...defaultProperties,
      name: 'expr_history_comp_0',
      label: 'High Chg',
      expression: 'GREATEST( [[A]], [[B]] ) - [[C]]',
      format: EXPRESSION_FORMATS.FIXED,
      symbol: EXPRESSION_SYMBOLS.CURRENCY,
      args: {
        'A': { column: 'day0_high' },
        'B': { column: 'day0_pm_high' },
        'C': { column: 'day_m1_close' },
      },
    },
  },
  [EXPRESSION_NAMESPACE.HISTORY_FULL]: {
    'expr_history_full_0': {
      ...defaultProperties,
      name: 'expr_history_full_0',
      label: 'High Chg',
      expression: 'GREATEST( [[A]], [[B]] ) - [[C]]',
      format: EXPRESSION_FORMATS.FIXED,
      symbol: EXPRESSION_SYMBOLS.CURRENCY,
      args: {
        'A': { column: 'day0_high' },
        'B': { column: 'day0_pm_high' },
        'C': { column: 'day_m1_close' },
      },
    },
  }


}


const initialOrderings = {
  [EXPRESSION_NAMESPACE.REALTIME]: ['expr_realtime_0'],
  [EXPRESSION_NAMESPACE.HISTORY_COMP]: ['expr_history_comp_0'],
  [EXPRESSION_NAMESPACE.HISTORY_FULL]: ['expr_history_full_0']
}


/**
 * @param {string[]} expressionNamespaces
 * @returns {{expressions: object, orderings: object}}
 */
export function getInitialExpressionState(...expressionNamespaces) {
  return {
    expressions: _pick(initialState, expressionNamespaces),
    orderings: _pick(initialOrderings, expressionNamespaces)
  }
}




