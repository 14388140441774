import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTime } from 'date-fns';
import { nextTradingDay4amUnix } from 'src/utils/datetime/date-fns.tz';
import { useComponent, useComponentData, useLayoutApi } from '../../base/layoutContext';



/**
 * @param {UnixMilliseconds} expiration
 * @returns {boolean}
 */
export const isExpired = (expiration) => expiration && expiration <= (+new Date());


/**
 * Returns next business day at 4am in EST
 * @return {UnixMilliseconds} next expiration date
 */
const getNextExpiry = () => {
  let nextExpiry = getTime(nextTradingDay4amUnix());
  // if (process.env.REACT_APP_USERS_LAMBDA_STAGE === 'local') {
  //   // nextExpiry = (+new Date()) + 15000
  // }
  return nextExpiry;
}


const defaultExcludedTickers = [];


/**
 * @typedef {Object} MosaicTickerExcludeCallbacks
 * @property {function(): string[]} getExcludedTickers
 *    - Returns the list of excluded tickers. If the list is expired, returns an empty array.
 *    - You can use this function itself as a useEffect dependancy if you want to track when the underlying list has been updated.
 *    - This is a function in order to enforce the expiration to run every render.
 * @property {function(string): void} addExcludedTicker
 *    - Add a ticker to the list. If the list is expired, prev values will be reset.
 * @property {function(string): void} removeExcludedTicker
 *    - Remove ticker from list. If the list is expired, it will be reset.
 */


/**
 * Adds and removes excluded tickers for a Mosaic component. Stored in Redux.
 * Also handles expiration of the excluded tickers at 4am every business day.
 *
 * We also are checking the profile during load to see if the list is expired, so we can update
 * before the initial render. Annoying, but better UX.
 *
 * @param {string} componentId
 * @param {string} layoutId
 * @return {MosaicTickerExcludeCallbacks} 
 */
function useMosaicTickerExclude() {
  const dispatch = useDispatch();
  const { updateComponent } = useLayoutApi();
  const { componentId } = useComponent();
  const {
    excludedTickers = defaultExcludedTickers,
    excludedTickersExpiration = null,
  } = useComponentData();


  return useMemo(() => {
    const addExcludedTicker = (ticker) => {
      let nextExpiry = getNextExpiry()

      let nextList;
      if (isExpired(excludedTickersExpiration)) {
        console.debug(`[tickerExclude] EXPIRED LIST, ADDING TICKER. nextExpiry:${nextExpiry} now:${+new Date()}`);
        nextList = [...defaultExcludedTickers, ticker];
      } else {
        console.debug(`[tickerExclude] ADDING TICKER. nextExpiry:${nextExpiry} now:${+new Date()}`);
        nextList = [...new Set([...excludedTickers, ticker])];
      }

      dispatch(updateComponent(componentId, {
        excludedTickers: nextList,
        excludedTickersExpiration: nextExpiry,
      }));
    }

    const getExcludedTickers = () => {
      return isExpired(excludedTickersExpiration) ? [] : excludedTickers;
    }

    const removeExcludedTicker = (ticker) => {
      let nextList;

      if (isExpired(excludedTickersExpiration)) {
        console.log(`[tickerExclude] EXPIRED LIST, REMOVING TICKER`);
        nextList = defaultExcludedTickers;
      } else {
        console.debug(`[tickerExclude] REMOVING TICKER`);
        nextList = excludedTickers.filter(t => t !== ticker);
      }

      dispatch(updateComponent(componentId, {
        excludedTickers: nextList,
        excludedTickersExpiration: nextList.length ? excludedTickersExpiration : null,
      }));
    }

    return {
      getExcludedTickers,
      addExcludedTicker,
      removeExcludedTicker,
    }
  }, [componentId, updateComponent, excludedTickers, excludedTickersExpiration])
}


export default useMosaicTickerExclude;
