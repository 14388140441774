import _uniqueId from 'lodash/uniqueId';
import { PREDEF_PREFIX, initialFinancialsAlarms, initialKeyStatsAlarms } from 'src/redux/profileSettings/profileSettingsConfig';
import {
  ARRAY_OPS,
  BOOLEAN_OPS,
  DATE_TYPES,
  ROLLING_DATE_OPS,
  AGGREGATES,
} from 'src/app/slicedForm/FilterForm/definitions/inputEnums';
import { STRUCTURAL_TYPES } from 'src/app/slicedForm/mapping/mappingDirections';
import { shareGroupOptions } from 'src/app/slicedForm/FilterForm/definitions/dropdownOptions';
import { addPredefinedKey, col, val, makeAggNames } from '../base/layoutSchema';

/** @typedef {import('src/app/slicedForm/mapping/mappingDirections').ProfileStruct} ProfileStruct */
/** @typedef {import('src/app/slicedForm/shared/reducers/profileTreeReducer').TreeItem} TreeItem */



//************************ SCANNER ************************//


export const predefinedLayoutScannerColumnProfiles = addPredefinedKey({
  [`${PREDEF_PREFIX}default`]: {
    id: `${PREDEF_PREFIX}default`,
    name: 'Default',
    columns: [
      col('index'),
      col('ticker'),
      col('total_news_count'),
      col('session_lp'),
      col('session_v'),
      col('session_chg_p'),
      col('sharesfloat'),
      col('marketcap'),
    ]
  },
  [`${PREDEF_PREFIX}premarket`]: {
    id: `${PREDEF_PREFIX}premarket`,
    name: 'Premarket',
    columns: [
      col('index'),
      col('ticker'),
      col('total_news_count'),
      col('session_lp'),
      col('pm_v'),
      col('pm_chg_p'),
      col('sharesfloat'),
      col('marketcap'),
    ]
  },
  [`${PREDEF_PREFIX}after_hours`]: {
    id: `${PREDEF_PREFIX}after_hours`,
    name: 'After Hours',
    columns: [
      col('index'),
      col('ticker'),
      col('total_news_count'),
      col('session_lp'),
      col('ah_v'),
      col('ah_chg_p'),
      col('sharesfloat'),
      col('marketcap'),
    ]
  }
});


export const predefinedLayoutScannerFilterProfiles = addPredefinedKey({
  [`${PREDEF_PREFIX}default`]: {
    id: `${PREDEF_PREFIX}default`,
    name: 'Default',
    filters: {
      AND: [
        {
          allowNull: true,
          left: col('share_group'),
          operator: ARRAY_OPS.IN,
          right: [val([shareGroupOptions[0].name])]
        }
      ]
    }
  },
  [`${PREDEF_PREFIX}small-cap`]: {
    id: `${PREDEF_PREFIX}small-cap`,
    name: 'Small Cap',
    filters: {
      AND: [
        {
          left: col('session_lp'),
          operator: BOOLEAN_OPS.BTW,
          right: [
            val(2),
            val(15)
          ]
        },
        {
          left: col('session_chg_p'),
          operator: BOOLEAN_OPS.GE,
          right: [val(10)]
        },
        {
          left: col('session_v'),
          operator: BOOLEAN_OPS.GE,
          right: [val(300_000)]
        },
        {
          allowNull: true,
          left: col('share_group'),
          operator: ARRAY_OPS.IN,
          right: [val([shareGroupOptions[0].name])]
        }
      ]
    }
  },
  [`${PREDEF_PREFIX}large-cap`]: {
    id: `${PREDEF_PREFIX}large-cap`,
    name: 'Large Cap',
    filters: {
      AND: [
        {
          left: col('session_lp'),
          operator: BOOLEAN_OPS.BTW,
          right: [
            val(15),
            val(100)
          ]
        },
        {
          left: col('session_chg_p'),
          operator: BOOLEAN_OPS.GE,
          right: [val(5)]
        },
        {
          left: col('session_v'),
          operator: BOOLEAN_OPS.GE,
          right: [val(300_000)]
        },
        {
          allowNull: true,
          left: col('share_group'),
          operator: ARRAY_OPS.IN,
          right: [val([shareGroupOptions[0].name])]
        }
      ]
    }
  },
  [`${PREDEF_PREFIX}afterhours-scanner`]: {
    id: `${PREDEF_PREFIX}afterhours-scanner`,
    name: 'Afterhours Scanner',
    filters: {
      AND: [
        {
          left: col('session_lp'),
          operator: BOOLEAN_OPS.BTW,
          right: [
            val(2),
            val(15)
          ]
        },
        {
          left: col('ah_chg_p'),
          operator: BOOLEAN_OPS.GE,
          right: [val(10)]
        },
        {
          left: col('ah_v'),
          operator: BOOLEAN_OPS.GE,
          right: [val(50_000)],
        },
        {
          allowNull: true,
          left: col('share_group'),
          operator: ARRAY_OPS.IN,
          right: [val([shareGroupOptions[0].name])]
        }
      ]
    }
  }
});





//************************ KEYSTATS ************************//

export const KEYSTATS_EXTRA_CHECKBOXES = [
  { name: 'show_stock_price', label: 'Stock Price' },
];

const checkboxDefaultState = KEYSTATS_EXTRA_CHECKBOXES.reduce((acc, cb) => {
  acc[cb.name] = true;
  return acc;
}, {});


export const predefinedLayoutKeyStatsProfiles = addPredefinedKey([
  {
    id: `${PREDEF_PREFIX}small-cap`,
    name: 'Small Cap',
    alarms: {
      'marketCap': { less: 150_000_000, greater: 500_000_000, reversed: true },
      'sharesoutstanding': { less: 3_000_000, greater: 10_000_000, reversed: false },
      'sharesfloat': { less: 3_000_000, greater: 10_000_000, reversed: false },
      'shortpercentfloat': { less: 10, greater: 30, reversed: true },
      'percentinstitutions': { less: 10, greater: 30, reversed: true },
      'percentinsiders': { less: 20, greater: 50, reversed: true },
      'floatRotation': { less: 3, greater: 10, reversed: true },
      ...initialFinancialsAlarms
    },
    extra: { ...checkboxDefaultState },
    sections: [
      {
        sectionName: 'General Info',
        tables: [
          ['marketCap', 'sharesoutstanding', 'sharesfloat', 'shortpercentfloat', 'percentinstitutions', 'percentinsiders', 'floatRotation'],
          ['SSR', 'rvolCalculated', 'day365_high_combined', 'day365_low_combined', 'changeYTDCombined', 'lastSplitCombined', 'next_earnings']
        ],
      },
      {
        sectionName: 'Financials',
        tables: [
          ['CashAndCashEquivalents', 'MonthsOfCash', 'WorkingCapital'],
          ['TotalRevenue', 'CurrentRatio', 'BurnMo']
        ],
      }
    ]
  },

  {
    id: `${PREDEF_PREFIX}large-cap`,
    name: 'Large Cap',
    alarms: {
      'marketCap': { less: 500_000_000, greater: 10_000_000_000, reversed: false },
      'sharesoutstanding': { less: 100_000_000, greater: 500_000_000, reversed: false },
      'sharesfloat': { less: 100_000_000, greater: 500_000_000, reversed: false },
      'shortpercentfloat': { less: 10, greater: 30, reversed: false },
      'percentinstitutions': { less: 10, greater: 30, reversed: false },
      'percentinsiders': { less: 20, greater: 50, reversed: true },
      ...initialFinancialsAlarms
    },
    extra: { ...checkboxDefaultState },
    sections: [
      {
        sectionName: 'General Info',
        tables: [
          ['marketCap', 'sharesoutstanding', 'sharesfloat', 'shortpercentfloat', 'percentinstitutions', 'percentinsiders', 'industry'],
          ['SSR', 'rvolCalculated', 'day365_high_combined', 'day365_low_combined', 'changeYTDCombined', 'lastSplitCombined', 'next_earnings']
        ],
      },
      {
        sectionName: 'Financials',
        tables: [
          ['pe_ratio', 'DebtEquityRatio', 'CurrentRatio'],
          ['BasicEPS', 'ROA', 'ROE']
        ],
      }
    ]
  },
]);


//************************ NEWS/SEC ************************//



export const defaultLayoutNewsColumnProfiles = addPredefinedKey({
  [`${PREDEF_PREFIX}price`]: {
    id: `${PREDEF_PREFIX}price`,
    name: 'Price',
    columns: [
      col('ticker'),
      col('combinedHeadline'),
      col('session_lp'),
      col('session_v'),
      col('session_chg_p'),
    ]
  },
  [`${PREDEF_PREFIX}basic`]: {
    id: `${PREDEF_PREFIX}basic`,
    name: 'Basic',
    columns: [
      col('ticker'),
      col('combinedHeadline'),
    ]
  },
  [`${PREDEF_PREFIX}volume`]: {
    id: `${PREDEF_PREFIX}volume`,
    name: 'Volume',
    columns: [
      col('ticker'),
      col('combinedHeadline'),
      col('session_lp'),
      col('session_v'),
      col('rvol'),
    ]
  },
  [`${PREDEF_PREFIX}share`]: {
    id: `${PREDEF_PREFIX}share`,
    name: 'Share',
    columns: [
      col('ticker'),
      col('combinedHeadline'),
      col('session_lp'),
      col('session_chg_p'),
      col('sharesfloat'),
    ]
  }
});


//******************** HISTORICAL (GAP/HISTORY) ********************//


export const defaultLayoutHistoryColumnProfiles = addPredefinedKey({
  [`${PREDEF_PREFIX}default`]: {
    id: `${PREDEF_PREFIX}default`,
    name: 'Default',
    columns: [
      col('day0_date'),
      col('day0_open'),
      col('day0_vol'),
      col('day0_gap_pct'),
      col('day0_chg_pct'),
      col('day0_high_time'),
      col('day0_low_time'),
    ],
  },
  [`${PREDEF_PREFIX}gappers`]: {
    id: `${PREDEF_PREFIX}gappers`,
    name: 'Gappers',
    columns: [
      col('day0_date'),
      col('day0_open'),
      col('day0_vol'),
      col('day0_pm_high_pct'),
      col('day0_gap_pct'),
      col('day0_open_high_pct'),
      col('day0_high_time'),
      col('day0_low_time'),
    ],
  },
});


export const defaultLayoutHistoryFilterProfiles = addPredefinedKey({
  [`${PREDEF_PREFIX}small-cap`]: {
    id: `${PREDEF_PREFIX}small-cap`,
    name: 'Small Cap',
    filters: {
      AND: [
        {
          left: col('day0_date'),
          dateType: DATE_TYPES.ROLLING,
          operator: ROLLING_DATE_OPS.GE,
          right: [val('rolling_14_years')]
        },
        {
          left: { column: 'day0_gap_pct', type: 'column' },
          operator: BOOLEAN_OPS.GE,
          right: [val(10)]
        },
        {
          left: col('day0_vol'),
          operator: BOOLEAN_OPS.GE,
          right: [val(1_000_000)]
        }
      ]
    }
  },
  [`${PREDEF_PREFIX}large-cap`]: {
    id: `${PREDEF_PREFIX}large-cap`,
    name: 'Large Cap',
    filters: {
      AND: [
        {
          left: col('day0_date'),
          dateType: DATE_TYPES.ROLLING,
          operator: ROLLING_DATE_OPS.GE,
          right: [val('rolling_14_years')]
        },
        {
          left: col('day0_gap_pct'),
          operator: BOOLEAN_OPS.GE,
          right: [val(5)]
        },
        {
          left: col('day0_vol'),
          operator: BOOLEAN_OPS.GE,
          right: [val(1_000_000)]
        }
      ]
    },
  },

});



export const predefinedLayoutHistoryAggregateProfiles = addPredefinedKey({
  [`${PREDEF_PREFIX}default`]: {
    id: `${PREDEF_PREFIX}default`,
    name: 'Default',
    aggregates: [
      {
        label: 'Day 1',
        [STRUCTURAL_TYPES.VISUAL_GROUP]: makeAggNames(
          {
            conditional_agg: AGGREGATES.CNT,
            target: col('day0_close'),
            label: 'Count',
            conditions: [],
          },
          {
            conditional_agg: AGGREGATES.AVG,
            target: col('day0_gap'),
            label: 'Gap',
            conditions: [],
          },
          {
            conditional_agg: AGGREGATES.AVG,
            target: col('day0_gap_pct'),
            label: 'Gap %',
            conditions: [],
          },
          {
            conditional_agg: AGGREGATES.CNT,
            target: col('day0_close'),
            label: 'Close > Open',
            conditions: [{
              left: col('day0_ret_open'),
              operator: BOOLEAN_OPS.GE,
              right: [val(0)]
            }],
          },
          {
            conditional_agg: AGGREGATES.CNT,
            target: col('day0_close'),
            label: 'Close < Open',
            conditions: [{
              left: col('day0_ret_open'),
              operator: BOOLEAN_OPS.LT,
              right: [val(0)]
            }],
          },
          {
            conditional_agg: AGGREGATES.AVG,
            target: col('day0_high_time'),
            label: 'HOD',
            conditions: [],
          },
          {
            conditional_agg: AGGREGATES.AVG,
            target: col('day0_low_time'),
            label: 'LOD',
            conditions: [],
          },
          {
            conditional_agg: AGGREGATES.AVG,
            target: col('day0_open_high_pct'),
            label: 'High Spike %',
            conditions: [],
          },
          {
            conditional_agg: AGGREGATES.AVG,
            target: col('day0_open_low_pct'),
            label: 'Low Spike %',
            conditions: [],
          },
          {
            conditional_agg: AGGREGATES.AVG,
            target: col('day0_range'),
            label: 'Range',
            conditions: [],
          },
          {
            conditional_agg: AGGREGATES.AVG,
            target: col('day0_vol'),
            label: 'Volume',
            conditions: [],
          },
        )
      },
      {
        label: 'Day 2',
        [STRUCTURAL_TYPES.VISUAL_GROUP]: makeAggNames(
          {
            conditional_agg: AGGREGATES.CNT,
            target: col('day1_close'),
            label: 'Gap Up 3%',
            conditions: [
              {
                left: col('day1_gap_pct'),
                operator: BOOLEAN_OPS.GE,
                right: [val(3)]
              }
            ],
          },
          {
            conditional_agg: AGGREGATES.CNT,
            target: col('day1_close'),
            label: 'Gap Down 3%',
            conditions: [
              {
                left: col('day1_gap_pct'),
                operator: BOOLEAN_OPS.GE,
                right: [val(3)]
              }
            ],
          },
          {
            conditional_agg: AGGREGATES.CNT,
            target: col('day1_close'),
            label: 'Close > Open',
            conditions: [{
              left: col('day1_ret_open'),
              operator: BOOLEAN_OPS.GT,
              right: [val(0)]
            }],
          },
          {
            conditional_agg: AGGREGATES.CNT,
            target: col('day1_close'),
            label: 'Close < Open',
            conditions: [{
              left: col('day1_ret_open'),
              operator: BOOLEAN_OPS.LT,
              right: [val(0)]
            }],
          },
          {
            conditional_agg: AGGREGATES.CNT,
            target: col('day1_close'),
            label: 'Close > Prev. Close',
            conditions: [{
              left: col('day1_chg'),
              operator: BOOLEAN_OPS.GE,
              right: [val(0)]
            }],
          },
          {
            conditional_agg: AGGREGATES.CNT,
            target: col('day1_close'),
            label: 'Close < Prev. Close',
            conditions: [{
              left: col('day1_chg'),
              operator: BOOLEAN_OPS.LT,
              right: [val(0)]
            }],
          },
          {
            conditional_agg: AGGREGATES.AVG,
            target: col('day1_open_high_pct'),
            label: 'High Spike %',
            conditions: [],
          },
          {
            conditional_agg: AGGREGATES.AVG,
            target: col('day1_open_low_pct'),
            label: 'Low Spike %',
            conditions: [],
          },
          {
            conditional_agg: AGGREGATES.AVG,
            target: col('day1_range'),
            label: 'Range',
            conditions: [],
          },
          {
            conditional_agg: AGGREGATES.AVG,
            target: col('day1_vol'),
            label: 'Volume',
            conditions: [],
          },
        )
      }
    ]
  },
});





