import { layoutMapReducer } from '../base/layoutItemReducers/layoutMapReducer';
import { layoutTreeReducer } from '../base/layoutItemReducers/layoutTreeReducer';
import createLayoutReducer from '../base/layoutReducer';
import makeInitialState from './initialState';
import { HistorySchemaApi, HISTORY_NAMESPACE } from './schema';


/**
 * Create the layout reducer for history.
 * Handles initialState, Schema, and reducer instantiation on its own.
 * Appends Watchlist actions to the reducer.
 *
 * Extension of layoutv2/base
 * @param {string} namespace
 * @returns {{string: function}} [namespace]: reducer()
 */
export default function makeReducer(namespace = HISTORY_NAMESPACE) {
  return {
    [namespace]: createLayoutReducer(
      namespace,
      makeInitialState(),
      HistorySchemaApi,
      [layoutMapReducer, layoutTreeReducer]
    )
  }
}
