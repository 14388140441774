import { produce, current, isDraft } from 'immer';
import reduceReducers from 'reduce-reducers';
import _isObject from 'lodash/isObject';
import { getLeaves } from 'react-mosaic-component';
import { LINK_COLORS, PROFILE_TYPES } from './layoutSchema';
import { INIT_SOURCE } from 'src/redux/middleware/layoutSyncMiddleware/constants';
import {
  clearFalsey,
  deleteExpressionsFromColumns,
  deleteExpressionsFromFilters,
  getRemovedObjetKeys,
  arrayToMapAndOrder,
} from './reducerUtils';
import {
  CREATE_COMPONENT,
  CREATE_ZERO_STATE_COMPONENT,
  UPDATE_PROFILE,
  UPDATE_COMPONENT_PROFILE,
  UPDATE_LINKED_DATA,
  UPDATE_LINKED_DATA_BY_LAYOUT,
  UPDATE_COMPONENT,
  SET_ADD_COMPONENT_OVERLAY,

  MDW_INIT_START,
  MDW_INIT_SUCCESS,
  MDW_INIT_FAILURE,
  MDW_INIT_TEARDOWN,
  UPDATE_COMPONENT_LINK_COLOR,
  UPDATE_TICKER_SEARCH_LINK_COLOR,
  UPDATE_GLOBAL_SETTINGS
} from './layoutActions';


/** @typedef {{string: ReducerHandler}} LayoutHandlers */
/** @typedef {import('./layoutActions.js').LayoutAction} LayoutAction */
/** @typedef {import('./layoutSchema.js').SchemaApi} SchemaApi */


/** 
 * @callback ReducerHandler
 * @param {object} draft
 * @param {LayoutAction} action
 * @param {SchemaApi} schemaApi
 * @returns {void|object}
 */


/**
 * Just a catch-all locaiton for simple, persisted properties. Like Layout sidebar open.
 * You may not need this.
 */
function updateGlobalSettings(draft, action, schemaApi) {
  draft.globalSettings = {
    ...draft.globalSettings,
    ...action.payload
  }
}


/** @type {ReducerHandler} */
function setAddComponentOverlay(draft, action, schemaApi) {
  const { open } = action.payload;
  draft.ui.showComponentSelectOverlay = open;
}


/**
 * Copy a component (and necissary supporting data) to a new location.
 * Can be copied to any layout.
 */
// function copyComponent(draft, action, schemaApi) {
//   const {
//     sourceLayoutId,
//     sourceComponentId,
//     targetLayoutId,
//   }
// }


/**
 * Create a brand new empty component based on COMPONENT_TYPE.
 * Note, the "ComponentSelectPanel" is a component itself, and also will be created here.
 * NOTE: We don't need to save to DB here, since UPDATE_CURRENT_NODE will fire 
 * immediatly after. How do we stop this save?
 *  We probably need to change the mosaic logic, and update the node ourselves?
 * 
 * @type {ReducerHandler}
 */
function createComponent(draft, action, schemaApi) {
  const {
    layoutId,
    type,
    newComponentId,
    overrides = {}
  } = action.payload;

  if (!draft?.layouts?.[layoutId]) return;

  const { component } = schemaApi.createComponent(type, overrides);

  draft.components[newComponentId] = component;

  draft.ui.showComponentSelectOverlay = false;
}

/**
 * If the user deletes all their components, we need a special type of component (with no ID)
 * to show to the user.
 * @type {ReducerHandler}
 */
function createZeroStateComponent(draft, action, schemaApi) {
  const { layoutId, type, newComponentId, overrides = {} } = action.payload;

  if (!draft?.layouts?.[layoutId]) return;

  const { component } = schemaApi.createComponent(type, overrides);

  draft.components[newComponentId] = component;
  draft.ui.showComponentSelectOverlay = false;

  draft.layouts[layoutId].currentNode = newComponentId; // Force single component
}



/** @type {ReducerHandler} */
function updateLinkedData(draft, action, schemaApi) {
  const { color, data } = action.payload;

  // Set the color data if needed. Clear the metadata.
  if (color !== LINK_COLORS.white.name) {
    draft.links[color] = {
      ...draft.links[color],
      ...data
    }
    clearFalsey(draft.links[color], ['dispatchRule', 'sourceComponentId']);
  }

  // Always set white, its "on-change"
  draft.links.white = {
    ...draft.links.white,
    ...data,
  }
  clearFalsey(draft.links.white, ['dispatchRule', 'sourceComponentId']);
}


/** @type {ReducerHandler} */
function updateLinkedDataByLayout(draft, action, schemaApi) {
  const { color, data } = action.payload;

  draft.linksByLayout[draft.activeLayout][color] = {
    ...draft.linksByLayout[draft.activeLayout][color],
    ...data
  }
  clearFalsey(draft.linksByLayout[draft.activeLayout][color], ['dispatchRule', 'sourceComponentId']);

  draft.linksByLayout[draft.activeLayout][LINK_COLORS.white.name] = {
    ...draft.linksByLayout[draft.activeLayout][LINK_COLORS.white.name],
    ...data
  }
  clearFalsey(draft.linksByLayout[draft.activeLayout][LINK_COLORS.white.name], ['dispatchRule', 'sourceComponentId']);
}


/** @type {ReducerHandler} */
function updateComponentLinkColor(draft, action, schemaApi) {
  const { componentId, color } = action.payload;

  if (!(componentId in draft.components)) {
    return;
  }

  draft.components[componentId].link = color;
}


/** @type {ReducerHandler} */
function updateTickerSearchLinkColor(draft, action, schemaApi) {
  const { color } = action.payload;
  draft.tickerSearchLinkColor = color;
}


/** @type {ReducerHandler} */
function updateComponent(draft, action, schemaApi) {
  const { componentId, data } = action.payload;
  const { link, ...rest } = data; // Backwords compat, prolly not needed

  if (!(componentId in draft.components)) {
    return;
  }

  if (!_isObject(data)) {
    return;
  }

  draft.components[componentId] = {
    ...draft.components[componentId],
    ...rest
  };
}



function _deleteDereferencedExpressions(
  draft,
  schemaApi,
  removedExpressionIds,
) {
  if (!removedExpressionIds.length) {
    return
  }

  const removeExpressions = (profile, cfg) => {
    let hasChanged = false
    if (cfg.type === PROFILE_TYPES.COLUMN) {
      hasChanged = deleteExpressionsFromColumns(profile, removedExpressionIds, cfg);
    } else if (cfg.type in [PROFILE_TYPES.FILTER, PROFILE_TYPES.AGGREGATE]) {
      hasChanged = deleteExpressionsFromFilters(profile, removedExpressionIds, cfg);
    }
    if (hasChanged) {
      console.debug(`Removed expressions from ${cfg.type} profile:${profile.id}`);
    }
  }

  schemaApi.forEachProfile(draft, ({ profile, cfg }) => {
    removeExpressions(profile, cfg);
  });
}




/**
 * General case, where the payload contains all profiles of a given listKey.
 * The user may have added, deleted, or modified multiple profiles.
 *
 * We must check all other profiles for deleted expressions, and all other
 * components for deleted profile references.
 *
 * @type {ReducerHandler}
 */
function updateProfile(draft, action, schemaApi) {
  const { componentId, profileListKey, profile, expressionPayload } = action.payload;
  const profileConfigItem = schemaApi.getProfileConfigItem(profileListKey);
  if (!profileConfigItem) {
    console.warn(`Profile type not found: ${profile.name}`, action, schemaApi);
  }
  const { idKey, listKey, defaultProfileId } = profileConfigItem;
  const {
    activeProfile: activeProfileId,
    profileMap,
    profileTree
  } = profile;
  const {
    namespace: expressionNamespace = '',
    expressions: expressionList = []
  } = expressionPayload;

  if (!listKey || !idKey) {
    console.warn('Profile type not found', action.type, action.name);
    return;
  }

  // PROFILES
  const removedProfileIds = getRemovedObjetKeys(profileMap, draft.profileMap[listKey]);

  // EXPRESSIONS
  const [expressions, expressionOrder] = arrayToMapAndOrder(
    expressionList,
    'name'
  );

  const removedExpressionIds = getRemovedObjetKeys(
    expressions,
    draft.expressions?.[expressionNamespace] || {}
  );

  schemaApi.forEachComponent(draft, ({ component }) => {
    if (idKey in component && removedProfileIds.includes(component[idKey])) {
      component[idKey] = defaultProfileId;
    }
    if ('orderby' in component && removedExpressionIds.includes(component.orderby)) {
      component.orderby = null;
      console.debug(`Removed expression orderby from ${component.id}`);
    }
  })

  // Do main update
  draft.profileMap[listKey] = profileMap;
  draft.profileTree[listKey] = profileTree;
  draft.expressions[expressionNamespace] = expressions;
  draft.orderings.expressions[expressionNamespace] = expressionOrder;
  if (componentId in draft.components) {
    draft.components[componentId][idKey] = activeProfileId;
  }

  _deleteDereferencedExpressions(draft, schemaApi, removedExpressionIds);
}



/** 
 * @type {ReducerHandler} 
 * The Middleware calls this after a successful load from server, localstorage, or initialization from empty.
 */
function initSuccess(draft, action, schemaApi) {
  const { source, state: incomingSate, revision } = action.payload;

  if (source === INIT_SOURCE.initialState) {
    draft.isFetching.initial = false;
    return;
  }

  // Collections may not exist in the DB. We still need them to exist here, for predefinedes.
  // TODO: This merging is hard to maintain. Is there a better way?
  // TODO This is probably specific to each page, not global
  const nextState = {
    ...draft,
    ...incomingSate,
    isFetching: {
      ...draft.isFetching,
      initial: false
    }
  }

  if (draft.profileMap || incomingSate?.profileMap) {
    nextState.profileMap = {
      ...draft.profileMap,
      ...incomingSate?.profileMap || {}
    }
  }
  if (draft.profileTree || incomingSate?.profileTree) {
    nextState.profileTree = {
      ...draft.profileTree,
      ...incomingSate?.profileTree || {}
    }
  }

  return nextState;
}


/** @typedef {import('./layoutSchema').LayoutReducer} LayoutReducer */


/**
 * This reducer is meant to be reusable accross slices.
 * It fully relies on the layoutSyncMiddleware from syncronizing with server.
 * You must also instantiate the middleware on your store.
 *
 * All slices respond to the same action names, defined with a prefix from the middleware.
 * To differentiate between slices, a namespace must be attached to all actions.
 *
 * See layoutSyncMiddleware for more information.
 *
 * @param {string} namespace - name of reducer slice
 * @param {object} initialState
 * @param {SchemaApi} schemaApi - API to define new components and layouts, and track differences between slices.
 * @param {LayoutReducer[]} reduceWithReducers - Combine the default reducer with additional reducers in series
 * @returns {function} - reducer
 */
export default function createLayoutReducer(
  namespace,
  initialState,
  schemaApi,
  reduceWithReducers,
) {
  if (!initialState || initialState?.isFetching?.initial === undefined) {
    throw new Error('initialState must be defined');
  }

  const handlers = {
    [UPDATE_GLOBAL_SETTINGS]: updateGlobalSettings,
    [SET_ADD_COMPONENT_OVERLAY]: setAddComponentOverlay,
    [CREATE_COMPONENT]: createComponent,
    [CREATE_ZERO_STATE_COMPONENT]: createZeroStateComponent,
    [UPDATE_COMPONENT_LINK_COLOR]: updateComponentLinkColor,
    [UPDATE_TICKER_SEARCH_LINK_COLOR]: updateTickerSearchLinkColor,
    [UPDATE_LINKED_DATA]: updateLinkedData,
    [UPDATE_LINKED_DATA_BY_LAYOUT]: updateLinkedDataByLayout,

    [UPDATE_COMPONENT]: updateComponent,
    [UPDATE_PROFILE]: updateProfile,

    [MDW_INIT_START]: (draft) => {
      draft.isFetching.initial = true
    },
    [MDW_INIT_SUCCESS]: initSuccess,
    [MDW_INIT_TEARDOWN]: () => ({ ...initialState }),
  }


  function baseReducer(state, action, schemaApi) {
    const handler = handlers[action.type];
    if (!handler) return state;

    return produce(state, draft => handler(draft, action, schemaApi));
  }

  // first arg is initialState. We handle externally.
  const mergedReducer = reduceReducers({}, baseReducer, ...reduceWithReducers);

  return function layoutReducer(state = initialState, action) {
    if (action.namespace !== namespace) return state;

    const bb = performance.now();
    const ss = produce(state, draft => {
      draft.fakeKey = 'fake';
    });
    console.log('JUST ONE DRAFT TIME', performance.now() - bb, 'ms');

    const t = performance.now();
    const nextState = mergedReducer(state, action, schemaApi);
    console.log('Total immer time', performance.now() - t, 'ms');

    return nextState;
  }
}
