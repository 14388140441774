import edgeUsersApi from 'src/apis/edgeUsersApi';
import _isEqual from 'lodash/isEqual';
import { getLeaves } from 'react-mosaic-component';
import LayoutSyncMiddleware from '../../middleware/layoutSyncMiddleware/layoutSyncMiddleware';
import { isPredefined } from '../base/reducerUtils';
import {
  checkScannerTickerExpirationList,
} from './initializationFunctions.js'
import {
  fixProfilePermissionsOnDowngrade,
  makeLabelMap
} from '../base/layoutInitializationFunctions';
import { TOPLIST_NAMESPACE, ToplistSchemaApi } from './schema';
import { decidePlanLevel, profilePermissions } from 'src/hooks/useUserPlanPermissions';

/** @typedef {import('src/app/slicedForm/mapping/mappingDirections').ProfileStruct} ProfileStruct */
/** @typedef {import('../base/layoutSchema').Layout} Layout */
/** @typedef {import('src/app/slicedForm/shared/reducers/profileTreeReducer').TreeItem} TreeItem */
/** @typedef {import('../../middleware/layoutSyncMiddleware/constants.js').ChangesetResponse} ChangesetResponse */
/** @typedef {import('../../middleware/layoutSyncMiddleware/constants.js').LayoutResponse} LayoutResponse */
/** @typedef {import('../../middleware/layoutSyncMiddleware/constants.js').ChangeItem} ChangeItem */
/** @typedef {import('../../middleware/layoutSyncMiddleware/constants.js').RevisionRule} RevisionRule */
/** @typedef {import('../../middleware/layoutSyncMiddleware/constants.js').ReadRule} ReadRule */




/**
 * Defines how we modify records when reading from the database.
 * Note: You probably want to call these on the Parent of the target record, so we can modify the entire collection via filter/map.
 *
 * @type {Object.<string, ReadRule>} 
 */
const READ_RULES = {
  'components': {
    // Called on parent so we don't have to rebuild decidePlanLevel() every iteration
    mapRecord: ({ record, user }) => {
      const planPermissions = profilePermissions[TOPLIST_NAMESPACE];
      const planLevel = decidePlanLevel(user);

      return Object.entries(record).reduce((acc, [id, component]) => {
        let comp = checkScannerTickerExpirationList(id, component);
        comp = fixProfilePermissionsOnDowngrade(id, comp, planPermissions, planLevel, user, ToplistSchemaApi);

        acc[id] = comp;
        return acc;
      }, {});
    }
  },
  'profileMap.+': {
    // Called on parent of profile record, so we can add records
    mapRecord: ({ pathParts, record }) => {
      const profileListKey = pathParts[1];
      const cfg = ToplistSchemaApi.getProfileConfigItem(profileListKey);
      const predefined = cfg.predefinedProfiles || {};

      return {
        ...predefined,
        ...record
      }
    }
  },
  'profileTree.*.items': {
    mapRecord: ({ pathParts, record, state }) => {
      const profileListKey = pathParts[1];
      const cfg = ToplistSchemaApi.getProfileConfigItem(profileListKey);
      const predefinedMap = cfg.predefinedProfiles || {};
      const predefinedItems = ToplistSchemaApi.generateDefaultPredefinedFolder(predefinedMap);

      const merged = ToplistSchemaApi.mergePredefinedTreeItemsIntoRoot(record, predefinedItems)
      return ToplistSchemaApi.addIdAndLabelToTreeItems(
        merged,
        makeLabelMap(state.profileMap?.[profileListKey])
      );
    }
  },
}


const areSetsEqual = (a, b) => a.size === b.size && [...a].every(value => b.has(value));


/** 
 * Defines how we:
 *  1) Modify records before saving (localstorage or db)
 *  2) Build a revision changeset when sending to db
 *  3) What changes get a revision bump / sent to BroadcastChannel
 * 
 * Each specified record matching the path (wildcards allowed) will be
 * one record in dynamodb. A path of 'profileTree.+.items' will map to
 * the record 'profileTree#id1#items', for example.
 *
 * This also defines what get saved to localstorage. Any path not matching
 * will not be saved there.
 *
 * @type {Object.<string, RevisionRule>}
 */
const REVISION_RULES = {
  'layouts.+': {
    add: true,
    delete: true,
    put: (prev, next) => {
      const { currentNode: prevCurrentNode, ...prevRest } = prev;
      const { currentNode: nextCurrentNode, ...nextRest } = next;

      const setPrev = new Set(getLeaves(prevCurrentNode));
      const setNext = new Set(getLeaves(nextCurrentNode));

      return !_isEqual(prevRest, nextRest) || !areSetsEqual(setPrev, setNext);
    }
  },
  'layoutTabs': {
    put: false,
  },
  'activeLayout': {
    put: false,
  },
  'links': {
    put: false,
  },
  'tickerSearchLinkColor': {
    put: true,
  },
  'components.+': {
    add: true,
    delete: true,
    put: false
  },
  'profileMap.+.+': {
    filterRecord: ({ record }) => !isPredefined(record),
    add: true,
    delete: true,
    put: true // Maybe we don't want to communicate profile changes?
  },
  'profileTree.+.items': {
    mapRecord: ({ record, state }) => {
      const cleaned = ToplistSchemaApi.removePredefinedTreeItemsFromRoot(record);
      const removed = ToplistSchemaApi.removeIdAndLabelFromTreeItems(cleaned);
      return removed;
    },
    put: true,
    add: true,
  },
  'profileTree.+.openState': {
    put: false, // No need to communicate this
    add: true,
  },
  'expressions.+.+': {
    add: true,
    delete: true,
    put: true,
  },
  'orderings.expressions.+': {
    put: true,
    add: true,
  },
  'watchlistRevision': {
    // Watchlists themselves can't be saved/managed by the middleware. They exist as CRUD in postgres/python.
    // However, whenever we make a watchlist change, we can bump this to signify a change.
    // Now we can sort-of rely on other tabs revision numbers matching for WL changes.
    put: true
  }
}


/**
 * Send a changeset to the database
 * @param {string} namespace
 * @param {string} schemaVersion
 * @param {{revision: number, changeset: ChangeItem[] }} changesetReq
 * @returns {Promise<ChangesetResponse>}
 **/
async function persistChangeset(namespace, schemaVersion, changesetReq) {
  console.log('[MDW_TL] PERSIST:', changesetReq);
  const { data } = await edgeUsersApi.post(`/user/revisionedlayouts/${schemaVersion}/${namespace}`, changesetReq);
  return data;
}


/**
 * Initialize our layout from the database
 * @param {string} namespace
 * @param {string} schemaVersion
 * @returns {Promise<LayoutResponse>}
 **/
async function fetchLayout(namespace, schemaVersion) {
  const { data } = await edgeUsersApi.get(`/user/revisionedlayouts/${schemaVersion}/${namespace}`);
  console.log('[MDW_TL] FETCH END:', data);
  return data;
}



let middlewareApi = null;

/**
 * Singleton factory
 * @returns {LayoutSyncMiddleware}
 */
const getLayoutToplistMiddleware = () => {
  if (!middlewareApi) {
    middlewareApi = new LayoutSyncMiddleware({
      schemaVersion: 'v0',
      namespace: TOPLIST_NAMESPACE,
      revisionConfig: REVISION_RULES,
      readConfig: READ_RULES,
      persistChangeset,
      fetchLayout,
    });
  }

  return middlewareApi
}

export default getLayoutToplistMiddleware;
