import { COMPONENT_TYPES } from "../base/layoutSchema";
import produce from 'immer';
import {
  ADD_WATCHLIST_TICKER,
  CREATE_WATCHLIST,
  DELETE_WATCHLIST,
  FETCH_LIST_WATCHLIST_PROFILES,
  FETCH_WATCHLISTS,
  REMOVE_WATCHLIST_TICKER,
  RENAME_WATCHLIST,
  REORDER_WATCHLIST_TICKERS,
  REQUEST__ADD_WATCHLIST_TICKER,
  REQUEST__FETCH_LIST_WATCHLIST_PROFILES,
  REQUEST__FETCH_WATCHLISTS,
  REQUEST__REMOVE_WATCHLIST_TICKER,
} from "./watchlistActions"


/** @typedef {import("../base/layoutReducer").ReducerHandler} ReducerHandler */


/** @type {ReducerHandler} */
function request__addWatchlistTicker(draft, action, schemaApi) {
  const { watchlistProfileId, ticker, loadingItemId } = action.payload;

  draft.isFetching.watchlistData = true;
  draft.watchlists[schemaApi.profileConfig.WATCHLIST_ROWS.dataKey].push({
    watchlist_id: watchlistProfileId,
    item_id: loadingItemId,
    loadingRow: true,
    ticker: ticker,
    row_order: 999,
  })
}

/** @type {ReducerHandler} */
function request__removeWatchlistTicker(draft, action, schemaApi) {
  const { watchlistProfileId, watchlistItemId } = action.payload;

  const dataKey = schemaApi.profileConfig.WATCHLIST_ROWS.dataKey;

  draft.isFetching.watchlistData = true;

  const idx = draft.watchlists[dataKey].findIndex(row =>
    row.watchlist_id === watchlistProfileId && row.item_id === watchlistItemId
  );

  if (idx !== -1) {
    draft.watchlists[dataKey].splice(idx, 1);
  }
}


/** @type {ReducerHandler} */
function fetchListWatchlistProfiles(draft, action, schemaApi) {
  const profiles = action.payload;

  draft.isFetching.watchlists = false;
  draft.watchlists[schemaApi.profileConfig.WATCHLIST_ROWS.listKey] = action.payload;

  const profileConfigItem = schemaApi.profileConfig.WATCHLIST_ROWS;
  const listKey = profileConfigItem.listKey;
  const idKey = profileConfigItem.idKey;

  const allProfileIds = profiles.map(profile => profile.id);

  // Remove any references to profiles that no longer exist
  schemaApi.forEachComponent(draft, ({ componentId, component }) => {
    if (!(idKey in component)) return;
    if (allProfileIds.includes(component[idKey])) return;

    component[idKey] = profileConfigItem.defaultProfileId;
    console.debug(`[watchlistReducer] Deleting missing profileId ${component[idKey]} from component ${componentId}`);
  })
}


/** @type {ReducerHandler} */
function addWatchlistTicker(draft, action, schemaApi) {
  const { tickerData, loadingItemId } = action.payload;

  const dataKey = schemaApi.profileConfig.WATCHLIST_ROWS.dataKey;
  const listKey = schemaApi.profileConfig.WATCHLIST_ROWS.listKey;

  draft.isFetching.watchlistData = false;
  // remove loading row
  const idx = draft.watchlists[dataKey].findIndex(row => row.item_id === loadingItemId);
  if (idx !== -1) {
    draft.watchlists[dataKey].splice(idx, 1);
  }

  // add new data (if exists)
  tickerData.forEach(t => {
    let dataIdx = draft.watchlists[dataKey].findIndex(row => row.item_id === t.item_id);
    if (dataIdx === -1) {
      draft.watchlists[dataKey].push(t);
    } else {
      draft.watchlists[dataKey][dataIdx] = {
        ...draft.watchlists[dataKey][dataIdx],
        ...t
      };
    }
  });
}

/** @type {ReducerHandler} */
function reorderWatchlistTickers(draft, action, schemaApi) {
  const { watchlistProfileId, order } = action.payload;

  const dataKey = schemaApi.profileConfig.WATCHLIST_ROWS.dataKey;

  draft.watchlists[dataKey].forEach((row, idx) => {
    if (row.watchlist_id === watchlistProfileId) {
      const newOrderIdx = order.indexOf(row.item_id);
      if (newOrderIdx !== -1) {
        draft.watchlists[dataKey][idx].row_order = newOrderIdx;
      }
    }
  });
}


/** @type {ReducerHandler} */
function renameWatchlist(draft, action, schemaApi) {
  const { watchlistProfileId, newName } = action.payload;
  const listKey = schemaApi.profileConfig.WATCHLIST_ROWS.listKey;

  draft.watchlistRevision += 1;

  const profileIdx = draft.watchlists[listKey].findIndex(p => p.id === watchlistProfileId);
  if (profileIdx !== -1) {
    draft.watchlists[listKey][profileIdx].profileName = newName;
  }
}


/** @type {ReducerHandler} */
function deleteWatchlist(draft, action, schemaApi) {
  const { watchlistProfileId } = action.payload;

  draft.watchlistRevision += 1;

  const listKey = schemaApi.profileConfig.WATCHLIST_ROWS.listKey;
  const idKey = schemaApi.profileConfig.WATCHLIST_ROWS.idKey;

  const idx = draft.watchlists[listKey].findIndex(p => p.id === watchlistProfileId);
  if (idx !== -1) {
    draft.watchlists[listKey].splice(idx, 1);
  }

  // Update any references to deleted profile
  const removedProfileIds = [watchlistProfileId];

  schemaApi.forEachComponent(draft, ({ componentId, component }) => {
    if (idKey in component) {
      if (removedProfileIds.includes(component[idKey])) {
        component[idKey] = schemaApi.profileConfig.WATCHLIST_ROWS.defaultProfileId;
      }
    }
  });
}


/** @type {ReducerHandler} */
function createWatchlist(draft, action, schemaApi) {
  const { componentId, profile, data = [] } = action.payload;

  const idKey = schemaApi.profileConfig.WATCHLIST_ROWS.idKey;
  const listKey = schemaApi.profileConfig.WATCHLIST_ROWS.listKey;
  const dataKey = schemaApi.profileConfig.WATCHLIST_ROWS.dataKey;

  draft.watchlistRevision += 1;

  if (componentId in draft.components) {
    draft.components[componentId][idKey] = profile.id;
  }

  const idx = draft.watchlists[listKey].findIndex(p => p.id === profile.id);
  if (idx === -1) {
    draft.watchlists[listKey].push(profile);
  } else {
    draft.watchlists[listKey][idx] = {
      ...draft.watchlists[listKey][idx],
      ...profile
    };
  }

  // Insert/merge new rows into data
  if (data.length) {
    data.forEach(row => {
      const idx = draft.watchlists[dataKey].findIndex(p => p.item_id === row.item_id);
      if (idx !== -1) {
        draft.watchlists[dataKey][idx] = {
          ...draft.watchlists[dataKey][idx],
          ...row
        };
      } else {
        draft.watchlists[dataKey].push(row);
      }
    });
  }
}


const handlers = {
  [REQUEST__FETCH_LIST_WATCHLIST_PROFILES]: (draft, action, schemaApi) => {
    draft.isFetching.watchlist = true;
  },
  [REQUEST__FETCH_WATCHLISTS]: (draft, action, schemaApi) => {
    draft.isFetching.watchlistData = true;
  },
  [REQUEST__ADD_WATCHLIST_TICKER]: request__addWatchlistTicker,
  [REQUEST__REMOVE_WATCHLIST_TICKER]: request__removeWatchlistTicker,

  [FETCH_LIST_WATCHLIST_PROFILES]: fetchListWatchlistProfiles,
  [FETCH_WATCHLISTS]: (draft, action, schemaApi) => {
    draft.isFetching.watchlistData = false;
    draft.watchlists[schemaApi.profileConfig.WATCHLIST_ROWS.dataKey] = action.payload;
  },
  [ADD_WATCHLIST_TICKER]: addWatchlistTicker,
  [REMOVE_WATCHLIST_TICKER]: (draft, action, schemaApi) => {
    // Server deletes the data for us
    draft.isFetching.watchlistData = false;
  },
  [REORDER_WATCHLIST_TICKERS]: reorderWatchlistTickers,
  [RENAME_WATCHLIST]: renameWatchlist,
  [DELETE_WATCHLIST]: deleteWatchlist,
  [CREATE_WATCHLIST]: createWatchlist,
}


/** @type {import("../base/layoutSchema").LayoutReducer} */
export default function watchlistReducer(state, action, schemaApi) {
  const handler = handlers[action.type];

  if (!handler) return state;

  return produce(state, draft => handler(draft, action, schemaApi));
}
