import React, {
  useEffect,
  useState
} from 'react';
import { useIntercom } from 'react-use-intercom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import SplashScreen from 'src/components/SplashScreen';
import { fetchUser } from 'src/redux/account/accountActions';
import authService from 'src/services/authService';
import axiosAuthService from 'src/services/AxiosAuthService';
import { TagManagerService } from 'src/services/tagManagerService';


/*
 This component wraps the app at a high level and auto-logs the user in. If the user isn't already logged in via cookies, then another AuthGuard component will handle that case.
*/

function Auth({ children }) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.account.user);
  const [isLoading, setLoading] = useState(true);
  const { update: updateIntercom, boot: bootIntercom } = useIntercom();


  useEffect(() => {
    (async () => {
      // add guard to all requests requiring validation. After this, any token refresh logic will be handled by the service itself
      axiosAuthService.setInterceptors();

      // login from session and verify user exists
      const { user: authUser, session } = await authService.loginFromSession();

      if (authUser) {
        const authUserEmail = authUser.username;

        try {
          const id = session.getAccessToken()?.payload?.sub
          Sentry.setUser({ id });
          window.ettUser = { sub: id };
          TagManagerService.push({ userSub: id });
        } catch (err) {
          console.log(err);
        }

        //  fetch user if necessary. Also fetch new user if account has been switched.
        if (!user || !user.email || user.email !== authUserEmail) {
          dispatch(fetchUser());
        }
      } else {
        // They should be redirected to login anyways, no need for action here.
        // AuthGuard and BillingGuard should be doing this job.
      }
      setLoading(false);
    })();
  }, [dispatch]);


  useEffect(() => {
    if (user?.email) {
      const intercomProps = {
        email: user.email,
        userId: user.userSub,
        name: user.givenName,
        userHash: user.ihmac,
        hideDefaultLauncher: true,
        customAttributes: {
          stripeUserId: user?.stripeUserId,
          stage: process.env.REACT_APP_USERS_LAMBDA_STAGE
        }
      }

      // Boot can be called multiple times. Fixes logout->login issue
      bootIntercom(intercomProps)

      Sentry.setUser({ id: user?.userSub, email: user.email });
      TagManagerService.push({ userSub: user?.userSub });
      window.ettUser = { sub: user?.userSub, email: user.email };
    }
  }, [user?.email])


  if (isLoading && !user.subscriptionId) {
    return <SplashScreen />;
  }


  return children;
}

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;
