import React, { useMemo, useContext, createContext } from 'react';
import { createContext as createSelectableContext, useContextSelector } from 'use-context-selector';
import { useSelector } from 'react-redux';
import { useFormSelector } from './FormProvider';
import { selectActiveProfileId } from '../reducers/profileMapReducer';
import { FILTER_FORM_TYPES, STRUCTURAL_TYPES } from '../../mapping/mappingDirections';
import { getAllDescendants, getEntityVal } from '../../mapping/formTreeManipulation';
import { selectFormType } from '../../FilterForm/reducers/filterReducer';
import {
  ENTITY_PATHS_CAN_CONTAIN_EXPRESSIONS,
  STRUCTURAL_TYPES_CAN_CONTAIN_EXPRESSIONS,
} from '../../FilterForm/reducers/filterReducerHelpers';
import { EXPR_PREFIX } from 'src/redux/layoutV2/base/layoutExpressionConfig';


/** @typedef {import('src/redux/layoutV2/base/layoutSchema').LayoutEntityReference} LayoutEntityReference */

const initialState = {
  globalProfileReferencesSelector: () => ({}),
  globalExpressionReferenceSelector: () => ({}),
  componentId: null,
};


const emptyArray = [];
const emptyObj = {};


const FormReferencesArgumentContext = createContext(initialState);
const DerivedFormReferencesContext = createSelectableContext({
  expressions: {},
  profiles: {},
});





/**
 * In order to identify Profile and Expression references, (this profile is used in 3 other components), 
 * we must select state from Redux, and then combine it with state from FormProvider. 
 *
 * This context allows us to pass arguments and selectors down to the form, as natively the form does 
 * not understand redux/layouts/components, and I'm really sick of adding more props to every form.
 *
 * Wrap this around your <SlicedForm /> instances.
 *
 * @param {object} props
 * @param {function} props.globalProfileReferencesSelector - Passed to bare useSelector! Must be global! state[namespace].profiles
 * @param {function} props.globalExpressionReferenceSelector - Same as above
 * @param {string} props.componentId - Needed to detect which profileReference is being edited currently
 * @param {string} props.profileListKey - Needed to build local expressionReferences
 * @param {string} props.profileGroupLabel - Needed to build local expressionReferences
 * @param {*} props.children
 */
export function FormReferencesArgumentProvider({
  globalProfileReferencesSelector,
  globalExpressionReferenceSelector,
  componentId,
  profileListKey,
  profileGroupLabel,
  children,
}) {
  const value = useMemo(
    () => ({
      globalProfileReferencesSelector,
      globalExpressionReferenceSelector,
      componentId,
      profileListKey,
      profileGroupLabel,
    }),
    [globalProfileReferencesSelector, globalExpressionReferenceSelector, componentId, profileListKey, profileGroupLabel]
  );

  return (
    <FormReferencesArgumentContext.Provider value={value}>
      {children}
    </FormReferencesArgumentContext.Provider>
  );
}



/**
 * Calculates Expression and Profile references by combining FormReferencesArgumentContext
 * and local formState. Stores the result in context. Some hooks are provided to retrieve them.
 * 
 * -- 
 *
 * So it turns out that expressionReferences gets used once per form input.
 * once per input. Performance does not scale if you calculate at render-time. So we calculate
 * it here.
 *
 * NOTE: Don't use this in FormProvider. Instead, place it manually in each form.
 * This way we can choose to propagate it forward for ExpressionDetailsForm, and keep the 
 * correct parent values.
 */
export function DerivedFormReferencesProvider({ children }) {
  const argumentValues = useContext(FormReferencesArgumentContext);
  const profileReferences = useDeriveFormMergedProfileReferences(argumentValues);
  const expressionReferences = useDeriveFormMergedExpressionReferences(argumentValues);

  const value = useMemo(() => ({
    profiles: profileReferences,
    expressions: expressionReferences,
  }), [profileReferences, expressionReferences]);

  return (
    <DerivedFormReferencesContext.Provider value={value}>
      {children}
    </DerivedFormReferencesContext.Provider>
  )
}


/** 
 * @returns {LayoutEntityReference[]} 
 */
export function useFormMergedSingleProfileReference(profileId) {
  return useContextSelector(
    DerivedFormReferencesContext,
    state => state?.profiles?.[profileId] || emptyArray
  );
}


/**
 * @returns {LayoutEntityReference}
 */
export function useFormMergedProfileReferences() {
  return useContextSelector(
    DerivedFormReferencesContext,
    state => state?.profiles || emptyObj
  );
}


/** 
 * @returns {LayoutEntityReference[]} 
 */
export function useFormMergedSingleExpressionReference(expressionName) {
  return useContextSelector(
    DerivedFormReferencesContext,
    state => state?.expressions?.[expressionName] || emptyArray
  );
}


/**
 * @returns {LayoutEntityReference}
 */
export function useFormMergedExpressionReferences() {
  return useContextSelector(
    DerivedFormReferencesContext,
    state => state?.expressions || emptyObj
  );
}





/** 
 * @returns {{string: LayoutEntityReference[]}} profileId: references
 */
function useDeriveFormMergedProfileReferences({
  globalProfileReferencesSelector,
  componentId
}) {
  /** @type {{string: LayoutEntityReference[]}} profileId: references[] */
  const globalReferences = useSelector(globalProfileReferencesSelector);
  const localProfiles = useFormSelector(state => state.profileMap);
  const activeProfileId = useFormSelector(selectActiveProfileId);

  return useMemo(() => {
    if (!globalReferences) {
      return {};
    }

    let currentReference;

    const existingReferences = Object.entries(globalReferences).reduce((acc, [profileId, references]) => {
      // Remove profiles that the user has deleted in-session
      if (!(profileId in localProfiles)) {
        return acc;
      }

      // Pop the currentReference, since the user may have picked a different profile.
      acc[profileId] = references.filter(ref => {
        if (componentId && ref.itemId === componentId) {
          currentReference = ref;
          return false;
        }
        return true;
      }, {});

      return acc;
    }, {});

    // Re-insert the currentReference into the active profile's references
    if (activeProfileId in existingReferences) {
      existingReferences[activeProfileId].unshift({
        ...currentReference,
        isCurrent: true,
      });
    }

    return existingReferences;
  }, [
    globalReferences,
    componentId,
    activeProfileId,
    localProfiles
  ])

}



/**
 * Helper function to pull all expression ids for a given Sliced Form Filter profile
 * @param {object} profile
 * @param {object} entities
 * @returns {string[]} expressionIds
 */
const filter_findExpressionsIdsForSlicedFormProfile = (profile, entities = {}) => {
  const filterLeaves = getAllDescendants(profile?.root).filter(leaf => {
    return STRUCTURAL_TYPES_CAN_CONTAIN_EXPRESSIONS.includes(leaf.type);
  });

  const ids = filterLeaves.reduce((acc, leaf) => {
    const entity = entities[leaf?.id];
    const innerIds = ENTITY_PATHS_CAN_CONTAIN_EXPRESSIONS
      .map(path => getEntityVal(entity?.[path]))
      .filter(val => val && val.startsWith(EXPR_PREFIX));

    return [
      ...acc,
      ...innerIds
    ];
  }, []);

  return [...new Set(ids)];
}


/**
 * Helper function to pull all expression ids for a given Sliced Form Column profile
 * @param {object} profile
 * @param {object} entities
 * @returns {string[]} expressionIds
 */
const column_findExpressionsIdsForSlicedFormProfile = (profile) => {
  return [
    ...new Set(
      profile?.columns?.map(col => col?.column).filter(col => col && col.startsWith(EXPR_PREFIX))
    )
  ]
}

/*
 SlicedForm state will recieve all layout[namespace]expressions[exprNamespace] = [...] expressions.

 globalExpressionReferenceSelector will recieve ALL layout[namespace] expressions, due to the 
 selector not having exprNamespace available.

 When a user deletes an expression, it gets deleted from localState.expressions,
 then propagated to ColumnDefContext.columnDefs.

 When the form is finally saved, we then search through all layout[namespace] profiles
 and delete it from every other profile.

 But while the form is open, those to-be deleted references still exist. This means 
 references[deletedExpr.name] still exists.

 Now, because that expr was deleted in the form, there should be no situation where I ask for that
 data. How will we ask references for an ID that doesn't exist in-form? 

 However, I still feel its proper to delete them here. The situation may change.

 My plan for doing this:
  Check state.expressions
  Delete references[id] if (id not in state.expressions)

 This has a consequence of deleting non-namespace references. But thats good anyways.

*/

/**
 * @returns {{string: LayoutEntityReference[]}} expressionId: references[]
 */
function useDeriveFormMergedExpressionReferences({
  globalExpressionReferenceSelector,
  profileListKey,
  profileGroupLabel,
}) {
  /** @type {{string: LayoutEntityReference[]}} expressionId: references[] */
  const globalReferences = useSelector(globalExpressionReferenceSelector);
  const localProfiles = useFormSelector(state => state.profileMap);
  const localExpressions = useFormSelector(state => state.expressions || emptyArray);
  const entities = useFormSelector(state => state.entities);
  const formType = useFormSelector(selectFormType);

  return useMemo(() => {
    if (!globalReferences || !profileListKey || !profileGroupLabel) {
      return {};
    }

    const localReferences = Object.keys(localProfiles).reduce((acc, profileId) => {
      let expressionIds = [];
      if (formType === FILTER_FORM_TYPES.COLUMN) {
        expressionIds = column_findExpressionsIdsForSlicedFormProfile(localProfiles[profileId]);
      } else if ([FILTER_FORM_TYPES.FILTER, FILTER_FORM_TYPES.AGGREGATE].includes(formType)) {

        // if (profileId === "jV4HFmudMD2Y84bzoetU9j") {
        // debugger;
        // }
        expressionIds = filter_findExpressionsIdsForSlicedFormProfile(localProfiles[profileId], entities);
      }

      expressionIds.forEach(exprId => {
        acc[exprId] = acc[exprId] || [];
        acc[exprId].push({
          itemId: profileId,
          itemLabel: localProfiles[profileId].name,
          groupId: profileListKey,
          groupLabel: profileGroupLabel,
        });
      });

      return acc;
    }, {});

    const localProfileIds = Object.keys(localProfiles);
    const localExpressionIds = localExpressions.map(expr => expr.name);

    const finalReferences = Object.entries(globalReferences).reduce((acc, [expressionId, references]) => {
      // Cross-reference localState.expressions. If not exists, then the user has either deleted the expression
      // locally, or it was never part of the correct contextKey to begin with. Remove the entire expression.
      if (!localExpressionIds.includes(expressionId)) {
        return acc;
      }

      // Remove all reference items that relate to a profile within this form, as they may be stale.
      const cleanReferences = references.filter(ref => {
        return !localProfileIds.includes(ref.itemId);
      });

      // Insert all local references from this form, to reflect global + local state.
      const mergedReferences = [
        ...(localReferences[expressionId] || []),
        ...cleanReferences
      ];

      acc[expressionId] = mergedReferences;

      return acc;
    }, {});

    return finalReferences;
  }, [
    formType,
    globalReferences,
    localProfiles,
    localExpressions,
    entities,
    profileListKey,
    profileGroupLabel
  ]);
}
