
/** @typedef {import('src/app/slicedForm/shared/reducers/profileTreeReducer.js').TreeItem} TreeItem */


/** 
 * When a move conflict occurs, this will be sent to a callback for handling.
 * @typedef {object} NameConflict
 * @property {string} id - The ID of the conflicting item (new item or moved item)
 * @property {string} conflictingName - The name that is causing the conflict
 * @property {"item"|"node"} itemType - The type of the incoming item causing the conflict
 */

/**
 * After a conflict, the user will be presented with a list of conflicts.
 * You should return these objects.
 * @typedef {object} NameConflictResolution
 * @property {keyof BATCH_CONFLICT_ACTION} type - The type of resolution to apply
 */



export const BATCH_CONFLICT_ACTION = {
  KEEP: 'KEEP',
  OVERWRITE: 'OVERWRITE',
  CANCEL: 'CANCEL' // noop, probably not used
};


/**
 * Given a starting node, find the maximum depth of all children
 * item = 0
 * folder -> item = 1
 * folder = 1 (empty still counts towards depth)
 * folder -> folder -> item = 2
 * @param {{string: TreeItem}} items - Map of all nodes
 * @returns {number} 
 */
export function maxDepthOfChildren(items, startingId) {
  return (function recurse(nodeId, currentDepth = 0) {
    let node = items[nodeId];
    if (!node) return currentDepth;
    if (!node.isFolder) return currentDepth;

    let depth = currentDepth + 1;

    if (!node?.children?.length) return depth;

    let maxChildDepth = depth;
    for (let childId of node.children) {
      let childDepth = recurse(childId, depth);
      if (childDepth > maxChildDepth) {
        maxChildDepth = childDepth;
      }
    }
    return maxChildDepth;
  })(startingId);
}

/**
 * Given a target node, find the depth from the root.
 * @param {{string: TreeItem}} items - Map of all nodes
 * @param {string} targetId - The ID to find the depth of
 * @returns {number}
 */
export function nodeDepthFromRoot(items, targetId) {
  return (function recurse(nodeId, currentDepth = 0) {
    const node = items[nodeId];
    if (!node) return null;

    const depth = node.isFolder ? currentDepth + 1 : currentDepth;

    if (node.id === targetId) return depth;

    for (const childId of (node?.children || [])) {
      const result = recurse(childId, depth);
      if (result !== null) {
        return result;
      }
    }

    return null;
  })('root');
}


/**
 * Gets a list of an item's siblings, excluding the item itself
 * @param {{string: TreeItem}} items
 * @param {string} itemId
 * @param {string} [parentId] - If passed, simulate the siblings as if itemId was being moved to parentId
 */
export function getItemSiblings(items, itemId, parentId = null) {
  const parent = parentId
    ? items[parentId]
    : Object.values(items).find(item => item.children?.includes(itemId));
  return parent?.children?.filter(id => id !== itemId).map(id => items?.[id]).filter(Boolean) || [];
}


/**
 * Enforce a maximum depth on a move
 * @param {{string: TreeItem}} items - The items to check
 * @param {object} payload
 * @param {string[]} payload.dragIds - The IDs being dragged
 * @param {string} payload.parentId - The ID of the new parent
 * @param {number} [maxDepth=3] - The maximum depth allowed
 * @returns {boolean}
 */
export function isMoveDepthAllowed(items, { dragIds, parentId }, maxDepth = 3) {
  const nextParent = items[parentId] || items['root'];
  const parentDepth = nodeDepthFromRoot(items, nextParent.id);

  const childMaxDepths = dragIds.map(id => maxDepthOfChildren(items, id));
  return childMaxDepths.every(depth => depth + parentDepth <= maxDepth);
}


/**
 * Check if a move would cause a naming conflict
 * @param {{string: TreeItem}} items
 * @param {object} payload
 * @param {string[]} payload.dragIds - The IDs being dragged
 * @param {string} payload.parentId - The ID of the new parent
 * @returns {NameConflict[]}
 */
export function checkMoveNameConflicts(
  items,
  { dragIds, parentId },
) {
  const nextParent = items[parentId] || items['root'];

  return dragIds.reduce((acc, curr) => {
    const currName = items[curr]?.label;
    const siblings = getItemSiblings(items, curr, nextParent.id)
    const hasConflict = siblings.some(sibling => sibling.label === currName);

    if (hasConflict) {
      const itemType = items[curr].isFolder ? 'folder' : 'leaf';
      return [...acc, { id: curr, conflictingName: currName, itemType }]
    }
    return acc;
  }, []);
}



/**
 * Formats the tree as a nested object, for react-arborist to consume.
 * Also removes the root item, which is implied by the library.
 * Memoize this!
 * @param {{string: TreeItem[]}} items - The items to format
 * @returns {TreeItem[]} - Denormalized, ready for react-arborist
 *
 */
export const denormalizeProfileTree = (itemMap) => {
  const rootTree = (function recurse(id) {
    const item = itemMap[id];
    if (!item) return null;
    const children = item.children?.map(recurse)?.filter(Boolean);
    return children ? { ...item, children } : { ...item };
  })('root');
  return rootTree.children;
}


/**
 * Return all IDs nested under a node, to aid with mass deletion.
 * Excludes the starting node.
 * @param {{string: TreeItem}} items - The items to collect from
 * @param {string} id - The ID to start from
 * @returns {string[]} 
 */
export function collectChildIds(items, id) {
  const item = items[id];
  const children = item?.children || [];
  return children.reduce((acc, childId) => {
    return [...acc, ...collectChildIds(items, childId)];
  }, [...children]);
}


/**
 * @param {TreeItem} item
 * @returns {boolean} - True if the item is a profile, not a folder
 */
export function isItemProfile(item) {
  return item?.id && !item?.data?.isFolder && !item.id !== 'root';
}

/**
 * Mapping of childId->parentId, in order to more quickly traverse UP the tree.
 *
 * @param {{string: TreeItem}} items
 * @returns {string: string}
 */
export const buildParentMap = items => {
  return Object.values(items).reduce((acc, item) => {
    item.children?.forEach(childId => {
      acc[childId] = item.id;
    });
    return acc;
  }, {});
};


/**
 * Returns a map of ID -> index that represents the visual order of the entire tree,
 * as if it were a single vertical list of items. Root is excluded.
 *
 * @example
 * // myFolder
 * //   item1
 * // item2
 * // otherFolder
 * //   item3
 * 
 * result = {myFolder: 0, item1: 1, item2: 2, otherFolder: 3, item3: 4}
 *
 * @param {{string: TreeItem}} items
 * @returns {{string: number}}
 */
export const buildOrderMap = items => {
  if (!items || !items.root) return [];

  const queue = [items.root];
  const nodeOrder = {};
  let idx = 0;

  while (queue.length > 0) {
    const node = queue.shift();

    if (node.id !== 'root') {
      nodeOrder[node.id] = idx++;
    }

    if (node.children && node.children.length) {
      for (const childId of node.children) {
        const childNode = items[childId];
        if (childNode) {
          queue.push(childNode);
        }
      }
    }
  }

  return nodeOrder;
}


/**
 * Returns an array of labels representing the path to an item,
 * excluding the root item and the target item.
 *
 * We're using the item.label, not the layout[id].name. This 
 * should be fine, but its not the true source-of-truth.
 *
 * @param {string} id - ID to find path of
 * @param {{string: TreeItem}} items
 * @param {{string: string} | undefined} - Optionally pass a premade parentMap for performance. If not, one will be created.
 * @returns {string[]} Path of labels
 */
export const getReadablePathToItem = (id, items, parentMap = null) => {
  const _parentMap = parentMap || buildParentMap(items);

  const path = [];
  let parent = items[_parentMap[id]]

  while (parent && parent.id !== 'root') {
    path.push(items[parent.id].label);
    parent = items[_parentMap[parent.id]];
  }

  return path;
}
