import * as Sentry from '@sentry/react';
import _clamp from 'lodash/clamp';
import {
  format,
  differenceInDays,
  addDays,
  isWithinInterval,
} from 'date-fns';
import { parseUnixSeconds } from 'src/utils/datetime/date-fns.tz';

const humanReadableFormat = 'LLL d, yyyy'; // July 1, 2022

const tryFormat = (date, fmt) => {
  try {
    return format(date, fmt);
  } catch (err) {
    console.error(err);
    Sentry.captureException(err);
    return 'Unknown Date';
  }
};



export default class Subscription {

  constructor(user, id) {
    this.user = user;
    this.id = id;
    this.subscription = this.user.subscriptions.find(s => s.subscriptionId === this.id);
    this.status = this?.subscription?.subscriptionStatus;
    this.planDetails = this?.subscription?.planDetails;
    this.unpaidPaymentIntentId = this?.subscription?.unpaidPaymentIntentId;
    this.priceId = this?.subscription?.priceId;
    this.plan_level = this?.subscription?.planDetails?.plan_level;
    this.currentPeriodEnd = this?.subscription?.currentPeriodEnd;
    this.pendingUpdate = this?.subscription?.pendingUpdate;
    this.paymentMethod = this?.subscription?.paymentMethod;
    this.requires_plan_change = this.user?.requires_plan_change;
    this.schedule = this?.subscription?.schedule;
    this.GRACE_PERIOD_DAYS = 3;
    this.FREE_TRIAL_DAYS = 14;

    this.phase_idx = null;
    // Set the phase_idx, if applicable. This is just so useEffect() can update on phase change.
    void this.getScheduledPlanDetails();
  }

  getNow() {
    if (this.user?.testClockFrozenTime) {
      return parseUnixSeconds(this.user.testClockFrozenTime);
    } else {
      return new Date();
    }
  }

  newCustomer() {
    if (!this.user.subscriptionId) {
      return true;
    }
  }

  active() {
    // TODO: past_due and no payment intent. Means the second webhook hasn't fired yet. for now, just continue like the subscription is active.
    return !!((this.status === 'active' && !this.subscription.cancelAt) || (this.status === 'past_due' && !this.subscription.unpaidPaymentIntentId));
  }

  trialing() {
    return !!(this.status === 'trialing' && !this.subscription.cancelAt);
  }

  // We give some users free access via extended trials. Hide/show some UI for these users.
  trialingAndNotExtendedTrial() {
    return !!(this.status === 'trialing' && this.daysLeftInTrial() <= this.FREE_TRIAL_DAYS);
  }

  pastDue() {
    // TODO: what if 'active' and currentPeriodEnd?
    return !!(this.status === 'past_due' && this.subscription.unpaidPaymentIntentId);
  }

  canceled() {
    return !!((this.status === 'active' || this.status === 'trialing') && this.subscription?.cancelAt);
  }

  hasAccess() {
    return !!(this.status === 'active' || this.status === 'trialing');
  }

  hasTrialed() {
    return this.user.hasTrialed || false;
  }

  // TODO: unpaidPaymentIntentId is a bad way to store this, since a normal subscription cycle could clear this.
  pendingSubscriptionChange() {
    return !!(this.pendingUpdate && this.unpaidPaymentIntentId);
  }

  pendingSubscriptionPriceId() {
    let priceId = null;
    if (this?.pendingUpdate?.subscription_items && this.pendingUpdate.subscription_items.length && this.pendingUpdate.subscription_items[0].price?.id) {
      priceId = this.pendingUpdate.subscription_items[0].price.id;
    }
    return priceId;
  }

  periodEndFormatted() {
    if (this.status && this.status === 'trialing' && this.subscription?.trialEnd) {
      return tryFormat(parseUnixSeconds(this.subscription.trialEnd), humanReadableFormat);
    } else if (this.subscription?.currentPeriodEnd) {
      return tryFormat(parseUnixSeconds(this.subscription.currentPeriodEnd), humanReadableFormat);
    }
  }

  daysLeftInTrial() {
    if (this.subscription?.trialEnd) {
      const end = parseUnixSeconds(this.subscription.trialEnd);
      const now = this.getNow();
      // Moment worked differently. Just clamp it to 1 day, or MAX days.
      const d = _clamp(differenceInDays(end, now), 1, this.FREE_TRIAL_DAYS);
      // Horrible hack to make the signup form less confusing. Technically its 13.99 days, rounds down.
      if (d === this.FREE_TRIAL_DAYS - 1) {
        return this.FREE_TRIAL_DAYS;
      } else {
        return d;
      }
    }
  }

  daysLeftBeforeCancelation() {
    if (this.subscription?.currentPeriodEnd) {
      const currentPeriodEnd = parseUnixSeconds(this.subscription.currentPeriodEnd);
      const cancelationDate = addDays(currentPeriodEnd, this.GRACE_PERIOD_DAYS);
      const now = this.getNow();
      return Math.max(differenceInDays(cancelationDate, now), 1);
    }
  }

  trialEndFormatted() {
    if (this.trialing()) {
      return tryFormat(parseUnixSeconds(this.subscription?.trialEnd), humanReadableFormat);
    }
  }

  cancelAtFormatted() {
    if (this.canceled()) {
      return tryFormat(parseUnixSeconds(this.subscription?.cancelAt), humanReadableFormat);
    }
  }

  hasSchedule() {
    return (this.subscription && this.schedule && this.schedule?.phases);
  }


  getScheduledPlanDetails() {
    try {
      if (!this.hasSchedule()) {
        return false;
      }

      const now = this.getNow();

      // this.schedule.current_phase is not in-sync with stripe. Stripe doesn't send webhook events for it. Use timestamp instead.
      const phaseIdx = this.schedule.phases.findIndex(phase => {
        const interval = {
          start: parseUnixSeconds(phase.start_date),
          end: parseUnixSeconds(phase.end_date)
        };
        return isWithinInterval(now, interval);
      });

      if (phaseIdx === -1) {
        return false;
      }

      this.phase_idx = phaseIdx;

      if (phaseIdx === this.schedule.phases.length) {
        // Last phase. Currently, this means the phase is matching the user's current subscription.
        // Don't show anything special in that case. Later, this may change.
        return false;
      }

      const upcomingPriceIds = this.schedule.phases.slice(phaseIdx + 1, this.schedule.phases.length).map(({ price }) => price);

      if (upcomingPriceIds.some(p => p !== this.priceId)) {
        const nextPhase = this.schedule.phases[phaseIdx + 1];
        const priceId = nextPhase.price;
        const details = this?.schedule?.planDetails[priceId] || false;
        if (details) {
          return {
            priceId,
            ...details
          };
        }
      }

    } catch (err) {
      Sentry.captureException(err);
    }

    return false;
  }
}
