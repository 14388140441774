import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isInteger from 'lodash/isInteger';
import _isObject from 'lodash/isObject';
import SyncMiddlewareAsyncQueue from './SyncMiddlewareAsyncQueue';
import requestIdleCallback from 'ric-shim';
import { Store } from 'redux';
import { isShallowSerializable, retryAsync } from './helpers';
import {
  INIT_SOURCE,
  INIT_ACTIONS,
  MIDDLEWARE_PREFIX,
  middlewareActionType,
} from './constants';
import {
  buildChangeset,
  buildInitializationChangeset,
  applyLocalstorageWriteModifications,
  applyInitializationReadModifications
} from './buildChangeset';
import { createErrorNotification } from 'src/redux/notifications/notificationActions';
import shortUuid from 'short-uuid';


/** @typedef {import('./layoutSyncMiddleware/constants.js').ChangesetResponse} ChangesetResponse */
/** @typedef {import('./layoutSyncMiddleware/constants.js').LayoutResponse} LayoutResponse */
/** @typedef {import('./layoutSyncMiddleware/constants.js').RevisionRule} RevisionRule */
/** @typedef {import('./layoutSyncMiddleware/constants.js').StoredValue} StoredValue */
/** @typedef {import('./layoutSyncMiddleware/constants.js').MessageEvent} MessageEvent */



/**
EXPRESSIONS

Why does it work currently?
- Because 
  a) Expressions are saved at the same moment as profiles
  b) Expressions only involve a single reducer
  c) They are all batched together

Could it work with the middleware?
- One action dispatches to many reducers
- Each reducer runs and persists. Versions get bumped
- The expressions run and persist and bump. 
    We now have two changes to communicate, and they must happen in order, and neither one can fail
    We now have multiple out-of-sync revisions. Each change potentially causes 3+ persists. This don't work.

No it can't. I think the solution is that 

ALL layouts which must share expressions, must also share a reducer.
All layouts now exist together, denormalized. 

This sucks. I'm also worried about performance. The Diffs are going to be huge.

SCHEMA_TYPES: { historyPageCol: { profileType: FILTER, version: v2 } }

NOTE: THIS IS HOW IT HAST TO WORK. Plan for migration:
0) Deal with layout namespaces now, even though they will be unused. Helps migration later.
1) Make a brand new reducer and apply this middleware
2) Figure out:
  - denormalization structure
  - action creators and selectors (specific to slice)
  - DB data migration to new format
  - Performance. Reduce the amount of state for object-scan.
    - I guess each ACTION can define a list of paths to scan, if we are struggling
  - Collisions? Maybe check IDs against redux for every CREATE action. Sucks tho.
  - Merged reducers? It would be good to have profileReducer, expressionReducer, etc.
  - Similarly, mapping definitions for denormalized stuff (delete profile, delete expression, etc)

namespaces: {
  'historyPageIntermediate': {
    layouts: [a, b, c],
    meta: {
      layoutTabs: [],
      links: {},
      tickerSearchLinkColor: 'red',
    }
  }
},
layouts: {
  a: { currentNode: ... }
},
expressions: {
  namespace: {
    id1: contextKey
  },
components: {},
profiles: {
  historyPageColumnProfiles: {},
  scannerHistoricalColumnProiles: {},
},
orderings: {
  profiles: { ... },
  expressions: { ... }
},

 
**/



/**
 * Reusable middleware to manage Layouts. It:
 *  - Initializes state from either localstorage or database when prompted
 *    via INIT action. 
 *    - Applies mappings based on RevisionRule[].readModifications before loading
 *
 *  - Listens for actions that match a provided actionMatcher, and on match:
 *    - Uses prevState and nextState to build a diff to send to SERVER
 *
 *  - On successful save:
 *    - communicates the original action to other tabs via BROADCAST
 *    - Persists the entire redux state to LOCALSTORAGE
 *      - local state will be be mapped by RevisionRule[].writeModifications
 *
 *  REVISION RESOLUTION:
 *  In order to prevent invalid states from being reached, all updates must
 *  first validate their current revision against the incomming.
 *
 *  As specified in RevisionRules, some changes will BUMP a revision, some will not.
 *
 *  REVISIONED CHANGES: means its a breaking change. It must be communcated to other tabs for the 
 *  website to work correctly. If any change in a changeset requires a revision, then they all do. 
 *  There's only 1 revsision accross all records.
 *
 *  Rules for bumping:
 *    - If we're sending a bump to server, our current revision MUST match the server's revision.
 *      - On success, we return the new bumped revision, and set our browser's revision to match
 *      - On fail, our frontend is now considered out-of-sync. It can no longer persist changes, 
 *        and must be reloaded. On reloaded, we will fetch the freshest server revision instead.
 *    - If a server bump was successful, we must also BROADCAST to other tabs:
 *      - The other tab MUST be 1 revision behind the incomming revision. IF not, the other tab is now out-of-sync.
 *        Same rules apply. It will no longer be able to save.
 *    - We also now write the new state to localstorage, from the original tab.
 *
 *  Through this scheme, one tab will always win out, there will be no race conditions. Theoretically they will 
 *  stay perfectly in-sync through BROADCAST, and both can freely make changes. However, getting out-of-sync is fine,
 *  it will not break anything. Just prevent saves until reload.
 *
 *  NON-REVISIONED CHANGES:
 *  These changes can diverge between two tabs. Like changing sort on a grid, or width of components. 
 *
 *  They will still be sent to DB, and the DB will still validate the current revision matches the DB revision. 
 *  This is necessary so that we ensure all non-revision operations on revisioned resources are valid. E.G:
 *    - ADD component '123' (revision)
 *    - UPDATE component '123' (non-revision) - but still requries previos revision to exist.
 *
 *  These changes MUST NOT be broadcast. Otherwise a user cannot make divergant changes.
 *
 *  These changes WILL be saved to localstarge. last-write-wins between tabs. Totally fine.
 *
 **/
export default class LayoutSyncMiddleware {
  /**
   * @param {object} options
   * @param {string} options.namespace
   * @param {string} options.schemaVersion
   * @param {{string: RevisionRule}} options.revisionConfig
   * @param {{string: ReadRule}} options.readConfig
   * @param {function} options.persistChangeset
   * @param {function} options.fetchLayout
   * @param {function} [options.getUser]
   * @param {function} [options.getUserSub]
   */
  constructor({
    namespace,
    schemaVersion,
    revisionConfig,
    readConfig,
    persistChangeset,
    fetchLayout,
    getUser = state => state.account.user,
    getUserSub = state => state.account.user.userSub,
  }) {
    if (!namespace || !schemaVersion || !revisionConfig || !readConfig || !persistChangeset || !fetchLayout) {
      throw new Error('LayoutSyncMiddleware requires namespace, schemaVersion, revisionConfig, persistChangeset, and fetchLayout');
    }

    /** @type {string} */
    this.namespace = namespace;
    /** @type {string} */
    this.schemaVersion = schemaVersion;
    /** @type {{string: RevisionRule}} */
    this.revisionConfig = revisionConfig;
    /** @type {{string: ReadRule}} */
    this.readConfig = readConfig;
    /** @type {function(string, string, object): Promise<ChangesetResponse>} */
    this.persistChangeset = persistChangeset;
    /** @type {function(string, string): Promise<LayoutResponse>} */
    this.fetchLayout = fetchLayout;

    /** @type {function(object): object} */
    this.getUser = getUser;
    /** @type {function(object): string} */
    this.getUserSub = getUserSub;

    /** 
     * @type {boolean}
     * If we have conflics, we will need to mark one Tab as stale and prevent further DB/localstorage changes *
     * This is not ideal, but all other solutions require locks, commits/rollbacks, or conflict resolution.
     */
    this.isStale = false;

    this.initialized = false;
    this.bc = null;
    this.currentRevision = -1; // has to be tracked manually, cannot exist inside redux
    this.asyncQueue = new SyncMiddlewareAsyncQueue();

    // Will be set during initialization. Must exist before we do anything.
    this.userSub = null;
  }

  _teardown = () => {
    this.initialized = false;
    this.isStale = false;
    this.currentRevision = -1;
    this.asyncQueue = new SyncMiddlewareAsyncQueue();
    this.userSub = null;
    if (this.bc) {
      this.bc.close();
      this.bc = null;
    }
  }

  actionMatcher = action => {
    return action.type.startsWith(`${MIDDLEWARE_PREFIX}/`)
      && action.namespace === this.namespace && this.namespace
      && action.SKIP_LAYOUT_SYNC !== true;
  }

  actionCreator = action => ({
    ...action,
    type: middlewareActionType(action.type),
    namespace: this.namespace
  });

  _validateRules = () => {
    // TODO:
    // 1. Target records MUST be contained within a dict. No parnt arrays allowed.
    // 2. two separate REVISION/READ rules cannot match the same item
  }

  _getInitActionType(action) {
    // Remove the actionPrefix from the action.type, if it exists
    if (action.type.includes('~INIT~/')) {
      return action.type.split('/').slice(-2).join('/')
    }
  }

  // Check if the action is an initialization action
  _isInitAction(action) {
    const type = this._getInitActionType(action);
    return Boolean(type);
  }

  _getStorageKey = () => {
    if (!this.userSub) {
      throw new Error('LayoutSyncMiddleware not initialized');
    }
    return `layoutmdw#${this.userSub}#${this.schemaVersion}#${this.namespace}`;
  }

  _getDatabaseRevisionKey = () => {
    if (!this.userSub) {
      throw new Error('LayoutSyncMiddleware not initialized');
    }
    return `revision#${this.schemaVersion}#${this.namespace}`;
  }

  _getBroadcastChannelName = () => {
    if (!this.userSub) {
      throw new Error('LayoutSyncMiddleware not initialized');
    }
    return `layoutmdw#${this.userSub}#${this.schemaVersion}#${this.namespace}`;
  }

  /**
   * If storage === db, use storage
   * if storage > db use DB for safety (FUTURE: Just rewrite DB here)
   * if storeage < db, use DB
   * If storage and not DB, use initialState for safety (FUTURE: Just rewrite to DB here)
   * If no storage or DB, use initialState
   * @param {object} state - redux state
   * @returns {{soure: string, state: object, revision: number}}
  **/
  _getInitialStatePayload = async state => {
    console.log(`[LAYOUTMDW ${this.namespace} INIT] initializing state`);

    const user = this.getUser(state);
    const storageKey = this._getStorageKey();

    const dbRevision = user?.[this._getDatabaseRevisionKey()] || -1;
    let storageRevision = -1;

    const namespaceState = state[this.namespace];

    const storedValue = readLocalStorage(this._getStorageKey());
    if (storedValue) {
      const { revision, data } = storedValue;
      storageRevision = revision;
      console.log(`[LAYOUTMDW ${this.namespace} INIT] storage:${storageRevision}, db:${dbRevision}`);

      /*
       Because users can navigate away from the page, when they come back we don't refresh user.storageVersion.
       That would require delaying the pageload until we get the user object.

       This means its very easy for storageVersion > dbVersion. In which case we aren't sure about the state of the DB.
       We can either:
       - Use local. Assume its probably up to date, which it will be unless the user is using multiple browsers
         - Worst-case-scenario: We're OOD, and submitting an update will make us stale. Eh, not so bad.
       - Use DB to be safe. 
         - Worst-case-scenario: Slower initial load.

       I'm not sure the right choice. Trying local for now.

       TBH this should only hurt users who are sharing accounts, which is a no-no anyways.

       Potential solution:
       - Connect to a websocket on load that streams db revision bumps to client
      */

      if (storageRevision >= dbRevision) {
        // We will assume local is up to date. True for initial load, maybe not true for subsequent loads.
        // If not true (due to user using multiple browsers), tab will go stale.
        console.log(`[LAYOUTMDW ${this.namespace} INIT] storageRevision >= dbRevision, load_from_storage`, data);
        return {
          source: INIT_SOURCE.storage,
          state: data,
          revision: storageRevision
        };
      }
    } else {
      console.log(`[LAYOUTMDW ${this.namespace} INIT] storage:${storageRevision}, db:${dbRevision}`);
    }

    if (storageRevision > 0 && dbRevision <= 0) {
      // If storage exists but database revision is missing, we have an issue, reset to initial state 
      // TODO: persist local
      console.warn(`[LAYOUTMDW ${this.namespace} INIT] storage exists but dbRevision is missing, reset to initial state`);
      clearLocalStorage(storageKey);
      // TODO: We need to initialize DB too.
      return {
        source: INIT_SOURCE.initialState,
        state: namespaceState,
        revision: 0,
      }
    }

    if (dbRevision <= 0) {
      // We don't have storage and we don't have DB data. Initialize from redux.
      console.log(`[LAYOUTMDW ${this.namespace} INIT] no storage or db, load_from_initialState`);
      // TODO: We need to initialize DB too.
      return {
        source: INIT_SOURCE.initialState,
        state: namespaceState,
        revision: 0,
      }
    }

    // If we get here, we should have DB data and no/invalid storage data.
    try {
      const fetchFnc = async () => await this.fetchLayout(this.namespace, this.schemaVersion);
      const responseData = await retryAsync(fetchFnc, 2, 150);
      const { revision: dbRevision, data: dbData } = responseData;

      if (!dbData
        || !_isObject(dbData)
        || !Object.keys(dbData).length
        || !dbRevision
        || !_isInteger(dbRevision)
        || !dbRevision > 0
      ) {
        throw new Error(`Invalid or empty data recieved from fetchLayout: ${JSON.stringify(responseData)}`);
      }

      // We shouldn't need to apply writeModifications, DB data should already be corrected
      writeLocalStorage(
        storageKey,
        storageRevision,
        dbData,
      );

      console.log(`[LAYOUTMDW ${this.namespace} INIT] load_from_db`, dbData);

      return {
        source: INIT_SOURCE.database,
        state: dbData,
        revision: dbRevision,
      }
    } catch (err) {
      console.error(`[LAYOUTMDW ${this.namespace} INIT] Error fetching layout`, err);
    }

    console.log(`[LAYOUTMDW ${this.namespace} INIT] DB issue and no storage, load_from_initialState`);
    clearLocalStorage(storageKey);

    return {
      source: INIT_SOURCE.initialState,
      state: namespaceState,
      revision: 0,
    }
  }

  /**
   * Create the broadcast channel if it doesn't exist or is the wrong channel name
   * @param {string} channelName
   * @param {Store} store
   **/
  createBroadcastChannel = (channelName, store) => {
    if (!channelName) {
      throw new Error('no channel name provided');
    }
    if (this.bc && this.bc.name === channelName) {
      return this.bc;
    }
    if (this.broadcastchannel) {
      this.bc.close();
      this.bc = null;
    }
    const bc = new BroadcastChannel(channelName);
    bc.instanceId = shortUuid().generate();

    bc.onmessage = event => this._onRecieveBroadcastMessage(event, store);
    bc.onmessageerror = event => this._onRecieveBroadcastError(event, store);

    this.bc = bc;
  }

  sendBroadcast = (action, revision) => {
    if (!this.bc) {
      throw new Error('Broadcast channel not initialized');
    }
    this.bc.postMessage({
      ...action,
      source: 'broadcast',
      senderInstanceId: this.bc.instanceId,
      revision
    });
  }

  /**
   * Called when a message is recieved
   * @param {MessageEvent} event
   * @param {Store} store
   * @returns {void}
   */
  _onRecieveBroadcastMessage = (event, store) => {
    if (event.data.senderInstanceId === this.bc.instanceId) {
      // ignore self
      return;
    }

    console.log(`[LAYOUTMDW ${this.namespace}] Recieved broadcast message`, event.data);

    const action = event.data;
    store.dispatch(action); // should already have namespace attached. Process next tick.
  }

  /**
   * Called when the recieved message cannot be deserialized
   * @param {MessageEvent} event
   * @param {Store} store
   */
  _onRecieveBroadcastError = (event, store) => {
    console.log(event)
    console.log(`[LAYOUTMDW ${this.namespace}] Broadcast error`, event?.data);
  }

  /**
   * ENTRY POINT. Apply this to Redux store.
   * @param {Store} store
   * @returns {function}
   */
  middleware = store => next => async action => {
    if (!this.actionMatcher(action)) {
      return next(action);
    }

    if (!isShallowSerializable(action.payload)) {
      console.error(
        'CRITICAL ERROR! layoutSyncMiddleware requires serializable actions!\n' +
        `BroadcastChannel cannot communicate changes otherwise. type: ${action.type}`
      )
    }

    if (this._isInitAction(action)) {
      const result = next(action);

      const initType = this._getInitActionType(action);

      if (initType === INIT_ACTIONS.START) {
        const state = store.getState();
        this.userSub = this.getUserSub(state);

        const initialPayload = await this._getInitialStatePayload(state);

        if (initialPayload.source === INIT_SOURCE.initialState) {
          // Make sure we don't mutate redux state
          initialPayload.state = _cloneDeep(initialPayload.state);

          // Send our initial state to server
          let t = performance.now();
          const initialChangeset = buildInitializationChangeset(initialPayload.state, this.revisionConfig);
          console.info(`[LAYOUT MDW ${this.namespace}] buildInitializationChangeset took`, performance.now() - t, 'ms');

          try {
            // NOTE: We don't save to LocalStorage here. Should we? I guess it doesn't make a difference.
            const func = async () => await this.persistChangeset(
              this.namespace,
              this.schemaVersion,
              {
                changeset: initialChangeset,
                revision: initialPayload.revision // Must be zero
              });
            const res = await retryAsync(func, 2, 150);
            if (!res.success || !res.revision) {
              throw new Error('Failed to persist initial state, response invalid', res);
            }
            initialPayload.revision = res.revision;
          } catch (err) {
            console.log('panic', err);
            // TODO: I have no clue what to do here. Prevent changes I guess.
            throw new Error('Failed to persist initial state');
          }
        } else {
          this.currentRevision = initialPayload.revision;
        }

        // If first init, then payload.state will just contain the reducer.
        // That should already have all the defaults. But to make life more consistent,
        // we're going to apply these transformations anyways.
        // TODO: I'm aware that this is confusing. Whats the point of the reducer initState then?
        // NOTE: We have to getUser here, because some read initializations require planLevel and such. Kind of hacky.

        const user = this.getUser(state);

        let t = performance.now();
        initialPayload.state = applyInitializationReadModifications(initialPayload.state, this.readConfig, user);
        console.info(`[LAYOUT MDW ${this.namespace}] applyInitializationReadModifications took`, performance.now() - t, 'ms');

        this.currentRevision = initialPayload.revision;

        store.dispatch(this.actionCreator({ type: INIT_ACTIONS.SUCCESS, payload: initialPayload }))

        this.createBroadcastChannel(this._getBroadcastChannelName(), store);
        this.initialized = true;
      }


      if (initType === INIT_ACTIONS.TEARDOWN) {
        // Reset middleware state, and prevent BC channel from processing further.
        // Reducer will respond by setting state = initialState.
        this._teardown();
        return next(action);
      }


      // all other init types should be ignored. The reducer will handle.
      return result;
    }

    if (!this.initialized) {
      // Not sure what to do here. We should never reach this.
      console.error('MAJOR ISSUE: LayoutSyncMiddleware not initialized, but an action was recieved!', action, this.namespace);
      return;
    }

    if (action.source === 'broadcast') {
      if (this.isStale) {
        console.error(`[LAYOUTMDW ${this.namespace}] Stale state. Ignoring action.`, action);
        return;
      }
      if (action.revision !== this.currentRevision + 1) {
        console.error(`[LAYOUTMDW ${this.namespace}] Received out of order revision. Ignoring.`, action.revision, this.currentRevision);
        return;
      } else {
        // Don't write localstorage. The sender already wrote valid data, and we don't want to defeat their write.
        const result = next(action);
        this.currentRevision = action.revision;
        return result;
      }
    }

    const prevState = store.getState()[this.namespace];
    const result = next(action);
    const nextState = store.getState()[this.namespace];

    if (this.isStale) {
      console.error(`[LAYOUTMDW ${this.namespace}] Stale state. Not persisting or broadcasting.`, action);
      const payload = createErrorNotification(
        'Your session is out of sync and changes will not be saved. Please refresh the page.',
      );
      store.dispatch({ type: 'GENERIC_ERROR_NO_TARGET', payload });
      return
    }

    let t = performance.now();
    const changeset = buildChangeset(prevState, nextState, this.revisionConfig);
    console.info(`[LAYOUT MDW ${this.namespace}] buildChangeset took`, performance.now() - t, 'ms');


    if (changeset.length === 0) {
      return result;
    }

    const needsRevision = changeset.some(it => it.revision);
    const currRevision = this.currentRevision;
    const nextRevision = this.currentRevision + (needsRevision ? 1 : 0);

    this.currentRevision = nextRevision;

    this.asyncQueue.push(
      async () => {
        const m = + new Date();
        const dbRequest = {
          revision: currRevision,
          changeset: _cloneDeep(changeset),
        };
        const bcAction = needsRevision ? _cloneDeep({ ...action }) : null;

        let t = performance.now();
        const storageState = applyLocalstorageWriteModifications(nextState, this.revisionConfig);
        console.info(`[LAYOUT MDW ${this.namespace}] applyLocalstorageWriteModifications took`, performance.now() - t, 'ms');

        const response = await this.persistChangeset(
          this.namespace,
          this.schemaVersion,
          dbRequest
        );
        return { responseRevision: response.revision, bcAction, needsRevision, storageState };
      },
      {
        onError: ({ error }) => {
          console.log(`[LAYOUTMDW ${this.namespace}] SyncMiddlewareAsyncQueue Error:`, error, error?.response?.data);

          const data = error?.response?.data || {};
          let payload = null;

          if (!data.dbErr) {
            // Generic error, not from our own stuff
            payload = createErrorNotification(
              'Failed to save changes. You may have lost internet connection. Please try refreshing the page.',
            )
          } else if (data.code === 'RevisionCheckFailed') {
            payload = createErrorNotification(data.message);
            this.isStale = true;
          } else {
            payload = createErrorNotification(data.message);
          }
          store.dispatch({ type: 'GENERIC_ERROR_NO_TARGET', payload });
        },
        onSuccess: ({ result }) => {
          const { responseRevision, bcAction, needsRevision, storageState } = result;
          if (needsRevision) {
            this.sendBroadcast(bcAction, responseRevision);
          }
          writeLocalStorage(this._getStorageKey(), responseRevision, storageState);
        }

      }
    )
  }
}


/**
 * @param {string} storageKey
 * @returns {StoredValue|undefined}
 **/
function readLocalStorage(storageKey) {
  try {
    const storedValue = localStorage.getItem(storageKey);
    if (!storedValue) {
      return undefined;
    }
    const parsedValue = JSON.parse(storedValue);

    if (!parsedValue.data || !parsedValue.revision) {
      return undefined;
    }
    return parsedValue;
  } catch (err) {
    console.err('[HIST_INTER] Error reading local storage', err);
  }
  return undefined;
}


/**
 * @param {string} storageKey
 * @param {number} revision
 * @param {object} data
 * @throws {Error} if data cannot be stringified, or storage fails
 **/
function writeLocalStorage(storageKey, revision, data) {
  requestIdleCallback(() => {
    const valueToStore = { data, revision, writtenAt: Date.now() };
    const stringified = JSON.stringify(valueToStore);
    localStorage.setItem(storageKey, stringified);
  }, { timeout: 1500 });
}


/**
 * @param {string} storageKey
 **/
function clearLocalStorage(storageKey) {
  try {
    localStorage.removeItem(storageKey);
  } catch (err) {
    console.err('[HIST_INTER] Error clearing local storage', err);
  }
}

