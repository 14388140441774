import reduceReducers from 'reduce-reducers';
import _cloneDeep from 'lodash/cloneDeep';
import { createSelector } from "reselect";
import shortUuid from 'short-uuid';
import { generateSimpleFilenameVerson } from 'src/utils/generateProfileFilenameVersion';
import { logSentryError } from './sentryReducerErrors';
import { TREE_DELETE_ITEMS, TREE_MOVE_ITEMS_WITH_BATCH_CONFLICTS, TREE_RENAME_ITEM } from './profileTreeReducer';
import { BATCH_CONFLICT_ACTION } from '../../utility/arborHelpers';

export const PROFILE_REFERENCE_SET_IGNORE_WARNING = '@form-profile-map/PROFILE_REFERENCE_IGNORE_WARNING';
export const SELECT_PROFILE = '@form-profile-map/SELECT_PROFILE';
export const ADD_PROFILE = '@form-profile-map/ADD_PROFILE';
export const COPY_PROFILE = '@form-profile-map/COPY_PROFILE';
export const RENAME_PROFILE = '@form-profile-map/RENAME_PROFILE';
export const DELETE_PROFILE = '@form-profile-map/DELETE_PROFILE';


export function getActiveProfile(draft) {
  const profileId = draft.activeProfile;
  return draft.profileMap[profileId];
}


/**
 * If we are using ProfileTree, dedup the name based on an item's siblings.
 * Otherwise, dedup based on all profiles.
 * @param {Object} draft
 * @param {string} name - The name to deduplicate
 * @param {Object} options
 * @param {string} [options.excludeId] - The ID to exclude from the deduplication, if needed
 * @param {string} [options.parentTreeItem] - The ID of the parent tree item, if needed
 */
export function deduplicateName(
  draft,
  name,
  { excludeId, parentTreeItem } = {}
) {
  let otherNames;
  if (parentTreeItem && draft?.profileTree?.items) {
    const siblingIds = draft.profileTree.items[parentTreeItem].children;
    const siblings = siblingIds.map(id => draft.profileTree.items[id]).filter(i => i.id !== excludeId);
    otherNames = siblings.map(s => s.label);
  } else {
    otherNames = Object.values(draft.profileMap).filter(p => p.id !== excludeId).map(p => p.name);
  }
  return generateSimpleFilenameVerson(name, otherNames);
}


/** 
 * on copy/new, make sure we don't include certain attributes
 */
function cleanNewProfileAttributes(profile) {
  const { predefined, ignoreReferencesWarning, ...cleanedProfile } = profile;
  return cleanedProfile;
}


/**
 * Handles the management of Profiles, like renaming, copying, deleting, etc.
 *
 * Note, this is seperate from the ProfileTree state, which is a nested list of normalized IDs.
 * This allows us to change UI strategies without re-coding the underlying profile logic.
 *
 * @param {Object} draft - The draft to modify
 * @param {import('reduce-reducers').Action} action - The action to apply
 */
function profileMapReducer(draft, action) {
  switch (action.type) {
    case SELECT_PROFILE: {
      const profileId = action.payload;
      draft.activeProfile = profileId;
      break;
    }
    /**
     * @param {Object} base - The unserialized (dynamo) profile to use as the base
     * @param {Object} id   - Alternatively, copy an ID from this reducer into the new profile
     * @param {string} newId - We need to know the ID ahead of time to orchestrate reducers.
     */
    case ADD_PROFILE: {
      const {
        id,
        newId,
        baseProfileName = 'New Profile'
      } = action.payload;

      if (!newId) {
        logSentryError(
          'profileReducer.ADD_PROFILE requires newId',
          new Error('profileReducer.ADD_PROFILE requires newId'),
          action,
        );
        break;
      }

      let newProfile = _cloneDeep(draft.profileMap[id]);

      if (!newProfile) {
        console.error('No profile provided to add profile action', action);
        return;
      }

      newProfile.id = newId;

      const otherNames = Object.values(draft.profileMap).filter(p => p.id !== newProfile.id).map(p => p.name);
      newProfile.name = generateSimpleFilenameVerson(baseProfileName, otherNames)

      draft.profileMap[newId] = cleanNewProfileAttributes(newProfile);
      draft.activeProfile = newProfile.id;
      break;
    }
    /**
    * @param {Object} id   - The ID to copy from this reducer
    * @param {string} name - New profile name (unversioned, we apply versioning here)
    * @param {string} newId - See ADD_PROFILE
    * @param {boolean} setActive - Should we set the active profile to this new one?
    * @param {string} parentTreeItem - The ID of the parent tree item, to dedup names
    */
    case COPY_PROFILE: {
      const { id, name, newId, setActive = false } = action.payload;

      if (!newId) {
        logSentryError(
          'COPY_PROFILE requires newId',
          new Error('COPY_PROFILE requires newId'),
          action,
          draft
        );
        return draft;
      }

      const oldProfile = draft.profileMap[id];
      if (!oldProfile) break;

      const newProfile = {
        ..._cloneDeep(oldProfile),
        id: newId,
        name,
      }

      draft.profileMap[newId] = cleanNewProfileAttributes(newProfile);

      if (setActive) {
        draft.activeProfile = newId;
      }

      break;
    }

    case DELETE_PROFILE: {
      if (!(action.payload in draft.profileMap)) break;

      if (Object.keys(draft.profileMap).length === 1) break; // can't delete last profile

      delete draft.profileMap[action.payload];

      if (draft.activeProfile === action.payload) {
        draft.activeProfile = Object.values(draft.profileMap)[0].id;
      }
      break;
    }


    case RENAME_PROFILE: {
      const { id, newName } = action.payload;

      if (!(id in draft.profileMap)) break;

      const otherNames = Object.keys(draft.profileMap).filter(p => p.id !== id).map(p => p.name);
      draft.profileMap[id].name = generateSimpleFilenameVerson(newName, otherNames);

      break;
    }


    case PROFILE_REFERENCE_SET_IGNORE_WARNING: {
      const { profileId, value } = action.payload;

      const profile = draft.profileMap[profileId];

      if (!profile) {
        return;
      }

      if (value) {
        profile.ignoreReferencesWarning = true;
      } else {
        delete profile.ignoreReferencesWarning;
      }

      break;
    }


    default:
      break;
  }
}


/**
 * Responds to some profileTree actions as needed. 
 * Both profileMap and profileTree can be called by the same action.
 * 
 * @param {Object} draft - The draft to modify
 * @param {import('reduce-reducers').Action} action - The action to apply
*/
function profileMapReducerTreeActions(draft, action) {
  switch (action.type) {

    case TREE_RENAME_ITEM: {
      // Alternative way of renaming, from profileTree
      const { id, name, itemType } = action.payload;
      if (itemType === 'folder' || !(id in draft.profileMap)) break;
      draft.profileMap[id].name = name;
      break;
    }

    case TREE_DELETE_ITEMS: {
      // Alternative way of deleting, from profileTree
      // All recursive children have already been collected.
      const { ids } = action.payload;
      if (ids.includes(draft.activeProfile)) {
        draft.activeProfile = Object.values(draft.profileMap).find(p => !ids.includes(p.id)).id;
      }
      ids.forEach(id => {
        if (id in draft.profileMap) {
          delete draft.profileMap[id];
        }
      });

      break;
    }

    case TREE_MOVE_ITEMS_WITH_BATCH_CONFLICTS: {
      // Only need to worry about renames and deletes here
      const { batchActions } = action.payload;

      batchActions.forEach(({ resolutionType, payload: batchPayload }) => {
        const { dragId, itemType, newName, itemsToOverwrite = [] } = batchPayload;

        switch (resolutionType) {
          case BATCH_CONFLICT_ACTION.OVERWRITE: {
            itemsToOverwrite.forEach(id => {
              if (id in draft.profileMap) {
                delete draft.profileMap[id];
                if (draft.activeProfile === id) {
                  draft.activeProfile = Object.values(draft.profileMap)[0].id;
                }
              }
            })
            break;
          }
          case BATCH_CONFLICT_ACTION.KEEP: {
            if (dragId in draft.profileMap && itemType === 'leaf') {
              draft.profileMap[dragId].name = newName;
            }
            break;
          }
          default: {
            console.warn(`TREE_MOVE_ITEMS_WITH_BATCH_CONFLICTS Unknown resolutionType ${resolutionType}`)
            break;
          }
        }
      })

      break;
    }
    default: {
      break;
    }
  }
}


export default function combined(draft, action) {
  profileMapReducer(draft, action);
  profileMapReducerTreeActions(draft, action);
}


export const generateId = () => shortUuid.generate();

export const selectActiveProfileId = state => state.activeProfile;

export const selectProfileMap = state => state.profileMap;


export const selectActiveProfile = createSelector(
  [selectActiveProfileId, selectProfileMap],
  (id, profiles) => {
    // TODO: EXPRESSIONS. FormActions calls this. We don't want that. Need to generalize FormActions I guess
    if (!profiles) return null;
    return profiles[id];
  }
)


export const selectActiveProfileName = createSelector(
  [selectActiveProfile],
  (profile) => profile?.name
)

