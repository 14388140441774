import TreeStateManager from 'src/app/slicedForm/utility/arborDraftTreeManipulation';
import produce from 'immer';
import {
  LAYOUT_TREE_TOGGLE_OPEN,
  LAYOUT_TREE_COPY_LAYOUT,
  LAYOUT_TREE_CREATE_ITEM,
  LAYOUT_TREE_MOVE_ITEMS,
  LAYOUT_TREE_RENAME_ITEM,
  LAYOUT_TREE_DELETE_ITEMS,
  LAYOUT_TREE_MOVE_ITEMS_WITH_BATCH_CONFLICTS,
  LAYOUT_TREE_CLEAR_NEW_ITEM_INDICATOR,
  LAYOUT_TREE_SET_SELECTION,
} from '../layoutItemActions/layoutTreeActions'



/** 
 * @type {LayoutReducer} 
 */
export function layoutTreeReducer(state, action, schemaApi) {

  return produce(state, draft => {

    const manager = new TreeStateManager(
      draft,
      draft.layoutTree.items,
      draft.layoutTree.openState,
      draft.ui.layoutTree.newItemIndicators
    );


    switch (action.type) {

      case LAYOUT_TREE_TOGGLE_OPEN: {
        /* NOTE: Not source-of-truth, this just syncs the data for initialOpen */
        const id = action.payload;
        const idx = draft.layoutTree.openState.indexOf(id);
        if (idx === -1) {
          draft.layoutTree.openState.push(id);
        } else {
          draft.layoutTree.openState.splice(idx, 1);
        }
        break;
      }

      case LAYOUT_TREE_SET_SELECTION: {
        /* Focuses the tree on a specific item, scroll-to basically */
        const id = action.payload;
        draft.layoutTree.selection = id;
        break;
      }

      case LAYOUT_TREE_COPY_LAYOUT: {
        const { itemPayload } = action.payload;
        const { parentId, index, newItem } = itemPayload;

        if (!(newItem.id in draft.layouts)) {
          console.error(
            'LAYOUT_TREE_COPY_LAYOUT: layout not found.\n' +
            'profileMapReducer should have responded first, in the same render cycle.',
            newItem.id
          );
          break;
        }

        manager.createItem(parentId, index, newItem, false);
        break;
      }

      case LAYOUT_TREE_CREATE_ITEM: {
        const { parentId, index, newItem, openFolder = false } = action.payload;
        manager.createItem(parentId, index, newItem);

        if (openFolder && newItem.isFolder) {
          draft.layoutTree.openState.push(newItem.id);
        }

        break;
      }

      case LAYOUT_TREE_MOVE_ITEMS: {
        const { dragIds, dragNodes, parentId, parentNode, index } = action.payload;
        dragIds.forEach(id => manager.moveItem(id, parentId, index));
        break;
      }

      case LAYOUT_TREE_RENAME_ITEM: {
        // NOTE: profileMapReducer also responds to this action
        const { id, name, itemType } = action.payload;
        if (!draft.layoutTree.items[id]) break;
        draft.layoutTree.items[id].label = name;
        break;
      }

      case LAYOUT_TREE_DELETE_ITEMS: {
        // These IDS have already been recursed and collected
        // NOTE: profileMapReducer also responds to this action
        const { ids } = action.payload;
        const parentMap = manager.buildParentMap();
        ids.forEach(id => manager.deleteItem(id, parentMap));

        break;
      }

      case LAYOUT_TREE_CLEAR_NEW_ITEM_INDICATOR: {
        const id = action.payload;
        const idx = draft.ui.layoutTree.newItemIndicators.indexOf(id);
        if (idx > -1) {
          draft.ui.layoutTree.newItemIndicators.splice(idx, 1);
        }
        break;
      }

      case LAYOUT_TREE_MOVE_ITEMS_WITH_BATCH_CONFLICTS: {
        // NOTE: profileMapReducer also responds to this action
        const { dragIds, parentId, index, batchActions } = action.payload;
        const parentMap = manager.buildParentMap();

        // Like everywhere else, parentId may be null. manager will assume root.

        const moveCounter = { current: 0 }

        batchActions.forEach(batchAction => {
          manager.handleBatchConflictAction(
            action.payload,
            batchAction,
            parentMap,
            moveCounter
          )
        })

        break;
      }

      default: {
        break;
      }
    }

  });

}
