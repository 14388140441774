import {
  middlewareActionType as mAction,
} from 'src/redux/middleware/layoutSyncMiddleware/constants';

export const REORDER_LAYOUT_TABS = mAction('reorder-layout-tabs');
export const ADD_LAYOUT_TAB = mAction('add-layout-tab');
export const REMOVE_LAYOUT_TAB = mAction('remove-layout-tab');


/** @typedef {import('../layoutActions').LayoutAction} LayoutAction */


/**
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {string} layoutId
 * @returns {LayoutAction}
 */
export function addLayoutTab(
  namespace,
  schemaApi,
  layoutId
) {
  return {
    type: ADD_LAYOUT_TAB,
    namespace,
    payload: { layoutId }
  };
}


/**
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {string} layoutId
 * @returns {LayoutAction}
 */
export function removeLayoutTab(
  namespace,
  schemaApi,
  layoutId
) {
  return {
    type: REMOVE_LAYOUT_TAB,
    namespace,
    payload: { layoutId }
  };
}


/**
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {string} layoutId
 * @returns {LayoutAction}
 */
export function reorderLayoutTabs(
  namespace,
  schemaApi,
  layoutOrderIds
) {
  return {
    type: REORDER_LAYOUT_TABS,
    namespace,
    payload: { layoutOrderIds }
  };
}

