import {
  Checkbox,
  Collapse,
  ListItem,
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import _noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import useSlots from 'src/hooks/useSlots';
import { MoveIcon } from 'src/theme/EdgeIcons';
import onEnter from 'src/utils/onEnter';
import { ItemContext, ListContext } from './context';
import ItemLabel from './ItemLabel';
import { Leading, Trailing } from './Visuals';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.fontSize.label,
    // padding: [[6, 8]],
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 6,
    paddingRight: 6,
    // paddingLeft: props => props.pl,
    // paddingRight: props => props.pr,
    borderRadius: theme.grid.borderRadius,
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    cursor: 'pointer',
    width: '100%',
    marginTop: 'unset',
    marginBottom: 'unset',
    lineHeight: '19px',
    textAlign: 'unset',
    '&:focus-visible': {
      ...theme.focus.outline
    },
    // This is so jank, but makeStyles({ props }) is killing performance
    [
      '&:hover:not(:has(.MuiIconButton-root:hover)), ' +
      '&[data-focus=true]:not(:has(.MuiIconButton-root:hover))'
    ]: {
      backgroundColor: theme.palette.action.hover
    },
    '&.--selected': {
      color: theme.palette.primary.main,
      '&.--checkbox': {
        color: theme.palette.text.primary
      }
    },
    '&.pl_10': {
      paddingLeft: 10 // Attempt to speed up Column form by avoiding another makeStyles call
    }
  },
  divider: {
    'display': 'flex',
    '-webkit-box-flex': 1,
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  dragHandle: {
    '&.--disabled': {
      opacity: .7,
    },
    '&:hover svg': {
      color: theme.palette.text.primary,
    },
    '& svg': {
      color: '#777A82',
    }
  },
  textCont: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    padding: [[9, 0, 7, 0]],
    fontWeight: 400,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    flexShrink: 1,
    '&.--dense': {
      paddingTop: 5,
      paddingBottom: 5
    }
  },
  checkbox: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'unset'
    }
  }
}));





function Item(props, ref) {
  const {
    className,
    pl,
    pr,
    role,
    selected,
    onSelect,
    selectionVariant,
    checkbox,
    title,
    dense,

    // private
    style,
    disabled,
    DragHandleProps,

    children,
    ...rest
  } = props;

  const classes = useStyles(); // TODO: Remove. makeStyles props are a huge slowdown for MUI
  const listContext = useContext(ListContext);
  const [slots, childrenWithoutSlots] = useSlots(props.children, {
    leading: Leading,
    trailing: Trailing,
    collapseContent: Collapse
  });

  const _draggable = listContext.draggable && DragHandleProps;

  const _disabled = disabled || listContext.disabled;

  const context = useMemo(() => ({
    disabled: _disabled,
    draggable: _draggable,
    selectionVariant,
    checkbox
  }), [_disabled, _draggable, selectionVariant, checkbox]);

  if (_draggable && listContext.virtual) {
    throw Error(`ActionList.Item cannot be both Virtual and Draggable.`)
  }

  if (_draggable && checkbox) {
    throw Error(`ActionList.Item cannot be both a Checkbox and a Draggable.`)
  }

  if (_draggable && slots.leading) {
    throw Error('ActionList.Item cannot be Draggable while having an ActionList.Leading defined.')
  }

  const isTextChild = childrenWithoutSlots.length === 1 && typeof childrenWithoutSlots[0] === 'string'

  return (
    <ItemContext.Provider value={context}>
      <ListItem
        role={role}
        button={role === 'button'}
        disabled={_disabled}
        tabIndex={_disabled ? undefined : 0}
        onClick={onSelect}
        onKeyDown={onEnter(onSelect)}
        style={style}
        ref={ref}
        title={title}
        className={clsx(
          classes.root,
          className,
          'action-list-item',
          {
            '--dense': dense,
            '--selected': selected,
            '--with-leading': Boolean(slots.leading) || checkbox || _draggable,
            '--checkbox': checkbox
          }
        )}
        {...rest}
      >

        {(listContext.draggable) && (
          <Leading key="draghandle">
            <div
              className={clsx(
                classes.dragHandle,
                'dragHandle',
                disabled && '--disabled'
              )}
              onClick={(e) => e.stopPropagation()}
              {...(_draggable && DragHandleProps)}
            >
              <MoveIcon />
            </div>
          </Leading>
        )}
        {Boolean(checkbox) && (
          <Leading key="checkbox">
            <Checkbox
              className={classes.checkbox}
              disableRipple
              checked={selected}
              tabIndex={-1}
              disabled={_disabled}
              aria-disabled={_disabled || undefined}
            />
          </Leading>
        )}
        {slots.leading}

        <div className={classes.divider}>
          <span className={clsx(
            classes.textCont,
            dense && '--dense'
          )}>
            {isTextChild ? (
              <ItemLabel>{childrenWithoutSlots}</ItemLabel>
            ) : (
              childrenWithoutSlots
            )}
          </span>
          {slots.trailing}
        </div>
      </ListItem>
      {slots.collapseContent}
    </ItemContext.Provider>
  );
}

const RefItem = React.memo(React.forwardRef(Item));


RefItem.displayName = 'ActionList.Item'


export const ItemPropTypes = {
  className: PropTypes.string,
  /** Required for Virtualized lists to apply styling  */
  style: PropTypes.object,
  role: PropTypes.oneOf(['button', 'menuitem', 'menuitemradio', 'menuitemcheckbox', 'option']),
  pl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectionVariant: PropTypes.oneOf(['single', 'multiple', 'highlight', 'none']),
  dense: PropTypes.bool,
  title: PropTypes.string,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  /** Private */
  draggable: PropTypes.bool,
  /** Private */
  DragHandleProps: PropTypes.object,
}


export const ItemDefaultProps = {
  role: 'menuitem',
  selectionVariant: 'none',
  selected: false,
  draggable: false,
  dense: false,
  pl: 10,
  pr: 10,
  onSelect: _noop,
  style: {},
}


RefItem.propTypes = {
  ...ItemPropTypes
};



RefItem.defaultProps = {
  ...ItemDefaultProps
}


export default RefItem;
