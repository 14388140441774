import _sortedUniqBy from 'lodash/sortedUniqBy';
import { getLeaves } from "react-mosaic-component";
import { createErrorNotification } from "src/redux/notifications/notificationActions";
import { COMPONENT_TYPES } from "../base/layoutSchema";
import { intIdExists } from "../base/reducerUtils";
import { PROFILE_CONFIG } from "./profileConfig";



/** @typedef {import("../base/layoutActions").Thunk} Thunk */
/** @typedef {import("../base/layoutActions").LayoutAction} LayoutAction */
/** @typedef {import("../base/layoutActions").Action} Action */



export const wAction = (type) => `@layout-watchlist/${type}`;


export const dispatchErrorNotification = (dispatch) => {
  dispatch({
    type: 'GENERIC_ERROR_NO_TARGET',
    payload: {
      ...createErrorNotification('There was an issue saving your Watchlist. Please refresh the page and try again.')
    }
  });
}


/**
 * @param {string} namespace
 * @returns {function(Action): LayoutAction}
 */
export const makeNamespaceDispatch = (namespace, dispatch) => (action) => {
  dispatch({ ...action, namespace })
}


/**
 * @param {object} namespace
 * @returns {string|boolean}
 */
export function getActiveLayoutId(namespaceState) {
  const activeLayoutId = Object.keys(namespaceState.layouts).find(lid => lid === namespaceState.activeLayout);
  if (!activeLayoutId) return false;

  return activeLayoutId;
}


/**
 * @param {Object} namespaceState
 * @returns {{columns: object[], watchlistIds: number[]}}
 */
export function getColumnsForAllActiveWatchlists(namespaceState) {
  const activeLayoutId = Object.keys(namespaceState.layouts).find(lid => lid === namespaceState.activeLayout);

  const componentIds = getLeaves(namespaceState.layouts[activeLayoutId].currentNode);
  const components = componentIds.map(cid => namespaceState.components[cid]);
  const watchlistComponents = components.filter(c => c.type === COMPONENT_TYPES.WATCHLIST);
  const watchlistComponentsWithProfile = watchlistComponents.filter(c => intIdExists(c?.[PROFILE_CONFIG.WATCHLIST_ROWS.idKey]));

  let watchlistIds = watchlistComponentsWithProfile
    .map(c => c[PROFILE_CONFIG.WATCHLIST_ROWS.idKey]);

  watchlistIds = [...new Set(watchlistIds)];

  let watchlistColumnProfileIds = watchlistComponentsWithProfile.map(c => c[PROFILE_CONFIG.SCANNER_COLUMNS.idKey]);

  let watchlistColumns = Object.values(namespaceState.profileMap[PROFILE_CONFIG.SCANNER_COLUMNS.listKey])
    .filter(c => watchlistColumnProfileIds.includes(c.id))
    .reduce((acc, curr) => [...acc, ...curr.columns], []);

  return {
    columns: _sortedUniqBy(watchlistColumns, 'column'),
    watchlistIds
  }
}


/**
 * @param {Object} namespaceState
 * @returns {object[]}
 */
export function getColumnsForSpecificWatchlist(watchlistProfileId, namespaceState) {
  const activeLayoutId = Object.keys(namespaceState.layouts).find(lid => lid === namespaceState.activeLayout);
  if (!activeLayoutId) return;

  const componentIds = getLeaves(namespaceState.layouts[activeLayoutId].currentNode);
  const components = componentIds.map(cid => namespaceState.components[cid]);
  const watchlistComponents = components.filter(c => c.type === COMPONENT_TYPES.WATCHLIST);
  const watchlistComponentsWithProfile = watchlistComponents.filter(c => c?.[PROFILE_CONFIG.WATCHLIST_ROWS.idKey] === watchlistProfileId);

  let watchlistColumnProfileIds = watchlistComponentsWithProfile.map(c => c[PROFILE_CONFIG.SCANNER_COLUMNS.idKey]);

  let watchlistColumns = Object.values(namespaceState.profileMap[PROFILE_CONFIG.SCANNER_COLUMNS.listKey])
    .filter(c => watchlistColumnProfileIds.includes(c.id))
    .reduce((acc, curr) => [...acc, ...curr.columns], []);

  return _sortedUniqBy(watchlistColumns, 'column')
}
