
import edgeUsersApi from 'src/apis/edgeUsersApi';
import _isEqual from 'lodash/isEqual';
import { getLeaves } from 'react-mosaic-component';
import LayoutSyncMiddleware from '../../middleware/layoutSyncMiddleware/layoutSyncMiddleware';
import { isPredefined } from '../base/reducerUtils';
import {
  predefinedLayouts,
  predefinedLayoutTreeItems,
  predefinedLinksByLayout,
  predefinedComponents,
  predefinedComponentProfiles
} from './predefinedLayouts'
import {
  fixProfilePermissionsOnDowngrade,
  makeLabelMap
} from '../base/layoutInitializationFunctions';
import { HISTORY_NAMESPACE, HistorySchemaApi } from './schema';
import { decidePlanLevel, profilePermissions } from 'src/hooks/useUserPlanPermissions';

/** @typedef {import('src/app/slicedForm/mapping/mappingDirections').ProfileStruct} ProfileStruct */
/** @typedef {import('../base/layoutSchema').Layout} Layout */
/** @typedef {import('src/app/slicedForm/shared/reducers/profileTreeReducer').TreeItem} TreeItem */
/** @typedef {import('../../middleware/layoutSyncMiddleware/constants.js').ChangesetResponse} ChangesetResponse */
/** @typedef {import('../../middleware/layoutSyncMiddleware/constants.js').LayoutResponse} LayoutResponse */
/** @typedef {import('../../middleware/layoutSyncMiddleware/constants.js').ChangeItem} ChangeItem */
/** @typedef {import('../../middleware/layoutSyncMiddleware/constants.js').RevisionRule} RevisionRule */
/** @typedef {import('../../middleware/layoutSyncMiddleware/constants.js').ReadRule} ReadRule */



/**
 * Defines how we modify records when reading from the database.
 * Note: You probably want to call these on the Parent of the target record, so we can modify the entire collection via filter/map.
 *
 * @type {Object.<string, ReadRule>} 
 */
const READ_RULES = {
  'layouts': {
    mapRecord: ({ record }) => {
      return {
        ...predefinedLayouts,
        ...record,
      }
    }
  },
  'layoutTree.items': {
    mapRecord: ({ record, state }) => {
      const items = HistorySchemaApi.mergePredefinedTreeItemsIntoRoot(
        record,
        predefinedLayoutTreeItems,
      );

      return HistorySchemaApi.addIdAndLabelToTreeItems(
        items,
        makeLabelMap(state.layouts)
      );
    }
  },
  'linksByLayout': {
    mapRecord: ({ record }) => {
      return {
        ...predefinedLinksByLayout,
        ...record
      }
    }
  },
  'components': {
    mapRecord: ({ record, user }) => {
      const planPermissions = profilePermissions[HISTORY_NAMESPACE];
      const planLevel = decidePlanLevel(user);

      const fixedPermissionsRecord = Object.entries(record).reduce((acc, [id, component]) => {
        acc[id] = fixProfilePermissionsOnDowngrade(id, component, planPermissions, planLevel, user, HistorySchemaApi);
        return acc;
      }, {});

      return {
        ...predefinedComponents,
        ...fixedPermissionsRecord
      }
    }
  },
  'profileMap.+': {
    // Called on parent of profile record, so we can add records
    mapRecord: ({ pathParts, record }) => {
      const profileListKey = pathParts[1];
      const cfg = HistorySchemaApi.getProfileConfigItem(profileListKey);
      const predefined = cfg.predefinedProfiles || {};

      return {
        ...predefined,
        ...record
      }
    }
  },
  'profileTree.*.items': {
    mapRecord: ({ pathParts, record, state }) => {
      const profileListKey = pathParts[1];
      const cfg = HistorySchemaApi.getProfileConfigItem(profileListKey);
      const predefinedMap = cfg.predefinedProfiles || {};
      const predefinedItems = HistorySchemaApi.generateDefaultPredefinedFolder(predefinedMap);

      const merged = HistorySchemaApi.mergePredefinedTreeItemsIntoRoot(record, predefinedItems);

      // This sucks because state.profileMap.* is not guaranteed to exist. If the user hasn't saved any
      // data, it won't even be initialized yet. So we can't pull label from it for predefineds.
      // This works because generateDefaultPredefinedFodler will already include the labels, but 
      // god damn this is messy.
      //
      // TODO: We need to specify initialState here, not inside reducer. We need to run each matcher
      // in order, regardless if the server has data. So we can ensure state.profileMap exists already.
      // This would also fix the horribly confusing initialState stuff
      // WARN: That causes problems with UI / non-saved state. That needs to be initialized too.
      return HistorySchemaApi.addIdAndLabelToTreeItems(
        merged,
        makeLabelMap(state.profileMap?.[profileListKey])
      )
    }
  },

}


const areSetsEqual = (a, b) => a.size === b.size && [...a].every(value => b.has(value));


/** 
 * Defines how we:
 *  1) Modify records before saving (localstorage or db)
 *  2) Build a revision changeset when sending to db
 *  3) What changes get a revision bump / sent to BroadcastChannel
 * 
 * Each specified record matching the path (wildcards allowed) will be
 * one record in dynamodb. A path of 'profileTree.+.items' will map to
 * the record 'profileTree#id1#items', for example.
 *
 * This also defines what get saved to localstorage. Any path not matching
 * will not be saved there.
 *
 * @type {Object.<string, RevisionRule>}
 */
const REVISION_RULES = {
  'layouts.+': {
    add: true,
    delete: true,
    filterRecord: ({ record }) => !isPredefined(record),
    put: (prev, next) => {
      const { currentNode: prevCurrentNode, ...prevRest } = prev;
      const { currentNode: nextCurrentNode, ...nextRest } = next;

      const setPrev = new Set(getLeaves(prevCurrentNode));
      const setNext = new Set(getLeaves(nextCurrentNode));

      return !_isEqual(prevRest, nextRest) || !areSetsEqual(setPrev, setNext);
    }
  },
  'layoutTree.items': {
    mapRecord: ({ record }) => {
      const cleaned = HistorySchemaApi.removePredefinedTreeItemsFromRoot(record);
      return HistorySchemaApi.removeIdAndLabelFromTreeItems(cleaned);
    },
    put: true,
  },
  'layoutTree.openState': {
    put: false,
    add: true,
  },
  'linksByLayout.*': {
    filterRecord: ({ pathParts, state }) => {
      const layoutId = pathParts[1];
      return !isPredefined(state.layouts?.[layoutId])
    },
    add: true,
    delete: true,
    put: false,
  },
  'globalSettings': {
    put: true
  },
  'activeLayout': {
    put: false,
  },
  'components.+': {
    filterRecord: ({ record }) => !isPredefined(record),
    add: true,
    delete: true,
    put: false
  },
  'profileMap.+.+': {
    filterRecord: ({ record }) => !isPredefined(record),
    add: true,
    delete: true,
    put: true // Maybe we don't want to communicate profile changes?
  },
  'profileTree.+.items': {
    mapRecord: ({ record }) => {
      const cleaned = HistorySchemaApi.removePredefinedTreeItemsFromRoot(record);
      return HistorySchemaApi.removeIdAndLabelFromTreeItems(cleaned);
    },
    put: true,
    add: true,
  },
  'profileTree.+.openState': {
    put: false,
    add: true,
  },
  'expressions.+.+': {
    add: true,
    delete: true,
    put: true,
  },
  'orderings.expressions.+': {
    put: true,
    add: true,
  },
}


/**
 * Send a changeset to the database
 * @param {string} namespace
 * @param {string} schemaVersion
 * @param {{revision: number, changeset: ChangeItem[] }} changesetReq
 * @returns {Promise<ChangesetResponse>}
 **/
async function persistChangeset(namespace, schemaVersion, changesetReq) {
  console.log('[MDW_HIST] PERSIST:', changesetReq);
  const { data } = await edgeUsersApi.post(`/user/revisionedlayouts/${schemaVersion}/${namespace}`, changesetReq);
  return data;
}


/**
 * Initialize our layout from the database
 * @param {string} namespace
 * @param {string} schemaVersion
 * @returns {Promise<LayoutResponse>}
 **/
async function fetchLayout(namespace, schemaVersion) {
  const { data } = await edgeUsersApi.get(`/user/revisionedlayouts/${schemaVersion}/${namespace}`);
  console.log('[MDW_HIST] FETCH END:', data);
  return data;
}



let middlewareApi = null;

/**
 * Singleton factory
 * @returns {LayoutSyncMiddleware}
 */
const getLayoutToplistMiddleware = () => {
  if (!middlewareApi) {
    middlewareApi = new LayoutSyncMiddleware({
      schemaVersion: 'v0',
      namespace: HISTORY_NAMESPACE,
      revisionConfig: REVISION_RULES,
      readConfig: READ_RULES,
      persistChangeset,
      fetchLayout,
    });
  }

  return middlewareApi
}

export default getLayoutToplistMiddleware;
