import {
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';
import BugReportIcon from '@material-ui/icons/BugReport';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo, useState, useRef } from 'react';
import { MosaicContext, MosaicWindowContext } from 'react-mosaic-component';
import { useDispatch } from 'react-redux';
import { useLayoutSelector } from 'src/redux/layoutV2/base/layoutContext';
import PanelIconButton from 'src/app/components/panels/PanelIconButton';
import PanelIconGroup from 'src/app/components/panels/PanelIconGroup';
import { useStyles as usePanelHeaderStyles } from 'src/app/components/panels/SinglePanelHeader';
import ConditionalWrapper from 'src/app/components/utility/ConditionalWrapper';
import DarkTooltip from 'src/app/components/utility/DarkTooltip';
import { useComponent, useLayoutApi, useLayoutNamespaceConfig } from 'src/redux/layoutV2/base/layoutContext';
import { selectComponentLinkColorById } from 'src/redux/layoutV2/base/layoutSelectors';
import { DenyLargeIcon, MoveIcon } from 'src/theme/EdgeIcons';
import LinkingDropdownMenu from './LinkingDropdownMenu';
import MosaicPanelTickerSearch from './MosaicPanelTickerSearch';
import Popover from 'src/app/slicedForm/FilterForm/elements/Popover';
import { MoreVert } from '@material-ui/icons';
import MosaicPanelControlsKebabMenu from './MosaicPanelControlsKebabMenu';
import { COMPONENT_TYPES } from 'src/redux/layoutV2/base/layoutSchema';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: 0,
    position: 'relative',
  },
  dragHandleIcon: {
    paddingLeft: 9,
    paddingRight: 9,
  },
  panelHeaderLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: 'inherit',
    paddingRight: 4,
    zIndex: 1
  },
  panelIconGroupCont: {
    position: 'absolute',
    right: 4,
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  panelIconGroup: {
    marginRight: 0,
  },
  spacer: {
    width: 15
  },
  uninteractable: {
    pointerEvents: 'none !important',
  },
  kebabPaperClass: {
    height: 'auto',
    backgroundColor: theme.palette.background.dark,
    border: [[1, 'solid', theme.palette.background.lightBorder]],
    borderRadius: 4,
  },
}));


function getTitles({
  title,
  titleSuppliment,
  componentTitle,
  tickerSearchRendered
}) {
  const value = {
    title: title,
    suppliment: titleSuppliment,
  }

  if (!title && !tickerSearchRendered) {
    value.title = componentTitle;
  }

  return value;
}


const preventParentDragHandle = (event) => {
  if (
    event.target.getAttribute('draggable') === 'true'
    && event.target !== event.currentTarget
  ) {
    // Allow react-arborist events to bubble up to DndProvider
    // Horrible, but I can't fix it any other way without adding a dedicated
    // drag handle icon to the MosaicPanelHeader.
    return;
  }

  // Prevent all other interactive elements from dragging.
  event.preventDefault();
  event.stopPropagation();
};


const handleDebugCopy = (componentId) => {
  navigator.clipboard.writeText(componentId);
  alert(`Copied Component ID to clipboard: ${componentId}`)
}


function MosaicPanelHeader({
  className,
  children,
  showLinkingDropdownMenu,
  showKebabMenu,
  showDragHandle,
  title,
  titleSuppliment,
  titleSupplimentColor,
  align,
  loading,
  noPaddingRight,
  showClearButton,
  tickerSearchDoNotShowLastTicker,
  tickerSearchDisabled,
  tickerSearchPlaceholder,
  tickerSearchIcon,
  tickerSearchValue,
  onTickerSearchSubmit,
  onTickerSearchClear,
  debug,
}) {
  const panelHeaderClasses = usePanelHeaderStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const mosaicContext = useContext(MosaicContext);
  const mosaicWindowContext = useContext(MosaicWindowContext);
  const { componentMap } = useLayoutNamespaceConfig();
  const { componentId, componentType } = useComponent();
  const {
    updateComponentLinkColor,
    showAddComponentOverlay
  } = useLayoutApi();
  const link = useLayoutSelector(selectComponentLinkColorById(componentId));
  const { bindTrigger, popoverApi, popoverProps } = Popover.usePopover({
    paperClassName: classes.kebabPaperClass,
    anchorPosition: 'left',
    maxWidth: 110,
  });

  const ComponentIcon = componentType && componentMap?.[componentType]?.Icon ? componentMap[componentType].Icon : MoveIcon
  const componentTitle = componentType && componentMap?.[componentType]?.title ? componentMap[componentType].title : null
  const titleValues = getTitles({ title, titleSuppliment, componentTitle, tickerSearchRendered: Boolean(onTickerSearchSubmit) });


  const handleLinkColor = useCallback((colorName) => {
    dispatch(updateComponentLinkColor(componentId, colorName));
  }, [dispatch, updateComponentLinkColor, componentId]);


  const handleKebabSplit = () => {
    dispatch(showAddComponentOverlay(componentId));
  };


  const handleKebabDelete = () => {
    mosaicContext?.mosaicActions?.remove(
      mosaicWindowContext.mosaicWindowActions.getPath()
    )
  }


  const renderTitle = () => {
    return (
      <div className={clsx(panelHeaderClasses.panelTitleCont)}>
        <Typography variant="h3" color="textPrimary" className="panelTitle" align={align}>{titleValues.title}</Typography>
        {!!(titleValues.suppliment) && React.isValidElement(titleValues.suppliment) ? (
          titleValues.suppliment
        ) : (
          <Typography variant="h4" className="panelTitleSuppliment" color="textSecondary" style={{ color: titleSupplimentColor }}>{titleValues.suppliment}</Typography>
        )}
      </div>
    );
  };


  const renderContent = () => (
    <div className={clsx(componentId, panelHeaderClasses.draggable)} data-component-id={componentId}>
      <Box className={clsx(panelHeaderClasses.root, classes.root, className, noPaddingRight && panelHeaderClasses.noPaddingRight)}>
        <div className={classes.panelHeaderLeft}>
          {(showDragHandle)
            ? (
              <ConditionalWrapper
                condition={componentTitle}
                wrapper={(children) => (
                  <DarkTooltip
                    title={(<div className={classes.tooltipContent}>{componentTitle}</div>)}
                    arrow={false}
                    enterDelay={0}
                    variant="primary"
                    placement="top"
                  >
                    {children}
                  </DarkTooltip>
                )}
              >
                <div className={classes.dragHandleIcon}>
                  <ComponentIcon />
                </div>
              </ConditionalWrapper>
            ) : (
              <div className={classes.spacer} />
            )}
          {(onTickerSearchSubmit) && (
            <div
              className={clsx(
                loading && classes.uninteractable
              )}
              draggable
              onDragStart={preventParentDragHandle}
            >
              <MosaicPanelTickerSearch
                doNotShowLastTicker={tickerSearchDoNotShowLastTicker}
                disabled={tickerSearchDisabled}
                Icon={tickerSearchIcon}
                ticker={tickerSearchValue}
                onSubmit={onTickerSearchSubmit}
                onClear={onTickerSearchClear}
                showClearButton={Boolean(onTickerSearchClear && showClearButton && tickerSearchValue)}
                nullPlaceholder={tickerSearchPlaceholder}
              />
            </div>
          )}
          {(titleValues.title || titleValues.suppliment) && renderTitle()}
        </div>
        <div
          draggable
          onDragStart={preventParentDragHandle}
          className={clsx(
            classes.panelIconGroupCont,
            loading && classes.uninteractable
          )}
        >
          <PanelIconGroup
            className={classes.panelIconGroup}
            compact
          >
            {Boolean(debug && process.env.REACT_APP_USERS_LAMBDA_STAGE !== 'prod') && (
              <PanelIconButton onClick={() => handleDebugCopy(componentId)} text={`Copy ID: ${componentId}`} Icon={BugReportIcon} shouldHideIconText>ID</PanelIconButton>
            )}
            {children}
            {showLinkingDropdownMenu && (
              <LinkingDropdownMenu
                link={link}
                onSelect={handleLinkColor}
              />
            )}
            {(showKebabMenu) && (
              <>
                <PanelIconButton
                  ariaLabel="more options"
                  ariua-haspopup="true"
                  Icon={MoreVert}
                  {...bindTrigger}
                  onClick={(e) => {
                    e.stopPropagation();
                    popoverApi.handleOpen(e);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.stopPropagation();
                      popoverApi.handleOpen(e);
                    }
                  }}
                />
                <div onClick={e => e.stopPropagation()}>
                  <Popover
                    {...popoverProps}
                    onClose={popoverProps.onClose}
                    transitionDuration={0}
                  >
                    <MosaicPanelControlsKebabMenu
                      onClose={popoverProps.onClose}
                      onSplit={handleKebabSplit}
                      // onCopy={componentType === COMPONENT_TYPES.SELECT ? undefined : handleKebabCopy}
                      onDelete={handleKebabDelete}
                    />
                  </Popover>
                </div>
              </>
            )}
          </PanelIconGroup>
        </div>
      </Box>
    </div>
  );


  const shouldConnectDrag = mosaicWindowContext?.mosaicWindowActions?.getPath()
    && mosaicWindowContext?.mosaicWindowActions?.getPath().length

  // Force the drag source to disconnect if we are the root element. This will automatically reconnect it when another panel is added.
  return shouldConnectDrag
    ? mosaicWindowContext.mosaicWindowActions.connectDragSource(renderContent())
    : renderContent();
}


MosaicPanelHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  titleSuppliment: PropTypes.string,
  titleSupplimentColor: PropTypes.string,
  align: PropTypes.string,
  showLinkingDropdownMenu: PropTypes.bool,
  showKebabMenu: PropTypes.bool,
  showClearButton: PropTypes.bool,
  loading: PropTypes.bool,
  showDragHandle: PropTypes.bool,
  noPaddingRight: PropTypes.bool,

  tickerSearchDoNotShowLastTicker: PropTypes.bool,
  tickerSearchDisabled: PropTypes.bool,
  tickerSearchPlaceholder: PropTypes.string,
  tickerSearchIcon: PropTypes.any,
  tickerSearchValue: PropTypes.string,
  onTickerSearchSubmit: PropTypes.func,
  onTickerSearchClear: PropTypes.func,

  // tickerSearchProps: PropTypes.objectOf(PropTypes.shape(MosaicPanelTickerSearch.propTypes)),
};


MosaicPanelHeader.defaultProps = {
  titleSupplimentColor: '#fff',
  align: 'left',
  noPaddingRight: false,
  showLinkingDropdownMenu: true,
  loading: true,
  showKebabMenu: true,
  showDragHandle: true,
  showClearButton: false,
  tickerSearchDoNotShowLastTicker: false,
  tickerSearchDisabled: false,
};


export default MosaicPanelHeader;

