
import { ClickAwayListener, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Trash2 } from 'react-feather';
import ActionList from 'src/app/components/ActionList';
import { CheckIcon, CopyIcon, EditIcon, PlusIcon2 } from 'src/theme/EdgeIcons';
import onEnter from 'src/utils/onEnter';

const useStyles = makeStyles(theme => ({
  kebabItem: {
    borderRadius: 0,
    paddingLeft: 8,
    paddingRight: 16
  },
  divider: {
    borderTop: [[1, 'solid', theme.palette.background.lightBorder]],
  },
  icon: {
    color: theme.palette.text.secondary,
  },
  errorColor: {
    color: theme.palette.error.main
  }
}));

/** @typedef {import('react-arborist').NodeApi} NodeApi */



/**
 * In order to improve drag/drop speeds, we need to render a single Popover 
 * outside of the tree. Then collect the node.id from a clicked node and 
 * use it to render this popover.
 *
 * Handles onCopy, onRename, onDelete for the profile.
 * Keyboard navigable once open.
 *
 * onCopy - External function we manage from useTreeController.onCopy
 * onDelete - Internal react-arborist function
 * onRename - Internal react-arborist function
 * onClose - Take from useKebabPopover.onClose
 * @param {Object} props
 * @param {string} className
 * @param {React.RefObject} treeRef
 * @param {function(NodeApi)} onCopy - Take from useTreeController.onCopy
 * @param {function} onClose
*/
export default function MosaicPanelControlsKebabMenu({
  className,
  onSplit,
  onCopy,
  onDelete,
  onClose,
}) {
  const classes = useStyles();
  const firstButtonRef = useRef(null);
  const [confirmingDelete, setConfirmingDelete] = useState(false);

  const handleSplit = () => {
    onClose();
    onSplit?.();
  }

  // const handleCopy = () => {
  //   onClose();
  //   onCopy?.();
  // }

  const handleDelete = () => {
    onClose();
    onDelete?.();
  }

  useEffect(() => {
    if (firstButtonRef.current) {
      firstButtonRef.current.focus();
    }
  }, [Boolean(firstButtonRef.current)]);

  const handleKeyDown = useCallback((e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    if (e.key === "Escape") {
      onClose(e);
      return;
    }

    const menuItems = Array.from(e.currentTarget.querySelectorAll('.MuiListItem-root[tabindex="0"]'));
    const currIndex = menuItems.indexOf(document.activeElement);

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      const nextIndex = (currIndex + 1) % menuItems.length;
      menuItems[nextIndex].focus();
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      const prevIndex = (currIndex - 1 + menuItems.length) % menuItems.length;
      menuItems[prevIndex].focus();
    }

    e.stopPropagation();
  }, [onClose]);

  return (
    <div onKeyDown={handleKeyDown} className={className}>
      <ActionList>
        {Boolean(onSplit) && (
          <ActionList.Item
            ref={firstButtonRef}
            tabIndex={0}
            className={classes.kebabItem}
            button
            disableRipple
            onClick={!confirmingDelete ? handleSplit : undefined}
            onKeyDown={onEnter(!confirmingDelete ? handleSplit : undefined)}
          >
            <ActionList.Leading className={classes.icon}>
              <PlusIcon2 className={clsx("plusIcon")} />
            </ActionList.Leading>
            Split
          </ActionList.Item>
        )}
        {/* {Boolean(onCopy) && ( */}
        {/*   <ActionList.Item */}
        {/*     tabIndex={0} */}
        {/*     className={classes.kebabItem} */}
        {/*     button */}
        {/*     disableRipple */}
        {/*     onClick={!confirmingDelete ? handleCopy : undefined} */}
        {/*     onKeyDown={onEnter(!confirmingDelete ? handleCopy : undefined)} */}
        {/*   > */}
        {/*     <ActionList.Leading className={classes.icon}> */}
        {/*       <CopyIcon className={clsx("copyIcon")} /> */}
        {/*     </ActionList.Leading> */}
        {/*     Copy */}
        {/*   </ActionList.Item> */}
        {/* )} */}
        {Boolean(onDelete) && (
          <ClickAwayListener onClickAway={() => setConfirmingDelete(false)}>
            <ActionList.Item
              tabIndex={0}
              button
              onClick={!confirmingDelete ? () => setConfirmingDelete(true) : handleDelete}
              onKeyDown={onEnter(!confirmingDelete ? () => setConfirmingDelete(true) : handleDelete)}
              className={clsx(classes.kebabItem, classes.errorColor, classes.divider)}
              disableRipple
            >
              <ActionList.Leading className={classes.errorColor}>
                {confirmingDelete ? <CheckIcon className={clsx("checkIcon")} /> : <Trash2 className={clsx("trashIcon")} />}
              </ActionList.Leading>
              {confirmingDelete ? 'Confirm?' : 'Delete'}
            </ActionList.Item>
          </ClickAwayListener>
        )}
      </ActionList>
    </div>
  )
}


