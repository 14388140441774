import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ListContext } from './context';
import Heading from './Heading';
import { useListStyles } from './styles';
import {
  List as MUIList,
  makeStyles
} from '@material-ui/core';
import useSlots from 'src/hooks/useSlots';



const List = React.forwardRef(function List(props, ref) {
  const {
    className,
    role,
    component,
    disabled,
    dense,
    ...rest
  } = props;

  const listClasses = useListStyles();
  const [slots, childrenWithoutSlots] = useSlots(props.children, {
    heading: Heading
  });

  const context = useMemo(() => ({
    virtual: false,
    draggable: false,
    component,
    disabled,
    role
  }), [component, disabled, role]);


  return (
    <ListContext.Provider value={context}>
      {slots.heading}
      <MUIList
        className={clsx(className, listClasses.root)}
        role={role}
        dense={dense}
        ref={ref}
        {...rest}
      >
        {childrenWithoutSlots}
      </MUIList>
    </ListContext.Provider>
  );
});


List.displayName = 'ActionList';


List.propTypes = {
  className: PropTypes.string,
  /** Aria-Role. Usually listbox or menu */
  role: PropTypes.string,
  component: PropTypes.elementType,
  /** Must be an array of <ActionList.Item /> instances */
  dense: PropTypes.bool,
  children: PropTypes.any
};


List.defaultProps = {
  role: 'listbox',
  component: 'ul',
  dense: true
};


export default List;
