import PropTypes from "prop-types";
import React, { createContext, useContext, useMemo } from 'react';
import { PROFILE_PERMISSION_SCOPES, profilePermissionDefaults } from 'src/hooks/useUserPlanPermissions';
import { PREDEF_PREFIX } from 'src/redux/profileSettings/profileSettingsConfig';
import { selectActiveProfile, selectActiveProfileId } from '../reducers/profileMapReducer';
import { useFormSelector } from './FormProvider';



const defaultFormSettingsValues = {
  permissions: {},
  settings: {},
  state: {},
  profileListKey: '',
  layoutKey: '',
};


const FormSettingsContext = createContext(defaultFormSettingsValues);



/**
 * Stores static settings for the form, not necissarily form state.
 * Note these values can change, but not in response to user input.
 *
 * We also precompute some locked values
 **/
function FormSettingsProvider({
  permissions,
  settings,
  children,
  defaultPermissions = profilePermissionDefaults
}) {
  const value = useMemo(() => ({
    permissions,
    defaultPermissions,
    settings,
  }), [permissions, settings, defaultPermissions]);

  return (
    <FormSettingsContext.Provider value={value}>
      {children}
    </FormSettingsContext.Provider>
  );
}


FormSettingsProvider.propTypes = {
  permissions: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  defaultScopes: PropTypes.object,
  children: PropTypes.any,
};


const emptyObj = {};

FormSettingsProvider.defaultProps = {
  permissions: emptyObj,
  settings: emptyObj,
}



export default FormSettingsProvider;

// This belongs in profileReducer.
// Needs to return False when no profiles exist.
// Alternatively, figure out how to remove this from shared components.
export function useIsPredefined() {
  const activeProfile = useFormSelector(selectActiveProfile);

  return activeProfile &&
    (activeProfile?.predefined || activeProfile?.id.startsWith(PREDEF_PREFIX));
}

/**
 * @typedef {Object} Permissions
 * @property {Boolean} value
 * @property {Date} unlocksAt
 **/


/**
 * @param {string[]} scopes
 * @returns {Permissions[]}
 **/
export function useFormPermissions(...scopes) {
  const value = useContext(FormSettingsContext);
  const defaults = value?.defaultPermissions || emptyObj;
  const permissions = value?.permissions || emptyObj;

  return useMemo(() => {
    const errs = [];
    const out = scopes.map(scope => {
      const perm = permissions[scope] || defaults[scope] || {};
      if (perm === undefined) {
        errs.push(scope);
      }
      return perm;
    });

    if (errs.length) {
      throw new Error(`Missing permissions for scopes: ${errs.join(', ')}. Must exist either explicitely, or in defaults passed to FormSettingsProvider.`);
    }

    return out;
  }, [value, ...scopes]);
}


/**
 * Either predefined, or customization disabled. Both mean the user cannot
 * change form values.
 **/
export function useIsEditingDisabled() {
  const isPredefined = useIsPredefined();
  const [{ value: customizationDisabled }] = useFormPermissions(PROFILE_PERMISSION_SCOPES.disabledCustomization);

  return Boolean(isPredefined || customizationDisabled);
}



export function useFormSettings() {
  const context = useContext(FormSettingsContext);
  return context?.settings || emptyObj;
}


export function useFormSetting(key, default_ = undefined) {
  const settings = useFormSettings();
  return (key in settings) ? settings[key] : default_;
}
