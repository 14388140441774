import { COMPONENT_TYPES } from 'src/redux/layoutV2/base/layoutSchema';
import { isExpired } from './hooks/useMosaicTickerExclude';

/**
 * MUTABLE!
 *
 * The excludedTicker list on Component state needs to be cleared at 4am every day.
 * useMosaicTickerExclude checks on update, and provides guards for each fetching API, but
 * we'd still rather start out with correct state on initial load, and check all profiles rather than
 * just the active one.

 * @TODO: We want to run this whenever a user changes layouts too.
 * @TODO: This won't be sent to server unless the user updates the layout. Not necissarily a problem, but not ideal either.
 *
 * @param {string} componentId 
 * @param {object} component
 * @returns {object} - component
 */
export const checkScannerTickerExpirationList = (componentId, component) => {
  if (component.type !== COMPONENT_TYPES.SCANNER) return component;

  const {
    excludedTickers = [],
    excludedTickersExpiration = null
  } = component;

  if (excludedTickers && excludedTickers.length && isExpired(excludedTickersExpiration)) {
    console.log(`[layout.toplist.initializationFunctions] CLEARED TICKER EXPIRATION. ${component.type}:${component.id}`)
    return {
      ...component,
      excludedTickers: [],
      excludedTickersExpiration: null
    };
  }
  return component;
};

