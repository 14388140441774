import {
  ClickAwayListener,
  IconButton,
  InputBase,
  makeStyles,
  Popper
} from '@material-ui/core';
import clsx from 'clsx';
import _uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { searchTicker } from 'src/apis/edgeProxyApi';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import useStateFromProp from 'src/hooks/useStateFromProp';
import { DenyLargeIcon, SearchIcon } from 'src/theme/EdgeIcons';
import MosaicPanelTickerSearchMenu from './MosaicPanelTickerSearchMenu';


const useStyles = makeStyles((theme) => ({
  root: {},
  inputWrap: {
    background: theme.palette.background.paperAlt,
    width: props => props.inputWidth,
    position: 'relative !important',
    '&.ticker-search-has-clear': {
      display: 'flex',
      '& $inputBase': {
        flex: 1
      }
    }
  },
  inputBase: {
    paddingLeft: 10,
    '& input': {
      transition: 'none !important',
      paddingLeft: 6,
      paddingTop: 5,
      paddingBottom: 5,
    },
    '&:not(.Mui-focused) input::placeholder': {
      color: theme.palette.text.primary,
      // opacity: 1
    },
    '& svg': {
      fontSize: '18px',
    }
  },
  clearButton: {
    padding: 0,
    borderRadius: 0,
    opacity: .7,
    '&:hover': {
      opacity: 1
    },
    borderLeft: `1px solid ${theme.palette.background.panelHeader}`,
    '& .MuiSvgIcon-root': {
      marginBottom: 0,
      fontSize: 24,
      color: theme.palette.text.primary,
    }
  },
  disabled: {
    cursor: 'not-allowed',
    '& > *': {
      pointerEvents: 'none',
    }
  }
}));


const wrapIndex = (i, max) => ((i % max) + max) % max;


function MosaicPanelTickerSearch({
  className,
  menuClassName,
  ticker,
  onSubmit,
  onClear,
  Icon,
  disabled,
  doNotShowLastTicker,
  showClearButton,
  nullPlaceholder,
  inputWidth,
  menuWidth
}) {
  const classes = useStyles({ inputWidth });
  const anchorEl = useRef();
  const [id] = useState(() => _uniqueId('ticker-search-'));
  const [query, setQuery] = useState('');
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(-1);
  const [placeholder, setPlaceholder] = useStateFromProp(ticker);
  const lastInputTimestamp = useRef(0);
  const isMounted = useIsMountedRef();
  const inputRef = useRef();


  const fetchFilteredTickers = useCallback(async (queryVal) => {
    const timestamp = + new Date();
    let list = [];
    lastInputTimestamp.current = timestamp;

    if (queryVal && queryVal.length > 0) {
      try {
        // No authentication, for performance reasons. Can't use edgeApi, use axios directly.
        list = await searchTicker(queryVal);
      } catch (err) { console.log(err); }
    }
    if (timestamp === lastInputTimestamp.current && isMounted.current) {
      setOptions(list);
    }
  }, []);


  useEffect(() => {
    if (query) {
      void fetchFilteredTickers(query);
    } else {
      resetElement();
    }
  }, [query]);


  const resetElement = () => {
    setOptions([]);
    setSelectedOption(-1);
    setQuery('');
  };


  const handleClickAway = () => {
    resetElement();
  };


  const handleQueryChange = (event) => {
    setQuery(event.target.value.toUpperCase());
  };


  const handleTickerSelect = (value) => {
    if (value && value?.symbol) {
      onSubmit(value.symbol);
      if (!doNotShowLastTicker) {
        setPlaceholder(value.symbol);
      }
    }
    resetElement();
    if (doNotShowLastTicker) {
      setPlaceholder(nullPlaceholder);
    }
    inputRef.current?.focus();
  };


  // -1 selOption means use query as ticker. Other values means use options[value]
  const handleKeyDown = (event) => {
    const VALID_KEYS = ['ArrowUp', 'ArrowDown', 'Enter', 'Escape'];

    if (event.key === 'ArrowUp') {
      if (selectedOption === -1) {
        setSelectedOption(options.length - 1);
      } else {
        setSelectedOption(wrapIndex(selectedOption - 1, options.length));
      }
    } else if (event.key === 'ArrowDown') {
      if (selectedOption === -1) {
        setSelectedOption(0);
      } else {
        setSelectedOption(wrapIndex(selectedOption + 1, options.length));
      }
    } else if (event.key === 'Enter') {
      if (selectedOption === -1) {
        handleTickerSelect({ symbol: query });
      } else {
        handleTickerSelect(options[selectedOption]);
      }
    } else if (event.key === 'Escape') {
      resetElement();
    }

    if (VALID_KEYS.includes(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <div className={clsx(
      className,
      classes.root,
      disabled && classes.disabled
    )}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div
          ref={anchorEl}
          className={clsx(
            classes.inputWrap,
            'ticker-search-bg',
            'ticker-search-input-wrap',
            showClearButton && 'ticker-search-has-clear'
          )}
        >
          <InputBase
            startAdornment={
              <span style={{ pointerEvents: 'none' }}>
                <Icon />
              </span>
            }
            inputRef={inputRef}
            type="text"
            placeholder={placeholder || nullPlaceholder}
            onChange={handleQueryChange}
            onKeyDown={handleKeyDown}
            value={query}
            className={classes.inputBase}
          />
          {Boolean(showClearButton) && (
            <IconButton
              aria-label="copy"
              onClick={onClear}
              className={classes.clearButton}
            >
              <DenyLargeIcon />
            </IconButton>
          )}
        </div>
      </ClickAwayListener>
      <Popper
        id={`tickersearch-${id}`}
        open={!!options.length && !!query}
        anchorEl={anchorEl.current}
        placement="bottom-start"
        style={{
          zIndex: 9999,
          width: menuWidth || anchorEl.current?.clientWidth
        }}

      >
        <MosaicPanelTickerSearchMenu
          options={options}
          onSelect={handleTickerSelect}
          selected={selectedOption}
          className={menuClassName}
        />
      </Popper>
    </div>
  );
}

MosaicPanelTickerSearch.defaultProps = {
  inputWidth: 105,
  menuWidth: 250,
  Icon: SearchIcon,
  showClearButton: false
};


export const MosaicPanelPropTypes = {
  className: PropTypes.string,
  menuClassName: PropTypes.string,
  ticker: PropTypes.string,
  onSubmit: PropTypes.func,
  onClear: PropTypes.func,
  Icon: PropTypes.any,
  nullPlaceholder: PropTypes.string,
  showClearButton: PropTypes.bool,
  inputWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  menuWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};


MosaicPanelTickerSearch.propTypes = MosaicPanelPropTypes;

export default MosaicPanelTickerSearch;
