import objectScan from 'object-scan';
import _isEqual from 'lodash/isEqual';
import _isObject from 'lodash/isObject';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import { CHANGESET_ACTIONS } from './constants';

/** @typedef {import('./constants.js').RevisionRule} RevisionRule */
/** @typedef {import('./constants.js').ReadRule} ReadRule */
/** @typedef {import('./constants.js').ChangeItem} ChangeItem */


/*
 TODO: This might be too slow. Ideas:
  1) Manually do this yourself with isEqual
  2) Use deep-diff, and convert arrays to objects somehow
  3) Try to speed up object-scan
*/



const hasMapping = (rule) => {
  return 'mapRecord' in rule || 'filterRecord' in rule;
}


/**
 * Apply our readModifications before initializing the reducer.
 * This is useful for applying mappings to the entire collection.
 * Will most likely be called no matter which source the data is from (db/local/init)
 *
 * @param {object} state - Make sure this is NOT a direct reference to store state!
 * @param {Object.<string, ReadRule>} rules
 * @param {object} user - Current user in redux. Needed for planLevel mappings.
 * @returns {object}
 */
export function applyInitializationReadModifications(state, rules, user) {

  // Because we're modifying state directly, we can skip over rules that don't apply changes
  // TODO: For localstorage we build a new object rather than modify, thats probably a better path.-
  const patterns = Object.keys(rules).filter(k => hasMapping(rules[k]));

  objectScan(patterns, {
    filterFn: ({ isMatch, getKey, getValue, getParent, getMatchedBy }) => {
      if (!isMatch) {
        return;
      }

      const matchedBy = getMatchedBy();
      const value = getValue();
      const pathParts = getKey();
      const parent = getParent();
      const rule = rules[matchedBy[0]];
      const { mapRecord, filterRecord } = rule;

      const filterResult = filterRecord?.({
        pathParts,
        record: value,
        records: parent,
        state,
        user
      });

      if (filterResult === false) {
        const key = pathParts[pathParts.length - 1];
        delete parent[key];
        return; // Stop further processing if item is removed
      }

      if (mapRecord) {
        _set(state, pathParts, mapRecord({
          pathParts,
          record: value,
          records: parent,
          state,
          user
        }));
      }
    },
    useArraySelector: true,
    joined: false,
  })(state);

  return state;
}


/**
 * Apply our writeModifications before saving to localstorage.
 * TODO: We could potentially build this in-unison with buildChangeset.
 *
 * @param {object} state
 * @param {Object.<string, RevisionRule>} rules
 * @returns {object}
 */
export function applyLocalstorageWriteModifications(
  state,
  rules,
) {
  const filteredState = {};

  objectScan(Object.keys(rules), {
    filterFn: ({ isMatch, getKey, getValue, getParent, getMatchedBy }) => {
      if (!isMatch) {
        return;
      }
      const matchedBy = getMatchedBy();
      const value = getValue();
      const pathParts = getKey();
      const parent = getParent();
      const rule = rules[matchedBy[0]];
      const { mapRecord, filterRecord } = rule;

      const filterResult = filterRecord?.({
        pathParts,
        record: value,
        records: parent,
        state // NOTE: This is wrong, huh? It won't have any other mappings
      });

      if (filterResult === false) {
        return
      }

      let newValue = value;

      if (mapRecord) {
        newValue = mapRecord({
          pathParts,
          record: value,
          records: parent,
          state // NOTE: Same here
        })
      }

      _set(filteredState, pathParts, newValue);
    },
    useArraySelector: true,
    joined: false,
  })(state);

  return filteredState;
}



/**
 * Finds DELETE items. You must call this function with prevStatem as the search target.
 * @param {object} prevState
 * @param {object} nextState
 * @param {Object.<string, RevisionRule>} rules
 * @param {ChangeItem[]} [changes]
 * @returns {function}
 */
const makeDeleteCallback = (
  prevState,
  nextState,
  rules,
  changes = []
) => {
  return ({ isMatch, getKey, getValue, getParent, getMatchedBy }) => {
    if (!isMatch) {
      return;
    }
    const matchedBy = getMatchedBy();
    const prevValue = getValue();
    const pathParts = getKey();
    const rule = rules[matchedBy[0]];

    if (matchedBy.length > 1) {
      console.error('Multiple patterns matched', { matchedBy, pathParts });
      return;
    }

    if (!('delete' in rule)) {
      return;
    }

    const prevParent = getParent();
    const { filterRecord } = rule;

    const filterResult = filterRecord?.({
      pathParts,
      record: prevValue,
      records: prevParent,
      state: prevState
    });

    if (filterResult === false) {
      return;
    }

    const nextValue = _get(nextState, pathParts);

    if (prevValue && nextValue === undefined) {
      const shouldRevision = typeof rule.delete === 'function' ? rule.delete(prevValue, undefined) : rule.delete;

      changes.push({
        action: CHANGESET_ACTIONS.delete,
        revision: Boolean(shouldRevision),
        key: pathParts.join("#"),
      })
    }
  }
}


/**
 * Finds PUT or ADD items. Call this with nextState as the target.
 * @param {object} prevState
 * @param {object} nextState
 * @param {RevisionRule[]} rules
 * @param {ChangeItem[]} [changes]
 * @param {object} [options]
 * @param {boolean} [options.ignoreRules] - Prevent the "Cannot ADD" and "Cannot PUT" checks, for initialization
 * @returns {function}
 **/
const makePutOrAddCallback = (
  prevState,
  nextState,
  rules,
  changes = [],
  { ignoreRules = false } = {}
) => {
  return ({ isMatch, getKey, getValue, getParent, getMatchedBy }) => {
    if (!isMatch) {
      return;
    }
    const matchedBy = getMatchedBy();
    const nextValue = getValue();
    const pathParts = getKey();
    const rule = rules[matchedBy[0]];

    if (matchedBy.length > 1) {
      console.error('Multiple patterns matched', { matchedBy, pathParts });
    }

    if (!('put' in rule) && !('add' in rule)) {
      return;
    }

    const nextParent = getParent();
    const { filterRecord, mapRecord } = rule;

    const filterResult = filterRecord?.({
      pathParts,
      record: nextValue,
      records: nextParent,
      state: nextState
    });

    if (filterResult === false) {
      return
    }

    const prevValue = _get(prevState, pathParts);

    const change = {
      key: pathParts.join("#"),
      data: mapRecord
        ? mapRecord({
          pathParts,
          record: nextValue,
          parent: nextParent,
          state: nextState
        })
        : nextValue,
    };

    if (nextValue && prevValue === undefined) {
      if (!('add' in rule) && !ignoreRules) {
        console.error('Cannot ADD', { pathParts, nextValue });
        return;
      }
      change.action = CHANGESET_ACTIONS.add;
      const shouldRevision = typeof rule.add === 'function' ? rule.add(prevValue, nextValue) : rule.add;
      change.revision = Boolean(shouldRevision);
    } else if (prevValue && nextValue && !_isEqual(prevValue, nextValue) && !ignoreRules) {
      if (!('put' in rule)) {
        console.error('Cannot PUT', { pathParts, prevValue, nextValue });
        return;
      }
      change.action = CHANGESET_ACTIONS.put;
      const shouldRevision = typeof rule.put === 'function' ? rule.put(prevValue, nextValue) : rule.put;
      change.revision = Boolean(shouldRevision);
    } else {
      // No change
      return;
    }

    changes.push(change);
  }
}


/**
 * Buld the Changeset request to be sent to the server.
 * Does not involve localstorage or redux.
 * Will apply RevisionRule write mappings
 * @param {object} prevState
 * @param {object} nextState
 * @param {Object.<string, RevisionRule>} rules
 * @returns {ChangeItem[]}
 **/
export function buildChangeset(prevState, nextState, rules) {
  const deletePatterns = Object.keys(rules).filter(k => CHANGESET_ACTIONS.delete in rules[k]);
  const putOrUpdatePatterns = Object.keys(rules).filter(k => CHANGESET_ACTIONS.put in rules[k] || CHANGESET_ACTIONS.add in rules[k]);

  const changes = [];

  const deleteCb = makeDeleteCallback(prevState, nextState, rules, changes);
  const putOrUpdateCb = makePutOrAddCallback(prevState, nextState, rules, changes);

  objectScan(deletePatterns, {
    filterFn: deleteCb,
    useArraySelector: true,
    joined: false,
  })(prevState);

  objectScan(putOrUpdatePatterns, {
    filterFn: putOrUpdateCb,
    useArraySelector: true,
    joined: false,
  })(nextState);

  return changes;
}



/**
 * Initialize the server on initial load.
 * We could instead write a new endpoint that accepts a full state object.
 * This is easier (I think), but bigger network cost and slower to calculate.
 * @param {object} state
 * @param {Object.<string, RevisionRule>} rules
 * @returns {ChangeItem[]}
 */
export function buildInitializationChangeset(initialState, rules) {
  const changes = [];

  const putOrUpdateCb = makePutOrAddCallback({}, initialState, rules, changes, { ignoreRules: true });

  objectScan(Object.keys(rules), {
    filterFn: putOrUpdateCb,
    useArraySelector: true,
    joined: false,
  })(initialState);

  return changes;
}




