import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ItemContext } from './context';
import ConditionalTooltip from 'src/app/components/utility/ConditionalTooltip';
import {
  IconButton as MuiIconButton,
  makeStyles,
} from '@material-ui/core';



const IconButton = React.forwardRef(function IconButton({
  className,
  Icon,
  label,
  disabled,
  edge,
  ariaLabel,
  placeholder,
  onClick,
  ...rest
}, ref) {
  const { disabled: _disabled } = useContext(ItemContext);

  return placeholder ? (
    <div
      ref={ref}
      className={clsx(
        className,
        'MuiButtonBase-root',
        'MuiIconButton-root',
        '--placeholder'
      )}
      tabIndex={undefined}
      aria-hidden="true"
      style={{ visibility: 'hidden' }}
    >
      <div className="MuiIconButton-label">
        <div className='MuiSvgIcon-root' />
      </div>
    </div>
  ) : (
    <ConditionalTooltip label={label}>
      <span ref={ref}>
        <MuiIconButton
          {...rest}
          className={clsx(className)}
          disableRipple
          edge={edge}
          aria-label={ariaLabel}
          disabled={disabled || _disabled}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClick(e);
          }}
        >
          <Icon />
        </MuiIconButton>
      </span>
    </ConditionalTooltip>
  )
});


IconButton.displayName = 'ActionList.IconButton';


IconButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  Icon: PropTypes.elementType,
  edge: PropTypes.oneOf(['start', 'end']),
  ariaLabel: PropTypes.string,
  placeholder: PropTypes.bool,
  onClick: PropTypes.func
};


IconButton.defaultProps = {};


export default IconButton;
