import { getLeaves } from 'react-mosaic-component';
import produce from 'immer';
import { BATCH_CONFLICT_ACTION } from 'src/app/slicedForm/utility/arborHelpers';
import {
  UPDATE_CURRENT_NODE,
  CREATE_LAYOUT,
  COPY_LAYOUT,
  DELETE_LAYOUT,
  RENAME_LAYOUT,
  SET_ACTIVE_LAYOUT
} from '../layoutItemActions/layoutMapActions';
import {
  LAYOUT_TREE_COPY_LAYOUT,
  LAYOUT_TREE_DELETE_ITEMS,
  LAYOUT_TREE_MOVE_ITEMS_WITH_BATCH_CONFLICTS,
  LAYOUT_TREE_RENAME_ITEM,
} from '../layoutItemActions/layoutTreeActions';




/** @typedef {import("../layoutSchema.js").LayoutReducer} LayoutReducer */
/** @typedef {import('../layoutSchema.js').ProfileStruct} ProfileStruct */
/** @typedef {import ("../layoutSchema.js").CreateLayoutPayload} CreateLayoutPayload */



/** 
 * Helper function to create/copy layouts
 * @param {object} draft
 * @partam {CreateLayoutPayload} layoutPayload
 * @param {boolean} [active=true]
 */
function _addNewLayout(draft, layoutPayload, active = true) {
  const {
    newId,
    layout,
    components,
    profilesByComponent,
    linksByLayout
  } = layoutPayload;

  draft.layouts[newId] = layout;

  Object.assign(draft.components, components);

  // May not exist
  if (profilesByComponent) {
    Object.assign(draft.profilesByComponent, profilesByComponent)
  }

  // May not exist. Must nest under new ID.
  if (linksByLayout) {
    Object.assign(draft.linksByLayout, { [newId]: linksByLayout })
  }

  // Set active if specified, or if we don't have an active
  if (active || !draft.activeLayout) {
    draft.activeLayout = newId;
  }
}



/**
 * @param {object} draft
 * @param {string} layoutId
 */
function _deleteLayout(draft, layoutId) {
  if (!draft?.layouts?.[layoutId]) return;

  const components = getLeaves(draft.layouts[layoutId].currentNode);

  // Remove layout
  delete draft.layouts[layoutId];

  // Remove its components
  for (const componentId of components) {
    delete draft.components[componentId];
    delete draft?.profilesByComponent?.[componentId];
  }

  // Delete if necissary
  if (layoutId in draft?.linksByLayout) {
    delete draft.linksByLayout[layoutId];
  }

  // Set active layout if necessary
  if (draft.activeLayout === layoutId) {
    draft.activeLayout = Object.keys(draft.layouts).length ? Object.keys(draft.layouts)[0] : null;
  }
}



/**
 * Updates the Mosaic tree, triggered by Mosaic event handlers.
 * If we detect a leaf has been deleted, we also must go and delete the component from
 * our state.
 * @type {ReducerHandler}
 */
function updateCurrentNode(draft, action, schemaApi) {
  const { layoutId, newNode } = action.payload;

  if (!draft?.layouts?.[layoutId]) return;

  // overwrite node
  draft.layouts[layoutId].currentNode = newNode;

  // remove any components within old node
  const previousLeaves = getLeaves(draft.layouts[layoutId].currentNode);
  const newLeaves = getLeaves(newNode);
  const removedLeaves = previousLeaves.filter(x => !newLeaves.includes(x));
  removedLeaves.forEach(id => {
    delete draft.components[id];
    delete draft?.profilesByComponent?.[id];
  });
}




/**
 * Inserts a mosaic tree and component schema into state,
 * setting activeProfile if necessary.
 * @type {ReducerHandler}
 */
function createLayout(draft, action, schemaApi) {
  const shouldSetActive = action?.source !== 'broadcast'
  _addNewLayout(draft, action.payload, shouldSetActive)
}


/**
 * Inserts a mosaic tree and component schema into state.
 * @type {ReducerHandler}
 */
function copyLayout(draft, action, schemaApi) {
  _addNewLayout(draft, action.payload, false);
}


/**
 * Delete a layout, and any referenced components
 * @type {ReducerHandler}
 */
function deleteLayout(draft, action, schemaApi) {
  const { layoutId } = action.payload;
  _deleteLayout(draft, layoutId)
}


/** @type {ReducerHandler} */
function renameLayout(draft, action, schemaApi) {
  const { layoutId, newName } = action.payload;

  if (!draft?.layouts?.[layoutId]) return;

  draft.layouts[layoutId].name = newName;
}


/** @type {ReducerHandler} */
function setActiveLayout(draft, action, schemaApi) {
  const { activeLayout } = action.payload;
  draft.activeLayout = activeLayout;
}


/** 
 * NOTE: Only make the layout here. Let mapReducer handle the tree item.
 *
 * @type {ReducerHandler}
 */
function treeCreateLayout(draft, action, schemaApi) {
  const { layoutPayload, itemPayload } = action.payload;
  _addNewLayout(draft, layoutPayload, false);
}


/**
 * If a Tree item is renamed, and it is a layout, we also must
 * rename the source profile to keep them synced.
 * @type {ReducerHandler} 
 */
function treeRenameItemIfNeeded(draft, action, schemaApi) {
  const { id, name, itemType } = action.payload;
  if (itemType === 'folder' || !(id in draft.layouts)) return;
  draft.layouts[id].name = name;
}


/** 
 * If a Tree item is deleted, check if it is a Layout. If so, delete it.
 * @type {ReducerHandler} 
 */
function treeDeleteItemsIfNeeded(draft, action, schemaApi) {
  const { ids } = action.payload;

  ids.forEach(id => {
    if (id in draft.layouts) {
      _deleteLayout(draft, id)
    }
  });
}


function treeMoveItemsWithBatchConflicts(draft, action, schemaApi) {
  const { batchActions } = action.payload;

  batchActions.forEach(({ resolutionType, payload: batchPayload }) => {
    const { dragId, itemType, newName, itemsToOverwrite = [] } = batchPayload;

    switch (resolutionType) {
      case BATCH_CONFLICT_ACTION.OVERWRITE: {
        itemsToOverwrite.forEach(id => {
          if (id in draft.layouts) {
            delete draft.layouts[id];

            if (draft.activeProfile === id) {
              draft.activeProfile = Object.values(draft.profileMap)[0].id;
            }
          }
        })
        break;
      }
      case BATCH_CONFLICT_ACTION.KEEP: {
        if (dragId in draft.layouts && itemType === 'leaf') {
          draft.layouts[dragId].name = newName;
        }
        break;
      }
      default: {
        break;
      }
    }
  })

}




const handlers = {
  [UPDATE_CURRENT_NODE]: updateCurrentNode,
  [CREATE_LAYOUT]: createLayout,
  [COPY_LAYOUT]: copyLayout,
  [DELETE_LAYOUT]: deleteLayout,
  [RENAME_LAYOUT]: renameLayout,
  [SET_ACTIVE_LAYOUT]: setActiveLayout,

  // We have to respond to some Tree actions. Both reducers will respond.
  [LAYOUT_TREE_COPY_LAYOUT]: treeCreateLayout,
  [LAYOUT_TREE_RENAME_ITEM]: treeRenameItemIfNeeded,
  [LAYOUT_TREE_DELETE_ITEMS]: treeDeleteItemsIfNeeded,
  [LAYOUT_TREE_MOVE_ITEMS_WITH_BATCH_CONFLICTS]: treeMoveItemsWithBatchConflicts,
}


/** 
 * Base for all Layout item operations.
 * Combine with layoutTabs or layoutTree to get page-specific behavior.
 * These reducers may respond to the same actions in order to handle
 * normalization.
 *
 * @type {LayoutReducer} 
 */
export function layoutMapReducer(state, action, schemaApi) {
  const handler = handlers[action.type];
  if (!handler) return state;

  return produce(state, draft => handler(draft, action, schemaApi));
}
