import shortUuid from 'short-uuid';
import { PROFILE_TYPES } from '../base/layoutSchema';
import {
  predefinedLayoutScannerColumnProfiles,
  predefinedLayoutScannerFilterProfiles,
  predefinedLayoutKeyStatsProfiles,
  defaultLayoutHistoryFilterProfiles,
  defaultLayoutHistoryColumnProfiles,
  defaultLayoutNewsColumnProfiles,
  predefinedLayoutHistoryAggregateProfiles
} from './defaultProfiles';

/** @typedef {import("../layoutSchema").ProfileConfigItem} ProfileConfigItem **/

export const generateId = () => shortUuid.generate();

/**
 * @readonly
 * @type {{string: ProfileConfigItem}}
 **/
export const PROFILE_CONFIG = {
  HISTORY_FILTERS: {
    label: 'Ticker Stats Filters',
    metaKey: 'HISTORY_FILTERS',
    listKey: 'historyFilterProfiles',
    idKey: 'historyFilterProfileId',
    type: PROFILE_TYPES.FILTER,
    predefinedProfiles: defaultLayoutHistoryFilterProfiles,
    defaultProfileId: Object.keys(defaultLayoutHistoryFilterProfiles)[0]
  },
  HISTORY_COLUMNS: {
    label: 'Ticker Stats Columns',
    metaKey: 'HISTORY_COLUMNS',
    listKey: 'historyColumnProfiles',
    idKey: 'historyColumnProfileId',
    type: PROFILE_TYPES.COLUMN,
    predefinedProfiles: defaultLayoutHistoryColumnProfiles,
    defaultProfileId: Object.keys(defaultLayoutHistoryColumnProfiles)[0]
  },
  HISTORY_AGGREGATES: {
    label: 'Ticker Stats Metrics',
    metaKey: 'HISTORY_AGGREGATES',
    listKey: 'historyAggregateProfiles',
    idKey: 'historyAggregateProfileId',
    type: PROFILE_TYPES.AGGREGATE,
    predefinedProfiles: predefinedLayoutHistoryAggregateProfiles,
    defaultProfileId: Object.keys(predefinedLayoutHistoryAggregateProfiles)[0]
  },
  SCANNER_FILTERS: {
    label: 'Scanner Filters',
    metaKey: 'SCANNER_FILTERS',
    listKey: 'scannerFilterProfiles',
    idKey: 'scannerFilterProfileId',
    type: PROFILE_TYPES.FILTER,
    predefinedProfiles: predefinedLayoutScannerFilterProfiles,
    defaultProfileId: Object.keys(predefinedLayoutScannerFilterProfiles)[0]
  },
  SCANNER_COLUMNS: {
    label: 'Realtime Columns',
    metaKey: 'SCANNER_COLUMNS',
    listKey: 'scannerColumnProfiles',
    idKey: 'scannerColumnProfileId',
    type: PROFILE_TYPES.COLUMN,
    predefinedProfiles: predefinedLayoutScannerColumnProfiles,
    defaultProfileId: Object.keys(predefinedLayoutScannerColumnProfiles)[0]
  },
  NEWS_COLUMNS: {
    label: 'News Columns',
    metaKey: 'NEWS_COLUMNS',
    listKey: 'newsColumnProfiles',
    idKey: 'newsColumnProfileId',
    type: PROFILE_TYPES.COLUMN,
    predefinedProfiles: defaultLayoutNewsColumnProfiles,
    defaultProfileId: Object.keys(defaultLayoutNewsColumnProfiles)[0]
  },
  WATCHLIST_ROWS: {
    label: 'Watchlist Rows',
    metaKey: 'WATCHLIST_ROWS',
    nonLayoutState: true,
    type: PROFILE_TYPES.WATCHLIST_ROWS,
    listKey: 'watchlistRowProfiles',
    idKey: 'watchlistRowProfileId',
    dataKey: 'watchlistRowProfileData',
    defaultProfileId: 0
  },
  KEY_STATS: {
    label: 'Key Stats',
    metaKey: 'KEY_STATS',
    listKey: 'keyStatsProfiles',
    idKey: 'keyStatsProfileId',
    type: PROFILE_TYPES.KEY_STATS,
    predefinedProfiles: predefinedLayoutKeyStatsProfiles,
    defaultProfileId: predefinedLayoutKeyStatsProfiles[0].id
  }
}


