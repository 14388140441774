
import { STRUCTURAL_TYPES } from 'src/app/slicedForm/mapping/mappingDirections';
import { AGGREGATES, ARRAY_OPS, BOOLEAN_OPS, DATE_TYPES, ROLLING_DATE_OPS, TIMESERIES_CHART_TYPES } from 'src/app/slicedForm/FilterForm/definitions/inputEnums';
import { PREDEF_PREFIX } from 'src/redux/profileSettings/profileSettingsConfig';
import { addPredefinedKey, col, val, makeAggNames } from '../base/layoutSchema';


/** @typedef {import('src/app/slicedForm/mapping/mappingDirections').ProfileStruct} ProfileStruct */
/** @typedef {import('src/app/slicedForm/shared/reducers/profileTreeReducer').TreeItem} TreeItem */



export const predefinedHistoryFilterProfiles = addPredefinedKey({
  [`${PREDEF_PREFIX}recent-gappers`]: {
    id: `${PREDEF_PREFIX}recent-gappers`,
    name: 'Recent Gappers',
    filters: {
      AND: [
        {
          left: { column: 'day0_date', type: 'column' },
          dateType: DATE_TYPES.ROLLING,
          operator: ROLLING_DATE_OPS.GE,
          right: [val('rolling_30_days')]
        },
        {
          left: col('day0_open'),
          operator: BOOLEAN_OPS.BTW,
          right: [val(1), val(15)]
        },
        {
          left: col('day0_gap_pct'),
          operator: BOOLEAN_OPS.GE,
          right: [val(20)]
        },
        {
          left: col('day0_vol'),
          operator: BOOLEAN_OPS.GE,
          right: [val(2_000_000)]
        },
        {
          allowNull: true,
          left: col('share_group'),
          operator: ARRAY_OPS.IN,
          right: [val(['CS'])]
        }
      ]
    }
  },
  [`${PREDEF_PREFIX}day-2-plays`]: {
    id: `${PREDEF_PREFIX}day-2-plays`,
    name: 'Day 2 Plays',
    // orderby: col('day0_date'),
    // order: 'desc',
    filters: {
      AND: [
        {
          left: col('day0_date'),
          dateType: DATE_TYPES.ROLLING,
          operator: ROLLING_DATE_OPS.GE,
          right: [val('rolling_30_days')]
        },
        {
          left: col('day0_open'),
          operator: BOOLEAN_OPS.BTW,
          right: [val(1), val(15)],
        },
        {
          left: col('day0_vol'),
          operator: BOOLEAN_OPS.GE,
          right: [val(1_000_000)]
        },
        {
          left: col('day_m1_vol'),
          operator: BOOLEAN_OPS.GE,
          right: [val(2_000_000)]
        },
        {
          left: col('day_m1_gap_pct'),
          operator: BOOLEAN_OPS.GE,
          right: [val(40)]
        },
        {
          left: col('day_m1_chg_pct'),
          operator: BOOLEAN_OPS.GE,
          right: [val(20)]
        },
        {
          left: col('share_group'),
          operator: ARRAY_OPS.IN,
          right: [val(['CS'])]
        }
      ]
    }
  },
  [`${PREDEF_PREFIX}high-gappers`]: {
    id: `${PREDEF_PREFIX}high-gappers`,
    name: 'High Gappers',
    // orderby: col('day0_date'),
    // order: 'desc',
    filters: {
      AND: [
        {
          left: col('day0_date'),
          dateType: DATE_TYPES.ROLLING,
          operator: ROLLING_DATE_OPS.GE,
          right: [val('rolling_60_days')]
        },
        {
          left: col('day0_open'),
          operator: BOOLEAN_OPS.BTW,
          right: [val(1), val(15)],
        },
        {
          left: col('day0_gap_pct'),
          operator: BOOLEAN_OPS.GE,
          right: [val(50)]
        },
        {
          left: col('day0_vol'),
          operator: BOOLEAN_OPS.GE,
          right: [val(2_000_000)]
        },
        {
          left: col('share_group'),
          operator: ARRAY_OPS.IN,
          right: [val(['CS'])]
        }
      ]
    }
  },
  [`${PREDEF_PREFIX}intraday-movers`]: {
    id: `${PREDEF_PREFIX}intraday-movers`,
    name: 'Intraday Movers',
    // orderby: col('day0_date'),
    // order: 'desc',
    filters: {
      AND: [
        {
          left: col('day0_date'),
          dateType: DATE_TYPES.ROLLING,
          operator: ROLLING_DATE_OPS.GE,
          right: [val('rolling_60_days')]
        },
        {
          left: col('day0_open'),
          operator: BOOLEAN_OPS.BTW,
          right: [val(1), val(15)],
        },
        {
          left: col('day0_gap_pct'),
          operator: BOOLEAN_OPS.LE,
          right: [val(3)]
        },
        {
          left: col('day0_chg_pct'),
          operator: BOOLEAN_OPS.GE,
          right: [val(50)]
        },
        {
          left: col('day0_vol'),
          operator: BOOLEAN_OPS.GE,
          right: [val(2_000_000)]
        },
        {
          left: col('share_group'),
          operator: ARRAY_OPS.IN,
          right: [val(['CS'])]
        }
      ]
    }
  },
  [`${PREDEF_PREFIX}stocks-with-halts`]: {
    id: `${PREDEF_PREFIX}stocks-with-halts`,
    name: 'Stocks with Halts',
    // orderby: col('day0_date'),
    // order: 'desc',
    filters: {
      AND: [
        {
          left: col('day0_date'),
          dateType: DATE_TYPES.ROLLING,
          operator: ROLLING_DATE_OPS.GE,
          right: [val('rolling_60_days')]
        },
        {
          left: col('day0_open'),
          operator: BOOLEAN_OPS.BTW,
          right: [val(1), val(15)],
        },
        {
          left: col('day0_vol'),
          operator: BOOLEAN_OPS.GE,
          right: [val(2_000_000)]
        },
        {
          left: col('halts_count'),
          operator: BOOLEAN_OPS.GE,
          right: [val(1)]
        },
        {
          left: col('share_group'),
          operator: ARRAY_OPS.IN,
          right: [val(['CS'])]
        }
      ]
    }
  },
  [`${PREDEF_PREFIX}large-cap-movers`]: {
    id: `${PREDEF_PREFIX}large-cap-movers`,
    name: 'Large Cap Movers',
    // orderby: col('day0_date'),
    // order: 'desc',
    filters: {
      AND: [
        {
          left: col('day0_date'),
          dateType: DATE_TYPES.ROLLING,
          operator: ROLLING_DATE_OPS.GE,
          right: [val('rolling_60_days')]
        },
        {
          left: col('day0_open'),
          operator: BOOLEAN_OPS.BTW,
          right: [val(25), val(500)],
        },
        {
          left: col('day0_vol'),
          operator: BOOLEAN_OPS.GE,
          right: [val(2_000_000)]
        },
        {
          left: col('day0_chg'),
          operator: BOOLEAN_OPS.GE,
          right: [val(15)]
        },
        {
          left: col('share_group'),
          operator: ARRAY_OPS.IN,
          right: [val(['CS'])]
        }
      ]
    }
  }
});




export const predefinedHistoryColumnProfiles = addPredefinedKey({
  [`${PREDEF_PREFIX}large-cap`]: {
    id: `${PREDEF_PREFIX}large-cap`,
    name: 'Large Cap',
    columns: [
      { column: 'day0_date' },
      { column: 'ticker' },
      { column: 'day0_pm_vol' },
      { column: 'day0_gap_pct' },
      { column: 'day0_chg_pct' },
      { column: 'day1_ret_open_pct' },
      { column: 'day0_vol' },
      { column: 'day0_high_time' }
    ],
  },
  [`${PREDEF_PREFIX}small-cap`]: {
    id: `${PREDEF_PREFIX}small-cap`,
    name: 'Small Cap',
    columns: [
      { column: 'day0_date' },
      { column: 'ticker' },
      { column: 'day0_pm_vol' },
      { column: 'day0_gap_pct' },
      { column: 'day0_chg_pct' },
      { column: 'day1_ret_open_pct' },
      { column: 'day0_vol' },
      { column: 'day0_high_time' }
    ],
  },
});




export const predefinedHistoryAggregateProfiles = addPredefinedKey({
  [`${PREDEF_PREFIX}default`]: {
    id: `${PREDEF_PREFIX}default`,
    name: 'Default',
    aggregates: [
      {
        label: 'Day 1',
        [STRUCTURAL_TYPES.VISUAL_GROUP]: makeAggNames(
          {
            conditional_agg: AGGREGATES.CNT,
            target: col('day0_close'),
            label: 'Count',
            conditions: [],
          },
          {
            conditional_agg: AGGREGATES.AVG,
            target: col('day0_gap'),
            label: 'Gap',
            conditions: [],
          },
          {
            conditional_agg: AGGREGATES.AVG,
            target: col('day0_gap_pct'),
            label: 'Gap %',
            conditions: [],
          },
          {
            conditional_agg: AGGREGATES.CNT,
            target: col('day0_close'),
            label: 'Close > Open',
            conditions: [{
              left: col('day0_close'),
              operator: BOOLEAN_OPS.GE,
              right: [col('day0_open')]
            }],
          },
          {
            conditional_agg: AGGREGATES.CNT,
            target: col('day0_close'),
            label: 'Close < Open',
            conditions: [{
              left: col('day0_close'),
              operator: BOOLEAN_OPS.LT,
              right: [col('day0_open')]
            }],
          },
          {
            conditional_agg: AGGREGATES.AVG,
            target: col('day0_high_time'),
            label: 'HOD',
            conditions: [],
          },
          {
            conditional_agg: AGGREGATES.AVG,
            target: col('day0_low_time'),
            label: 'LOD',
            conditions: [],
          },
          {
            conditional_agg: AGGREGATES.AVG,
            target: col('day0_open_high_pct'),
            label: 'High Spike %',
            conditions: [],
          },
          {
            conditional_agg: AGGREGATES.AVG,
            target: col('day0_open_low_pct'),
            label: 'Low Spike %',
            conditions: [],
          },
          {
            conditional_agg: AGGREGATES.AVG,
            target: col('day0_range'),
            label: 'Range',
            conditions: [],
          },
          {
            conditional_agg: AGGREGATES.AVG,
            target: col('day0_vol'),
            label: 'Volume',
            conditions: [],
          },
        )
      },
      {
        label: 'Day 2',
        [STRUCTURAL_TYPES.VISUAL_GROUP]: makeAggNames(
          {
            conditional_agg: AGGREGATES.CNT,
            target: col('day1_gap_pct'),
            label: 'Gap Up 3%',
            conditions: [
              {
                left: col('day1_gap_pct'),
                operator: BOOLEAN_OPS.GE,
                right: [val(3)]
              }
            ],
          },
          {
            conditional_agg: AGGREGATES.CNT,
            target: col('day1_gap_pct'),
            label: 'Gap Down 3%',
            conditions: [
              {
                left: col('day1_gap_pct'),
                operator: BOOLEAN_OPS.LE,
                right: [val(-3)]
              }
            ],
          },
          {
            conditional_agg: AGGREGATES.CNT,
            target: col('day1_close'),
            label: 'Close > Open',
            conditions: [{
              left: col('day1_close'),
              operator: BOOLEAN_OPS.GE,
              right: [col('day1_open')]
            }],
          },
          {
            conditional_agg: AGGREGATES.CNT,
            target: col('day1_close'),
            label: 'Close < Open',
            conditions: [{
              left: col('day1_close'),
              operator: BOOLEAN_OPS.LT,
              right: [col('day1_open')]
            }],
          },
          {
            conditional_agg: AGGREGATES.CNT,
            target: col('day1_close'),
            label: 'Close > Prev. Close',
            conditions: [{
              left: col('day1_close'),
              operator: BOOLEAN_OPS.GE,
              right: [col('day0_close')]
            }],
          },
          {
            conditional_agg: AGGREGATES.CNT,
            target: col('day1_close'),
            label: 'Close < Prev. Close',
            conditions: [{
              left: col('day1_close'),
              operator: BOOLEAN_OPS.LT,
              right: [col('day0_close')]
            }],
          },
          {
            conditional_agg: AGGREGATES.AVG,
            target: col('day1_open_high_pct'),
            label: 'High Spike %',
            conditions: [],
          },
          {
            conditional_agg: AGGREGATES.AVG,
            target: col('day1_open_low_pct'),
            label: 'Low Spike %',
            conditions: [],
          },
          {
            conditional_agg: AGGREGATES.AVG,
            target: col('day1_range'),
            label: 'Range',
            conditions: [],
          },
          {
            conditional_agg: AGGREGATES.AVG,
            target: col('day1_vol'),
            label: 'Volume',
            conditions: [],
          },
        )
      }
    ]
  },

  [`${PREDEF_PREFIX}11am_gap_stats`]: {
    id: `${PREDEF_PREFIX}11am_gap_stats`,
    name: "11AM Gap Stats",
    aggregates: [
      {
        label: "D[1] Gap Stats Metrics",
        [STRUCTURAL_TYPES.VISUAL_GROUP]: makeAggNames(
          {
            label: "Count",
            target: col("day0_close"),
            conditional_agg: AGGREGATES.CNT,
            conditions: [],
          },
          {
            label: "PM High %",
            target: col("day0_pm_high_pct"),
            conditional_agg: AGGREGATES.AVG,
            conditions: [],
          },
          {
            label: "PM Vol",
            target: col("day0_pm_vol"),
            conditional_agg: AGGREGATES.AVG,
            conditions: [],
          },
          {
            label: "PM Vol",
            target: col("day0_pm_vol"),
            conditional_agg: AGGREGATES.MAX,
            conditions: [],
          },
          {
            label: "Gap",
            target: col("day0_gap"),
            conditional_agg: AGGREGATES.AVG,
            conditions: [],
          },
          {
            label: "Gap %",
            target: col("day0_gap_pct"),
            conditional_agg: AGGREGATES.MAX,
            conditions: [],
          },
          {
            label: "Change",
            target: col("day0_chg"),
            conditional_agg: AGGREGATES.AVG,
            conditions: [],
          },
          {
            label: "Change %",
            target: col("day0_chg_pct"),
            conditional_agg: AGGREGATES.AVG,
            conditions: [],
          },
          {
            label: "HOD",
            target: col("day0_high_time"),
            conditional_agg: AGGREGATES.AVG,
            conditions: [],
          },
          {
            label: "LOD",
            target: col("day0_low_time"),
            conditional_agg: AGGREGATES.AVG,
            conditions: [],
          }
        )
      },
      {
        label: "D[1] 11AM Metrics",
        [STRUCTURAL_TYPES.VISUAL_GROUP]: makeAggNames(
          {
            label: "11AM Green",
            target: col("day0_range"),
            conditional_agg: AGGREGATES.CNT,
            conditions: [
              {
                left: col("day0_90min_close"),
                operator: BOOLEAN_OPS.GE,
                right: [
                  col("day0_open"),
                ]
              }
            ],
          },
          {
            label: "11AM Red",
            target: col("day0_vol"),
            conditional_agg: AGGREGATES.CNT,
            conditions: [
              {
                left: col("day0_90min_close"),
                operator: BOOLEAN_OPS.LT,
                right: [
                  col("day0_open"),
                ]
              }
            ],
          },
          {
            label: "11AM Spike %",
            target: col("day0_90min_high_pct"),
            conditional_agg: AGGREGATES.AVG,
            conditions: [],
          },
          {
            label: "11AM Spike %",
            target: col("day0_90min_high_pct"),
            conditional_agg: AGGREGATES.MAX,
            conditions: [],
          },
          {
            label: "11AM Fade %",
            target: col("day0_90min_low_pct"),
            conditional_agg: AGGREGATES.AVG,
            conditions: [],
          },
          {
            label: "11AM Fade %",
            target: col("day0_90min_low_pct"),
            conditional_agg: AGGREGATES.MIN,
            conditions: [],
          },
          {
            label: "11AM Vol",
            target: col("day0_90min_vol"),
            conditional_agg: AGGREGATES.AVG,
            conditions: [],
          },
          {
            label: "11 AM Close %",
            target: col("day0_90min_close_pct"),
            conditional_agg: AGGREGATES.AVG,
            conditions: [],
          }
        )
      },
      {
        label: "D[2] Stats",
        [STRUCTURAL_TYPES.VISUAL_GROUP]: makeAggNames(
          {
            label: "D2 Gap %",
            target: col("day1_gap_pct"),
            conditional_agg: AGGREGATES.AVG,
            conditions: [],
          },
          {
            label: "D2 Gap %",
            target: col("day1_gap_pct"),
            conditional_agg: AGGREGATES.MED,
            conditions: [],
          },
          {
            label: "D2 Gap Up 3%",
            target: col("day1_close"),
            conditional_agg: AGGREGATES.CNT,
            conditions: [
              {
                left: col("day1_gap_pct"),
                operator: BOOLEAN_OPS.GE,
                right: [val(3)]
              }
            ],
          },
          {
            label: "D2 Gap Down 3%",
            target: col("day1_close"),
            conditional_agg: AGGREGATES.CNT,
            conditions: [
              {
                left: col("day1_gap_pct"),
                operator: BOOLEAN_OPS.LE,
                right: [val(-3)]
              }
            ],
          },
          {
            label: "D2 Chg From Open Green",
            target: col("day1_close"),
            conditional_agg: AGGREGATES.CNT,
            conditions: [
              {
                left: col("day1_ret_open"),
                operator: BOOLEAN_OPS.GT,
                right: [val(0)]
              }
            ],
          },
          {
            label: "D2 Chg From Open Red",
            target: col("day1_close"),
            conditional_agg: AGGREGATES.CNT,
            conditions: [
              {
                left: col("day1_ret_open"),
                operator: BOOLEAN_OPS.LT,
                right: [val(0)]
              }
            ],
          },
          {
            label: "D2 Close Green",
            target: col("day1_close"),
            conditional_agg: AGGREGATES.CNT,
            conditions: [
              {
                left: col("day1_chg"),
                operator: BOOLEAN_OPS.GE,
                right: [val(0)]
              }
            ],
          },
          {
            label: "D2 Close Red",
            target: col("day1_close"),
            conditional_agg: AGGREGATES.CNT,
            conditions: [
              {
                left: col("day1_chg"),
                operator: BOOLEAN_OPS.LT,
                right: [val(0)]
              }
            ],
          },
          {
            label: "D2 High Spike %",
            target: col("day1_open_high_pct"),
            conditional_agg: AGGREGATES.AVG,
            conditions: [],
          },
          {
            label: "D2 Low Spike %",
            target: col("day1_open_low_pct"),
            conditional_agg: AGGREGATES.AVG,
            conditions: [],
          },
          {
            label: "D2 Volume",
            target: col("day1_vol"),
            conditional_agg: AGGREGATES.AVG,
            conditions: [],
          }
        )
      }
    ],
  }
});



export const predefinedHistoryTimeseriesProfiles = addPredefinedKey({
  [`${PREDEF_PREFIX}default`]: {
    id: `${PREDEF_PREFIX}default`,
    name: 'Default',
    aggregates: [
      {
        label: 'Axis One',
        [STRUCTURAL_TYPES.VISUAL_GROUP]: makeAggNames(
          {
            conditional_agg: AGGREGATES.CNT,
            target: col('day0_close'),
            label: 'Close > Open',
            chartType: TIMESERIES_CHART_TYPES.line,
            conditions: [{
              left: col('day0_close'),
              operator: BOOLEAN_OPS.GE,
              right: [col('day0_open')]
            }],
          },
          {
            conditional_agg: AGGREGATES.CNT,
            target: col('day0_close'),
            label: 'Close < Open',
            chartType: TIMESERIES_CHART_TYPES.bar,
            conditions: [{
              left: col('day0_close'),
              operator: BOOLEAN_OPS.LT,
              right: [col('day0_open')]
            }],
          },
        )
      },
    ]
  }
});


