import { layoutMapReducer } from '../base/layoutItemReducers/layoutMapReducer';
import { layoutTabReducer } from '../base/layoutItemReducers/layoutTabReducer';
import createLayoutReducer from '../base/layoutReducer';
import makeInitialState from './initialState';
import { ToplistSchemaApi, TOPLIST_NAMESPACE } from './schema';
import watchlistReducer from './watchlistReducer';


/**
 * Create the layout reducer for the toplist.
 * Handles initialState, Schema, and reducer instantiation on its own.
 * Appends Watchlist actions to the reducer.
 *
 * Extension of layoutv2/base
 * @param {string} namespace
 * @returns {{string: function}} [namespace]: reducer()
 */
export default function makeReducer(namespace = TOPLIST_NAMESPACE) {
  return {
    [namespace]: createLayoutReducer(
      namespace,
      makeInitialState(),
      ToplistSchemaApi,
      [layoutMapReducer, layoutTabReducer, watchlistReducer]
    )
  }
}
