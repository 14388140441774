import shortUuid from 'short-uuid';
import PromiseHelper from 'src/utils/PromiseHelper';


const temporaryID = () => shortUuid.generate().slice(0, 8);


/**
 * For layoutSyncMiddleware, we need to queue all updates made to the server.
 * If any of them fail, we stop work until the next request. On the next request,
 * we retry the entire queue in-order.
 *
 * onSuccess and onError is called for each item. This is to allow the middleware
 * to persist BroadcaseChannel updates in-order, just like DB saves.
 */
class SyncMiddlewareAsyncQueue {
  constructor() {
    this.queue = [];
    this.isProcessing = false;
    /** numer of times this.process() has ran, successfully or not. */
    this.processIndex = 0;
    /** number of batches processed. An error in a batch does not advance. */
    this.batchIndex = 0;
    /** So we can track batches easier in Sentry. An error in a batch does not advance. */
    this.batchId = temporaryID();
  }

  /**
   * @param {function} callback - The callback to enqueue
   * @param {Object} [options = {}]
   * @param {function} [options.onError] 
   * @param {function} [options.onSuccess]
   */
  push(callback, { onError, onSuccess } = {}) {
    this.queue.push({ callback, onError, onSuccess });
    void this._process();
  }


  async _process() {
    if (this.isProcessing) {
      return;
    }

    this.isProcessing = true;
    let successCount = 0;


    while (this.queue.length) {
      const item = this.queue[0];

      try {
        const result = await item.callback();
        this.queue.shift();
        item?.onSuccess?.({
          result,
          batchId: this.batchId,
          processIndex: this.processIndex,
        });
        successCount += 1;

        if (successCount.length >= 5) {
          // add a little cooldown if we have a large backlog
          await PromiseHelper.sleepBetween(200, 500);
        }
      } catch (err) {
        console.error(`[SyncMiddlewareAsyncQueue] Error after processing ${successCount} items`, err);

        void item?.onError?.({
          error: err,
          batchId: this.batchId,
          processIndex: this.processIndex,
        });

        this.processIndex++;
        this.isProcessing = false;

        return;
      }
    }

    this.batchId = temporaryID();
    this.batchIndex += 1;
    this.processIndex += 1;
    this.isProcessing = false;
  }
}


export default SyncMiddlewareAsyncQueue;

