import { PREDEF_PREFIX } from 'src/redux/profileSettings/profileSettingsConfig';
import { HistorySchemaApi } from './schema';
import { PREDEF_FOLDER_ID, LINK_COLORS } from '../base/layoutSchema';

const layoutOne = HistorySchemaApi._createDefaultLayout({ name: 'Predef One' });
const layoutIdOne = `${PREDEF_PREFIX}_default_one`;

const layoutTwo = HistorySchemaApi._createOtherLayout({ name: 'Predef Two' });
const layoutIdTwo = `${PREDEF_PREFIX}_default_two`;

const layoutThree = HistorySchemaApi._createOtherLayout({ name: 'Predef Three' });
const layoutIdThree = `${PREDEF_PREFIX}_default_three`;

const dayOneFolderId = 'fld_predef__day_1';
const dayTwoFolderId = 'fld_predef__day_2';

const defaultLinks = () => {
  return {
    [LINK_COLORS.white.name]: {
      ticker: 'AAPL',
      historicalDate: undefined,
    }
  }
}

export const predefinedLayoutTreeItems = {
  [PREDEF_FOLDER_ID]: {
    id: PREDEF_FOLDER_ID,
    label: 'Predefined Layouts',
    isPredefined: true,
    isFolder: true,
    children: [dayOneFolderId, dayTwoFolderId]
  },
  [dayOneFolderId]: {
    id: dayOneFolderId,
    label: 'Day 1',
    isPredefined: true,
    isFolder: true,
    children: [layoutIdOne, layoutIdTwo]
  },
  [dayTwoFolderId]: {
    id: dayTwoFolderId,
    label: 'Day 2',
    isPredefined: true,
    isFolder: true,
    children: [layoutIdThree]
  },
  [layoutIdOne]: {
    id: layoutIdOne,
    label: layoutOne.layout.name,
    isPredefined: true,
  },
  [layoutIdTwo]: {
    id: layoutIdTwo,
    label: layoutTwo.layout.name,
    isPredefined: true,
  },
  [layoutIdThree]: {
    id: layoutIdThree,
    label: layoutThree.layout.name,
    isPredefined: true,
  }
}

export const predefinedLayoutTreeOpenState = [
  PREDEF_FOLDER_ID,
  dayOneFolderId,
  dayTwoFolderId
]


export const predefinedLayouts = {
  [layoutIdOne]: layoutOne.layout,
  [layoutIdTwo]: layoutTwo.layout,
  [layoutIdThree]: layoutThree.layout,
}


export const predefinedLinksByLayout = {
  [layoutIdOne]: defaultLinks(),
  [layoutIdTwo]: defaultLinks(),
  [layoutIdThree]: defaultLinks(),
}


export const predefinedComponents = {
  ...layoutOne.components,
  ...layoutTwo.components,
  ...layoutThree.components,
}

