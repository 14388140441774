/**
 * @param {Function} asyncFn - The async function to execute.
 * @param {number} retries - Number of retries.
 * @param {number} delay - Delay between retries in milliseconds.
 * @returns {Promise<any>} - Returns the resolved value of the async function.
 * @throws {Error} - Throws the last error if all retries fail.
 */
export async function retryAsync(asyncFn, retries = 3, delay = 250) {
  let lastError;

  for (let attempt = 1; attempt <= retries; attempt++) {
    console.log('ATTEMPT', attempt);
    try {
      return await asyncFn();
    } catch (error) {
      lastError = error;
      console.warn(`Attempt ${attempt} failed. Retrying in ${delay}ms...`);
      await new Promise(res => setTimeout(res, delay)); // Wait before retrying
    }
  }

  // If all retries fail, throw the last error
  throw new Error(`All ${retries} retries failed. Last error: ${lastError.message}`);
}



function isPlainObject(obj) {
  return obj !== null && typeof obj === 'object' && Object.getPrototypeOf(obj) === Object.prototype;
}


export function isShallowSerializable(value) {
  // Primitives and null
  if (
    value === null ||
    value === undefined ||
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  ) {
    return true;
  }

  if (Array.isArray(value)) {
    // Check elements at top-level only
    return value.every(v => {
      const t = typeof v;
      return v == null || t === 'string' || t === 'number' || t === 'boolean' || Array.isArray(v) || isPlainObject(v);
    });
  }

  if (isPlainObject(value)) {
    // Check top-level properties only
    return Object.values(value).every(v => {
      const t = typeof v;
      return v == null || t === 'string' || t === 'number' || t === 'boolean' || Array.isArray(v) || isPlainObject(v);
    });
  }

  // Anything else (function, class instance, etc.) is not allowed
  return false;
}
