import React, { useContext, useState, useCallback } from 'react';
import _uniqueId from 'lodash/uniqueId';
import { makeStyles } from '@material-ui/core/styles';
import MuiPopover from '@material-ui/core/Popover';
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    maxWidth: props => props.maxWidth,
    maxHeight: props => props.maxHeight,
    width: '100%',
    height: '100%'
  }
}));



/**
 * Hook to hide the internal state of a Poper, providing 
 * only the necessary API and panel props.
 * Helps fix the annoyoying parent raising issue with popup state.
 * @TODO: Generalize, and move outside SlicedForms.
 * 
 * @description
 * Basically, this allows us to define the Trigger and the Popover within the same component,
 * without dealing with any annoying state management, clone children, renderProps, etc.
 * You should pass all your props to the hook, and then allow the hook to fully define
 * the Popover.
 * 
 * This means you're free to customize the trigger in any way you want too
 * 
 * GOTCHAS:
 * No memoization is possible. I haven't seen performance issues,
 *    but this will run every render
 * If you try to bind the props to multiple Popovers in the same component, 
 *    the props will overwrite eachother.
 *    If you need multiple, then call the hook multiple times instead.
 *
 *
 * @example
 * const { popoverApi, popoverProps } = Popover.usePopover({ ...options });
 * 
 * return (
 *  <MyCustomButton {...bindTrigger}>
 *  <Popover {...popoverProps}>
 *    <MyComponent />
 *  </Popover>
 * )
 */
function usePopover({
  className,
  paperClassName,
  strategy = 'click',
  anchorPosition = 'left',
  maxWidth = 500,
  maxHeight = 500,
  TransitionProps,
  transitionDuration,
}) {
  const classes = useStyles({ maxWidth, maxHeight });
  const [_id] = useState(() => _uniqueId('popover_'))
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? _id : undefined;

  const popoverApi = {
    handleOpen,
    handleClose,
  }

  const commonPopoverProps = {
    className: clsx(className, classes.root),
    classes: {
      paper: clsx(paperClassName, classes.paper, 'isThisWorking')
    },
    open,
    id,
    anchorEl,
    onClose: handleClose,
    ...(TransitionProps && { TransitionProps }),
    ...(transitionDuration && { transitionDuration }),
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: anchorPosition,
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: anchorPosition,
    }
  }

  const commonTriggerProps = {
    'aria-describedby': id,
  }

  let strategyPopoverProps;
  let strategyTriggerProps;

  if (strategy === 'click') {
    strategyTriggerProps = {
      onClick: handleOpen
    }
  } else if (strategy === 'hover') {
    strategyTriggerProps = {
      onMouseEnter: handleOpen,
      onMouseLeave: handleClose,
      onFocus: handleOpen,
      onBlur: handleClose,
      onKeyDown: (e) => e.key === 'Escape' && handleClose(),
      role: 'tooltip',
      tabIndex: 0
    }
    strategyPopoverProps = {
      disableRestoreFocus: true,
      disableAutoFocus: true,
      disableEnforceFocus: true,
      hideBackdrop: true,
      style: { pointerEvents: 'none' }
    }
  } else {
    console.warn(`Unknown popover strategy: ${strategy}, should be one of: 'click', 'hover'`);
  }

  return {
    id,
    open,
    popoverApi,
    popoverProps: {
      ...commonPopoverProps,
      ...strategyPopoverProps
    },
    bindTrigger: {
      ...commonTriggerProps,
      ...strategyTriggerProps
    }
  }
}



function Popover({
  children,
  ...rest
}) {

  return (
    <MuiPopover
      {...rest}
    >
      {children}
    </MuiPopover>
  );
}


Popover.usePopover = usePopover;



export default Popover
