import { intIdExists } from "../base/reducerUtils";
import _uniqueId from 'lodash/uniqueId';
import _sortedUniqBy from 'lodash/sortedUniqBy'
import _isEqual from 'lodash/isEqual';
import { getLeaves } from "react-mosaic-component";
import edgeDataApi from 'src/apis/edgeDataApi';
import * as Sentry from '@sentry/react';
import { middlewareActionType } from "src/redux/middleware/layoutSyncMiddleware/constants";
import { COMPONENT_TYPES } from "../base/layoutSchema";
import { PROFILE_CONFIG } from "./profileConfig";
import profileToQuery from "src/app/slicedForm/mapping/profileToQuery";
import { EXPRESSION_NAMESPACE } from "../base/layoutExpressionConfig";
import DataSourceResponseBatcher from "src/app/components/grid/topListScanner/dataSource/DataSourceResponseBatcher";
import { createErrorNotification, createSuccessNotification } from "src/redux/notifications/notificationActions";
import {
  wAction,
  dispatchErrorNotification,
  makeNamespaceDispatch,
  getActiveLayoutId,
  getColumnsForSpecificWatchlist,
  getColumnsForAllActiveWatchlists
} from './watchlistActionHelpers.js';


// These actions will not be processed by middleware. Tabs will get OOS for a bit
export const REQUEST__FETCH_LIST_WATCHLIST_PROFILES = wAction('request__fetch-watchlist-profiles');
export const FETCH_LIST_WATCHLIST_PROFILES = wAction('fetch-watchlist-profiles');

export const REQUEST__FETCH_WATCHLISTS = wAction('request__fetch-watchlist');
export const FETCH_WATCHLISTS = wAction('fetch-watchlist');

export const REQUEST__ADD_WATCHLIST_TICKER = wAction('request__add-watchlist-ticker');
export const ADD_WATCHLIST_TICKER = wAction('add-watchlist-ticker');

export const REQUEST__REMOVE_WATCHLIST_TICKER = wAction('request__remove-watchlist-ticker');
export const REMOVE_WATCHLIST_TICKER = wAction('remove-watchlist-ticker');

export const REORDER_WATCHLIST_TICKERS = wAction('reorder-watchlist-tickers');

// These will hit middleware, and cause a Revision change in Dynamo, even though these aren't stored in dynamo
// Its an attempt to keep tabs in sync via revision number. Its not perfect.
export const RENAME_WATCHLIST = middlewareActionType('rename-watchlist');
export const DELETE_WATCHLIST = middlewareActionType('delete-watchlist');
export const CREATE_WATCHLIST = middlewareActionType('create-watchlist');


/** @typedef {import("../base/layoutActions").Thunk} Thunk */
/** @typedef {import("../base/layoutActions").LayoutAction} LayoutAction */
/** @typedef {import("../base/layoutActions").Action} Action */
/** @typedef {import("../base/layoutSchema.js").SchemaApi"} SchemaApi */


/* 
  Watchlists are stored in the same layoutReducer state, but do not apply globally.
  They are saved/uploaded to RDS, so the middleware logic doesn't apply either.

  To make this somewhat modular, define your watchlist actions here, and define 
  action handlers for the reducer seperately too. Then use them only for 'toplist'

  WARN: Transactions are going to kill us. We can make no guarantees about the data.

  What is the real danger?
  Getting RDS + Component out of sync. Component referencing a deleted watchlist.

  How could that happen?
  Tab A deletes WL
  Tab B doesn't receive update
  Tab B saves component

  How do we prevent it?
  We can't. Search the state during initialization and reset components if need be.

  In order to fix we'd need to update UsersTable from within python lambda. It would be hard.
  It would also break our rules about component updates not causing revisions.

  Maybe we can:
  Just save WatchlistInrementer to localstorage as a revisioned changeset. We don't actually care about the Increment.
  Allow the original action to be broadcast
  Now if a tab doesn't recieve the udpate, it won't be able to save the component.
*/



/**
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @returns {Thunk}
 */
export const fetchListWatchlists = (
  namespace,
  schemaApi,
) => async dispatch => {
  const disp = makeNamespaceDispatch(namespace, dispatch);

  disp({ type: REQUEST__FETCH_LIST_WATCHLIST_PROFILES });

  let payload = [];
  try {
    const { data } = await edgeDataApi.get(`/watchlistsv2/${namespace}`);
    payload = data;
  } catch (err) {
    Sentry.captureException(err);
  }

  disp({
    type: FETCH_LIST_WATCHLIST_PROFILES,
    payload
  });
}


let prevParams = {};
let prevFetchTime = 0;


/** 
 * Fetches all data for all VISIBLE watchlists in a single go.
 * As such, we need to parse state heavily, and factor in expressions/layouts etc.
 *
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {string} preventFetchIfAlreadyFetching - Certain actions take prescedence over others. For example,
 *                                                 don't let the automated SyncronizedTimer override a manually 
 *                                                 triggered fetch.
 * @returns {Thunk} 
 */
export const fetchWatchlists = (
  namespace,
  schemaApi,
  preventFetchIfAlreadyFetching = false
) => async (dispatch, getState) => {
  const disp = makeNamespaceDispatch(namespace, dispatch);

  const { [namespace]: state } = getState();

  const expressions = Object.values(state?.expressions?.[EXPRESSION_NAMESPACE.REALTIME] || {});

  if (!getActiveLayoutId(state)) return;

  if (preventFetchIfAlreadyFetching && state.isFetching.watchlistData) return;

  const {
    columns: watchlistColumns,
    watchlistIds
  } = getColumnsForAllActiveWatchlists(state);

  if (!watchlistIds.length || !watchlistColumns.length) return;

  const { columns } = profileToQuery({ columns: watchlistColumns }, null, null, expressions);

  // Remove duplicates. Mounting components will send in the same request, often before redux can update.
  // Normally this function is only called once, on timer. Except for mounts, where duplicates happen.
  // This feels like an ugly solution, but really it's pretty robust. Much more simple than managing flags.
  // TODO: I think this deduping is obsolete now, due to TopListMosaic useEffect() timeout fetching. Not 100% sure.

  const params = {
    watchlistIds,
    columns
  };

  let timeSince = (+new Date()) - prevFetchTime;

  if (_isEqual(params, prevParams) && timeSince < 2000) {
    return;
  }

  prevParams = params;
  prevFetchTime = +new Date();

  void DataSourceResponseBatcher.registerRequest({
    id: 'watchlists',
    promise: (async () => {
      disp({ type: REQUEST__FETCH_WATCHLISTS });
      try {
        const { data } = await edgeDataApi.post(`/watchlistsv2/${namespace}/data`, params);
        return data;
      } catch (err) {
        Sentry.captureException(err);
        dispatch({ type: FETCH_WATCHLISTS, payload: [] });
      }
    })(),
    onResolve: (data = []) => {
      if (!data || !Array.isArray(data)) {
        return dispatch({ type: FETCH_WATCHLISTS, payload: [] });
      }
      disp({ type: FETCH_WATCHLISTS, payload: data });
    },
  });
}


/** 
 * Add ticker to watchlist, and immediatly refresh all watchlist data.
 * Use getState to find any components using this watchlist.id, and condense the columns.
 *
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {string} watchlistProfileId
 * @param {string} ticker
 * @returns {Thunk} 
 */
export const addWatchlistTicker = (
  namespace,
  schemaApi,
  watchlistProfileId,
  ticker
) => async (dispatch, getState) => {
  const disp = makeNamespaceDispatch(namespace, dispatch);

  const { [namespace]: state } = getState();

  if (!ticker || !state.activeLayout) return;
  const activeLayoutId = getActiveLayoutId(state);
  if (!activeLayoutId) return;

  const loadingItemId = _uniqueId(watchlistProfileId);

  disp({
    type: REQUEST__ADD_WATCHLIST_TICKER,
    payload: { watchlistProfileId, ticker, loadingItemId },
  });

  const columns = getColumnsForSpecificWatchlist(watchlistProfileId, state);

  let payload = { watchlistProfileId, loadingItemId };
  try {
    const { data } = await edgeDataApi.put(`/watchlistsv2/${namespace}/${watchlistProfileId}/${ticker}`, { columns });
    payload = {
      ...payload,
      tickerData: data,
      ...createSuccessNotification(`${ticker} added to watchlist`)

    };
  } catch (err) {
    let msg = err?.response?.data?.message || `Failed to add ${ticker} to watchlist`;
    payload = {
      ...payload,
      tickerData: [],
      ...createErrorNotification(msg)
    };
  }
  disp({
    type: ADD_WATCHLIST_TICKER,
    payload,
  });
}


/**
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {string} watchlistProfileId
 * @param {string} watchlistItemId
 * @returns {Thunk}
 */
export const removeWatchlistTicker = (
  namespace,
  schemaApi,
  watchlistProfileId,
  watchlistItemId
) => async dispatch => {
  const disp = makeNamespaceDispatch(namespace, dispatch);
  if (!intIdExists(watchlistItemId) || !intIdExists(watchlistProfileId)) return;

  disp({
    type: REQUEST__REMOVE_WATCHLIST_TICKER,
    payload: { watchlistProfileId, watchlistItemId },
  });
  let status = false;
  try {
    const { data } = await edgeDataApi.delete(`/watchlistsv2/${namespace}/${watchlistProfileId}/${watchlistItemId}`);
    status = ['true', 'True', 'TRUE', true].includes(data); // LOL
  } catch (err) {
    Sentry.captureException(err);
  }
  disp({
    type: REMOVE_WATCHLIST_TICKER,
    payload: { watchlistProfileId, watchlistItemId, status },
  });
}


/**
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {string} watchlistProfileId
 * @param {object} options
 * @param {string[]} options.order
 * @returns {Thunk}
 */
export const reorderWatchlistTickers = (
  namespace,
  schemaApi,
  watchlistProfileId,
  { order }
) => async dispatch => {
  const disp = makeNamespaceDispatch(namespace, dispatch);

  disp({ type: REQUEST__FETCH_WATCHLISTS });
  try {
    await edgeDataApi.patch(`/watchlistsv2/${namespace}/${watchlistProfileId}`, { order });
  } catch (err) {
    Sentry.captureException(err);
  }
  disp({ type: REORDER_WATCHLIST_TICKERS, payload: { watchlistProfileId, order } });

}


/**
 * @actionCreator
 * @param {string} namespace
 * @param {string} watchlistProfileId
 * @param {string} newName
 * @returns {Thunk}
 */
export const renameWatchlist = (
  namespace,
  schemaApi,
  watchlistProfileId,
  newName
) => async dispatch => {
  const disp = makeNamespaceDispatch(namespace, dispatch);

  disp({ type: RENAME_WATCHLIST, payload: { watchlistProfileId, newName } });
  try {
    await edgeDataApi.patch(`/watchlistsv2/${namespace}/r/${watchlistProfileId}`, { name: newName });
  } catch (err) {
    Sentry.captureException(err);
  }
}



/**
 *
 * This one is confusing, since we need to modify the overal layout (like we do for non-watchlist stuff) as well as the watchlist stuff.
 * Silently update DB after redux
 *
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {string} watchlistProfileId
 * @returns {Thunk}
 */
export const deleteWatchlist = (
  namespace,
  schemaApi,
  watchlistProfileId
) => async (dispatch, getState) => {
  const { [namespace]: state } = getState();
  const disp = makeNamespaceDispatch(namespace, dispatch);

  const action = { type: DELETE_WATCHLIST, payload: { watchlistProfileId } };

  disp(action);

  try {
    await edgeDataApi.delete(`/watchlistsv2/${namespace}/${watchlistProfileId}`);
  } catch (err) {
    Sentry.captureException(err);
    dispatchErrorNotification(dispatch);
  }
}


/**
 * Create a watchlist. This will not be silent, we will wait for the db to respond.
 * Updates layout state, so we need to use the old strategy.
 *
 * @actionCreator
 * @param {string} namespace
 * @param {SchemaApi} schemaApi
 * @param {string} componentId
 * @param {string} name
 * @param {string} [copyId]
 * @returns {Thunk}
 */
export const createWatchlist = (
  namespace,
  schemaApi,
  componentId,
  name,
  copyId = null
) => async (dispatch, getState) => {
  const { [namespace]: state } = getState();

  const disp = makeNamespaceDispatch(namespace, dispatch);

  const activeLayoutId = getActiveLayoutId(state);
  if (!activeLayoutId) return;

  const component = state.components[componentId];
  if (!component) return;

  const columnProfileId = component[PROFILE_CONFIG.SCANNER_COLUMNS.idKey];

  const columnProfile = state[PROFILE_CONFIG.SCANNER_COLUMNS.listKey]?.[columnProfileId];
  const columns = _sortedUniqBy(columnProfile?.columns || [], 'column');

  let response = { profile: {}, data: [] };
  try {
    const { data } = await edgeDataApi.post(`/watchlistsv2/${namespace}`, {
      name,
      columns,
      copyId
    });
    response = data;
  } catch (err) {
    Sentry.captureException(err);
  }

  if (!response?.profile?.id) {
    return dispatch({ type: 'GENERIC_ERROR_NO_TARGET', payload: { ...createErrorNotification('Watchlist failed to create') } });
  }
  const action = { type: CREATE_WATCHLIST, payload: { componentId, profile: response.profile, data: response.data } };

  disp(action);
}


